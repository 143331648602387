import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Sidebar from "react-sidebar";
import { bindActionCreators } from "redux";

import * as AppActions from "../../reducers/appReducer";
import * as UserActions from "../../reducers/userReducer";

import logo from "../../img/banner.png";

interface IMenuProps {
  appState: any;
  userState: any;
  appActions: any;
  userActions: any;
  history: any;
}

interface IMenuState {
  open: boolean;
}

class Menu extends React.Component<IMenuProps, IMenuState> {
  constructor(props: any) {
    super(props);
    const defaultState: IMenuState = {
      open: props.open ? props.open : true,
    };
    this.state = defaultState;

    this.toggleOpen = this.toggleOpen.bind(this);
    this.onTransition = this.onTransition.bind(this);
    this.logout = this.logout.bind(this);
    this.toggleDebug = this.toggleDebug.bind(this);
  }
  
  public toggleOpen() {
    this.setState({
      open: !this.state.open
    });
  }

  public onTransition() {
    this.props.appActions.toggleMenu();
  }

  public logout(e: any) {
    e.preventDefault();
    this.props.userActions.logoutUser();
    window.localStorage.clear();
    this.props.history.push("/login");
    this.props.appActions.toggleMenu();
  }

  public toggleDebug(){
    const newDebug = !this.props.appState.debug;
    this.props.appActions.setDebug(newDebug);
  }

  get header() {
    return (
      <div style={styles.sidebar}>
        <div style={styles.header}><img src={logo} alt="logo" /></div>
        {this.props.children}
      </div>
    );
  }
  get links() {
    return (
      <div style={styles.sidebar}>
        <div style={styles.header}><img src={logo} alt="logo" style={{width: "100%"}}/></div>
        <div style={styles.content}>
          <div style={styles.divider} />

          <Link to="/dashboard" style={styles.sidebarLink} onClick={this.onTransition} id="menu_dashboard">Dashboard</Link>

          <div style={styles.divider} />

          <Link to="/me" style={styles.sidebarLink} onClick={this.onTransition} id="menu_profile">My Profile</Link>
          <Link to="/subaccounts" style={styles.sidebarLink} onClick={this.onTransition} id="menu_subaccount">My Subaccounts</Link>
          <Link to="/organizations" style={styles.sidebarLink} onClick={this.onTransition} id="menu_organization">My Organizations</Link>
          {/* <Link to="/events" style={styles.sidebarLink} onClick={this.onTransition} id="menu_invitations">My Events</Link> */}
          <Link to="/organizations/create" style={styles.sidebarLink} onClick={this.onTransition} id="menu_create_organization">Create a New Group</Link>
          <Link to="#" style={styles.sidebarLink} onClick={this.logout} id="menu_logout">Logout</Link>

          {this.props.userState && this.props.userState.loggedIn && this.props.userState.user.systemRole === 'admin' && 
            <div>
              <div style={styles.divider} />
              <Link to="#" style={styles.sidebarLink} onClick={this.toggleDebug} id="menu_logout">Toggle Debug {this.props.appState.debug ? 'Off' : 'On'}</Link>
            </div>

          }
        </div>
      </div>
    );
  }

  public render() {
    return (
      <Sidebar
        sidebar={this.links}
        open={this.props.appState.showMenu}
        onSetOpen={this.onTransition}>
        {this.props.children}
      </Sidebar>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Menu));


const styles: any = {
  sidebar: {
    width: 256,
    height: "100%",
  },
  sidebarLink: {
    display: "block",
    paddingLeft: "8px",
    paddingTop: "2px",
    color: "#757575",
    textDecoration: "none",
  },
  linkHeader: {
    display: "block",
    paddingTop: "12px",
    color: "#757575",
    textDecoration: "none",
    fontWeight: "bold"
  },
  divider: {
    margin: "8px 0",
    height: 1,
    backgroundColor: "#e0e0e0",
  },
  content: {
    padding: "16px",
    height: "100%",
    backgroundColor: "#f2f2f2",
  },
  root: {
    fontFamily: "'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
    fontWeight: 300,
  },
  header: {
    backgroundColor: "#f2f2f2",
    color: "white",
    padding: "16px",
    fontSize: "1.5em",
    width: "100%"
  },
};