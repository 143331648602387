import * as React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import Card from "src/components/structure/Card";
import {error, success} from "src/components/structure/Alert";
import AdminReservationListItem from "src/components/screens/Events/AdminReservationListItem";
import { EventsAPI} from "src/api";
import { IEventInstance, IEventReservation, IEventTemplate, EventTemplateBlank, EventInstanceBlank } from "src/api/events";
import { IOrganization, OrganizationBlank } from "src/api/organizations";
import TimePicker from "src/components/structure/TimePicker";

interface IAdminManageReservationsScreenProps {
  onEditSaved: any;
  onInstanceDeleted: any;
  show: boolean;
  onHide: any;
  organization: IOrganization;
  templateId: number;
  instance: IEventInstance;
}

interface IAdminManageReservationsScreenState {
  loading: boolean;
  organization: IOrganization;
  template: IEventTemplate;
  instance: IEventInstance;
  reservations: IEventReservation[];

  allAccounts: any[]; // this is untyped so that we can combine users and sub accounts in the same list
  accountsToShow: any[];
  accountsToAdd: any[];
  showAddUserModal: boolean;

  showDeleteModal: boolean;
  showEditModal: boolean;

  allTemplates: IEventTemplate[];
  selectedTemplate: IEventTemplate;
  newStartTime: string;
  newLengthInMinutes: number;
}

export default class AdminManageReservationsScreen extends React.Component<IAdminManageReservationsScreenProps, IAdminManageReservationsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      organization: OrganizationBlank,
      template: EventTemplateBlank,
      instance: EventInstanceBlank,
      reservations: [],
      allAccounts: [],
      accountsToShow: [],
      accountsToAdd: [],
      showAddUserModal: false,

      showDeleteModal: false,
      showEditModal: false,

      allTemplates: [],
      newLengthInMinutes: 0,
      newStartTime: "00:00",
      selectedTemplate: EventTemplateBlank,
    };
    
    this.updateField = this.updateField.bind(this);
    this.updateNewStartTime = this.updateNewStartTime.bind(this);
    this.updateNewSelectedTemplate = this.updateNewSelectedTemplate.bind(this);

    this.initialize = this.initialize.bind(this);
    this.toggleShowAddUserModal = this.toggleShowAddUserModal.bind(this);
    this.handleDeleteReservation = this.handleDeleteReservation.bind(this);
    this.handleSelectAccount = this.handleSelectAccount.bind(this);
    this.addParticipants = this.addParticipants.bind(this);

    this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);
    this.toggleShowEditModal = this.toggleShowEditModal.bind(this);
    this.deleteInstance = this.deleteInstance.bind(this);
    this.editInstance = this.editInstance.bind(this);
  }

  componentDidUpdate(prevProps: IAdminManageReservationsScreenProps){
    if(this.props.instance.id !== 0 && this.props.instance.id !== prevProps.instance.id){
      this.initialize();
    }
  }

  public render() {
    if(!this.props.show){
      return null;
    }
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} dialogClassName="modal-94">
      <Modal.Header closeButton={true}>
        <Modal.Title>Edit Instance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-5 col-sm-12">
            <Card title="Event Information" loading={this.state.loading} help="">
              <h2>{this.state.organization.name}</h2>
              <h3>{this.state.template.name}</h3>
              <p style={{ fontWeight: "bold" }}>
                Starts: {moment(this.state.instance.startTime).format("MM/DD/YY hh:mm A")}<br />
                Ends: {moment(this.state.instance.startTime).clone().add(this.state.instance.numberOfMinutes, "minutes").format("MM/DD/YY hh:mm A")}<br />
                {this.state.instance.status === "pending" && (<span>Releases: {moment(this.state.instance.releaseOnDate).format("MM/DD/YY hh:mm A")}</span>)}
              </p>
              <p>
                Maximum Reservations Allowed: {this.state.template.maximumReservationsPerInstance}<br />
                {this.state.template.maximumReservationsPerInstanceRule !== "absolute" && (
                  <span><em>Note: All users and subaccounts can join and will count as one reservation</em><br /></span>
                )}
                Total Reservations: {this.state.instance.currentReservations}<br />
              </p>
              <p>
                Each participant may register for {this.state.template.maximumReservationsPerUserPerDay} instances each day and {this.state.template.maximumReservationsPerUserPerWeek} each week.
              </p>
              <p><strong><Link to={`/organizations/${this.state.organization.id}/admin/events`}> Back </Link></strong></p>
              <div className="form-group">
                <button className="btn btn-success btn-block" onClick={this.toggleShowEditModal}>Edit Instance</button>
                <button className="btn btn-danger btn-block" onClick={this.toggleShowDeleteModal}>Delete Instance</button>
              </div>
            </Card>
          </div>
          <div className="col-lg-7 col-sm-12">
            <Card title="Reservations" loading={this.state.loading} help="">
              {this.state.reservations.length === 0 && (<strong>No reservations have been placed.</strong>)}
              {this.state.reservations.map((r) => {
                return (
                  <AdminReservationListItem
                    key={r.id}
                    organization={this.state.organization}
                    reservation={r}
                    onDeleteReservation={this.handleDeleteReservation}
                    />
                );
              })}
              <div className="row">
                <div className="col-12">
                  <button className="btn btn-primary btn-block" onClick={this.toggleShowAddUserModal}>Manually Add User</button>
                </div>
              </div>
            </Card>
          </div>



          <Modal show={this.state.showAddUserModal} onHide={this.toggleShowAddUserModal}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Add Reservation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>You can add members here automatically. This will ignore any limits on participant counts you have set on this event.</p>
              <div>
                <Typeahead
                  id="taAdd"
                  key={this.state.accountsToShow.length}
                  labelKey="fullName"
                  multiple={true}
                  placeholder="Search for participant"
                  onChange={this.handleSelectAccount}
                  options={this.state.accountsToShow}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-block btn-primary" onClick={this.addParticipants}>Add Participants</button>
              <button className="btn btn-block btn-default" onClick={this.toggleShowAddUserModal}>Nevermind</button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.showDeleteModal} onHide={this.toggleShowDeleteModal}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Delete Instance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you absolutely sure you want to delete this event instance? If the event is not pending and has active reservations, the reservations will be removed and the users will be notified.</p>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-block btn-danger" onClick={this.deleteInstance}>Yes, Delete Instance</button>
              <button className="btn btn-block btn-default" onClick={this.toggleShowDeleteModal}>Nevermind</button>
            </Modal.Footer>
          </Modal>



          <Modal show={this.state.showEditModal} onHide={this.toggleShowEditModal}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Edit Instance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>Template</label>
                <select className="form-control" value={this.state.selectedTemplate.id} onChange={this.updateNewSelectedTemplate}>
                  {this.state.allTemplates.map((template) => {
                    return (
                      <option key={template.id} value={template.id}>{template.name}</option>
                    )
                  })}
                </select>
              </div>
              <div className="form-group">
                <label>Start Time</label>
                <TimePicker id="startTime" onChange={this.updateNewStartTime} value={this.state.newStartTime} />
              </div>
              <div className="form-group">
                <label>Number of Minutes</label>
                <input type="text" className="form-control" id="newLengthInMinutes" value={this.state.newLengthInMinutes} onChange={this.updateField} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-block btn-success" onClick={this.editInstance}>Yes, Edit Instance</button>
              <button className="btn btn-block btn-default" onClick={this.toggleShowEditModal}>Nevermind</button>
            </Modal.Footer>
          </Modal>
        </div>

      </Modal.Body>
      </Modal>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateNewStartTime(time: string){
    this.setState({newStartTime: time});
  }

  private updateNewSelectedTemplate(e: any){
    let selectedTemplate: any = {};
    const newTemplateId = parseInt(e.target.value)
    for(const t of this.state.allTemplates){
      if(t.id === newTemplateId){
        selectedTemplate = t;
        break;
      }
    }
    this.setState({selectedTemplate});
  }

  private toggleShowAddUserModal(){
    this.setState({ showAddUserModal: !this.state.showAddUserModal, accountsToAdd: [] });
  }

  private handleDeleteReservation(reservation: IEventReservation){
    const reservations: IEventReservation[] = [];
    for(const r of this.state.reservations){
      if(r.id !== reservation.id){
        reservations.push(r);
      }
    }
    // add back to available accounts
    const accountsToShow = this.state.accountsToShow;
    for(const a of this.state.allAccounts){
      if(a.id === reservation.participantId && a.accountType === reservation.participantType){
        accountsToShow.push(a);
        break;
      }
    }
    this.setState({reservations, accountsToShow});
  }

  private handleSelectAccount(e: any){
    this.setState({accountsToAdd: e});
  }

  private initialize() {
    this.setState({ loading: true }, async () => {
      try {
        const orgId = this.props.organization.id;
        const tempId = this.props.templateId;
        const instId = this.props.instance.id;


        // get the template
        const tempResult = await EventsAPI.getEventTemplate(orgId, tempId);

        // any reservations
        const resResult = await EventsAPI.getInstanceReservations(orgId, tempId, instId);

        // all template
        const allTemplatesResult = await EventsAPI.getEventTemplates(orgId);

        const template: IEventTemplate = tempResult.body.data;
        const reservationsResult: IEventReservation[] = resResult.body.data;
        const reservations: IEventReservation[] = [];
        for(const r of reservationsResult){
          r.instanceStartTime = moment(r.instanceStartTime);
          reservations.push(r);
        }
        // members
        const allAccounts: any = [];
        if(this.props.organization.users){
          for(const u of this.props.organization.users){
            u.fullName = `${u.firstName} ${u.lastName}`;
            u.accountType = "user";
            u.userId = u.id;
            allAccounts.push(u);
          }
        }
        if(this.props.organization.userSubAccounts){
          for(const u of this.props.organization.userSubAccounts){
            u.fullName = `${u.firstName} ${u.lastName}`;
            u.accountType = "subaccount";
            allAccounts.push(u);
          }
        }

        // sort by last name alpha
        allAccounts.sort((a: any, b: any) => {
          if(a.lastName !== b.lastName){
            return a.lastName > b.lastName ? 1 : -1;
          }
          return a.firstName > b.firstName ? 1 : -1;
        });

        // now loop and figure out the accounts to show
        const accountsToShow: any = [];
        for(const ac of allAccounts){
          // if they are in the list of reservations, we don't show them
          let found = false;
          for(const r of reservations){
            if(r.participantId === ac.id && r.participantType === ac.accountType){
              found = true;
              break;
            }
          }
          if(!found){
            accountsToShow.push(ac);
          }
        }

        this.setState({
          loading: false,
          organization: this.props.organization,
          template,
          instance: this.props.instance,
          reservations,
          allAccounts,
          accountsToShow, 
          allTemplates: allTemplatesResult.body.data,
          selectedTemplate: tempResult.body.data,
          newLengthInMinutes: this.props.instance.numberOfMinutes,
          newStartTime: moment(this.props.instance.startTime).format("HH:mm"),
        })
      } catch (err) {
        // we should redirect them?
        console.log(err);
      }
    });
  }

  private addParticipants(){
    this.setState({loading: true}, async () => {
      try{
        const data: any = {
          additionalParticipants: this.state.accountsToAdd
        };
        await EventsAPI.createInstanceReservationAdminOverride(this.state.organization.id, this.state.template.id, this.state.instance.id, data);
        this.setState({ showAddUserModal: false }, () => { this.initialize(); })
      }catch(err){
        error("Could not add those participants");
        this.setState({loading: false});
      }
    })
  }

  private toggleShowDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private toggleShowEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private deleteInstance(){
    this.setState({ loading: true }, async () => {
      try{
        await EventsAPI.deleteEventTemplateInstance(this.state.instance.organizationId, this.state.instance.eventTemplateId, this.state.instance.id);
        success("Instance deleted!");
        this.setState({ showDeleteModal: false }, () => {
          this.props.onInstanceDeleted(this.props.instance);
        })
      }catch(err){
        error("Could not delete that instance. Please contact support.");
        this.setState({ loading: false });
      }
    });
  }

  private editInstance(){
    this.setState({ loading: true }, async () => {
      const times = this.state.newStartTime.split(":");
      const hour = times[0];
      const minutes = times[1];
      const startTime = moment(this.state.instance.startTime).hour(parseInt(hour)).minute(parseInt(minutes)).utc();
      
      const data = {
        eventTemplateId: this.state.selectedTemplate.id,
        startTime,
        numberOfMinutes: parseInt(this.state.newLengthInMinutes + ""),
      };
      const orgId = this.props.organization.id;
      const templateId = this.props.templateId;
      const instanceId = this.props.instance.id;
      try{
        await EventsAPI.updateEventTemplateInstance(orgId, templateId, instanceId, data);
        success("Instance updated!");
        this.setState({ showEditModal: false }, async () => {    
          this.props.onEditSaved(this.props.instance.id, data);
        })
      }catch(err){
        error("Could not update the instance");
        return this.setState({ loading: false });
      }
    })
    
  }

}