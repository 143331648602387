import * as React from "react";
import { Modal } from "react-bootstrap";

import { IPendingInvitation } from "src/api/organizations";

interface IInvitedUserListItemProps {
  invitation: IPendingInvitation;
  onDeleteInvitation: any;
}

interface IInvitedUserListItemState {
  loading: boolean;
  showRemoveModal: boolean;
}

export class InvitedUserListItem extends React.Component<IInvitedUserListItemProps, IInvitedUserListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showRemoveModal: false,
    };

    this.toggleRemoveModal = this.toggleRemoveModal.bind(this);
    this.deleteInvitation = this.deleteInvitation.bind(this);
  }

  public render() {
    return (
      <div className="row">
        <div className="col-7">
          {this.props.invitation.email}
        </div>
        <div className="col-3">
          {this.props.invitation.requestedOn.format("MM/DD/YY")}
        </div>
        <div className="col-2">
          <span className="oi oi-x icon icon-danger" title="Delete Invitation" onClick={this.toggleRemoveModal} />
        </div>
        <Modal show={this.state.showRemoveModal} onHide={this.toggleRemoveModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Remove User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove the invitation for {this.props.invitation.email} sent on {this.props.invitation.requestedOn.format("MM/DD/YYYY at HH:mm A")}?
            You will need to re-invite the user, which will generate a new signup code for them. This cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.deleteInvitation}>Yes, Remove This Invitation</button>
            <button className="btn btn-block btn-default" onClick={this.toggleRemoveModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggleRemoveModal(){
    this.setState({ showRemoveModal: !this.state.showRemoveModal})
  }

  private deleteInvitation(){
    // let the caller handle all of this
    this.props.onDeleteInvitation(this.props.invitation);
  }

}