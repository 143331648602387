import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as qs from "query-string";

import Card from "src/components/structure/Card";
import MarkdownEditor from "src/components/structure/MarkdownEditor";
import { IOrganization, OrganizationBlank } from "src/api/organizations";
import { OrganizationsAPI } from "src/api";

interface IJoinOrganizationScreenProps {
  location: any;
  history: any;
  userState: any;
}

interface IJoinOrganizationScreenState {
  loading: boolean;
  joinCode: string;
  organization: IOrganization;
  view: "join" | "lookup" | "submitted" | "denied",
  errorMessage: string;
}

class JoinOrganizationScreen extends React.Component<IJoinOrganizationScreenProps, IJoinOrganizationScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      joinCode: "",
      organization: OrganizationBlank,
      view: "lookup",
      errorMessage: "",
    };
    this.updateField = this.updateField.bind(this);
    this.lookupOrganization = this.lookupOrganization.bind(this);
    this.submitRequest = this.submitRequest.bind(this);

  }

  componentDidMount(){
    // if the user is not logged in, we prompt them to login and then redirect them here
    const params = qs.parse(this.props.location.search);
    let joinCode: any = params.jc && params.jc !== "" ? params.jc : "";
    if(joinCode === "") {
      // check local storage
      joinCode = localStorage.getItem("jc")
      if(!joinCode){
        joinCode = "";
      }
    }
    if(!this.props.userState.loggedIn){
      window.localStorage.jc = joinCode;
      window.localStorage.return = "organization_join";
      const jcEncoded = encodeURIComponent(joinCode)
      this.props.history.push(`/signup?return=organization_join&jc=${jcEncoded}`);
    }
    if(joinCode !== ""){
      if(typeof joinCode === "object"){
        joinCode = joinCode[0];
      }
      // set the state, then do the lookup
      this.setState({ joinCode: joinCode }, () => this.lookupOrganization());
    }
    // view should be lookup so they can lookup the organization by the join code
  }

  public render() {
    if(this.state.view === "lookup"){
      return (
        <div className="row">
          <div className="col-8 offset-2">
            <Card title="Lookup Organization" loading={this.state.loading} help="">
              <p>To get started, you need to enter the join code for the organization you wish to join. This should have been provided to you by the group.</p>
              <input type="text" id="joinCode" className="form-control" value={this.state.joinCode} onChange={this.updateField} placeholder="Join Code" />
              {this.state.errorMessage !== "" && (<p className="text-danger">{this.state.errorMessage}</p>)}
              <button className="btn btn-block btn-primary" style={{marginTop: 20}} onClick={this.lookupOrganization}>Lookup Organization By Join Code</button>
            </Card>
          </div>
        </div>
      );
    }
    if(this.state.view === "denied"){
      return (
        <div className="row">
          <div className="col-8 offset-2">
            <Card title="" loading={this.state.loading} help="">
              <p>Your request to join {this.state.organization.name} was denied. You will want to contact the organization for the reason.</p>
            </Card>
          </div>
        </div>
      );
    }
    if(this.state.view === "submitted"){
      return (
        <div className="row">
          <div className="col-8 offset-2">
            <Card title="" loading={this.state.loading} help="">
              <p>Your request to join {this.state.organization.name} has been submitted and a message has been sent to the administrators. Thanks!</p>
            </Card>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-8 offset-2">
          <Card title="" loading={this.state.loading} help="">
            <h2 style={{textAlign: "center"}}>{this.state.organization.name}</h2>
            <div className="row">
              <div className="col-12">
                <MarkdownEditor
                  content={this.state.organization.description}
                  mode="view"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <MarkdownEditor
                  content={this.state.organization.joinMessage}
                  mode="view"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-10 offset-1">
                {this.displayJoinAction}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {this.state.organization.joinAction !== "block" && (<button className="btn btn-block btn-primary" onClick={this.submitRequest}>Request To Join Now!</button>)}
                {this.state.errorMessage !== "" && (<p className="text-danger"><strong>{this.state.errorMessage}</strong></p>)}
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private lookupOrganization(){
    this.setState({ loading: true, errorMessage: "" }, async () => {
      try{
        const orgResult = await OrganizationsAPI.getOrganizationByJoinCode(this.state.joinCode);
        const org = orgResult.body.data;

        // if the user is already a member, redirect them to the appropriate area
        let view: "join" | "lookup" | "submitted" | "denied" = "join";
        if(org.status && org.status !== ""){
          if(org.status === "accepted"){
            if(org.status === "admin" || org.status === "billing"){
              return this.props.history.push(`/organizations/${org.id}/admin`);
            }
            return this.props.history.push(`/organizations/${org.id}`);
          }
          if(org.status === "denied"){
            view = "denied";
          }
          if(org.status === "requested"){
            view = "submitted"
          }
          if(org.status === "invited"){
            // redirect to the invite accept page
            return this.props.history.push(`/organizations/${org.id}/invites/respond`);
          }
        }

        this.setState({ loading: false, organization: orgResult.body.data, view: view, errorMessage: ""});
      }catch(err){
        this.setState({ loading: false, errorMessage: "Could not load a group with that join code. Please try again or contact the group you wish to join."})
      }
    });
  }

  get displayJoinAction(){
    if(this.state.organization.joinAction === "accept"){
      return (
        <p>This group will accept anyone who requests to join automatically. By continuing, you will automatically join the group.</p>
      );
    }
    if(this.state.organization.joinAction === "request"){
      return (
      <p>This group will approve requests to join individually. By continuing, you will request to join the group and an administrator from {this.state.organization.name} will need to approve or deny your request for membership.</p>
      );
    }
    if(this.state.organization.joinAction === "block"){
      return (
        <p><strong>This group does not allow joining with a join code. You will need to receive an invitation from the group prior to being allowed to join the group.</strong></p>
      );
    }
    return null;
  }

  private submitRequest(){
    this.setState({ loading: true, errorMessage: "" }, async () => {
      try{
        const result = await OrganizationsAPI.joinOrganization(this.state.organization.id, this.state.joinCode);
        if(result.body.data.status === "requested"){
          return this.setState({ loading: false, errorMessage: "", view: "submitted"});
        }
        this.props.history.push(`/organizations/${this.state.organization.id}`);
      }catch(err){
        let errorMessage = "We could not complete that join request. Please try again or contact support.";
        if(err.code && err.code === 402){
          errorMessage = "That organization has reached the maximum number of users. Please contact the organization for further details."
        }
        this.setState({ loading: false, errorMessage});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JoinOrganizationScreen) as any);