import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import MarkdownEditor from "src/components/structure/MarkdownEditor";
import { error, success } from "src/components/structure/Alert";
import * as AppActions from "src/reducers/appReducer";
import { IOrganization, OrganizationBlank } from "src/api/organizations";
import { OrganizationsAPI } from "src/api";

import {timezones} from "src/utils/datetime";

interface OrganizationInfoScreenProps {
  appActions: any;
  organization: IOrganization;
  organizationUpdated: any;
}

interface OrganizationInfoScreenState {
  loading: boolean;
  organization: IOrganization;
  hiddenJoinURL: string;
}

class OrganizationInfoScreen extends React.Component<OrganizationInfoScreenProps, OrganizationInfoScreenState> {

  private hiddenURLInput: any;

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      organization: OrganizationBlank,
      hiddenJoinURL: "",
    };

    this.updateField = this.updateField.bind(this);
    this.updateDescription = this.updateDescription.bind(this);
    this.updateJoinMessage = this.updateJoinMessage.bind(this);
    this.copy = this.copy.bind(this);
    this.saveOrganization = this.saveOrganization.bind(this);

  }

  componentDidMount() {
    this.setState({ organization: this.props.organization });
  }

  public render() {
    return (
      <Card title="" loading={false} help="">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="form-group">
              <label>Name</label>
              <input type="text" className="form-control" id="name" value={this.state.organization.name} onChange={this.updateField} />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="form-group">
              <label>Join Code</label>
              <div className="row">
                <div className="col-lg-10 col-md-12">
                  <input type="text" className="form-control" id="joinCode" value={this.state.organization.joinCode} onChange={this.updateField}  /><br />
                  <input type="text" style={{opacity: 0, height: 2}} onChange={()=>{}} id="hiddenJoinURL" value={this.state.hiddenJoinURL} ref={(input) => this.hiddenURLInput = input} />
                </div>
                <div className="col-lg-2 col-md-12">
                  <button className="btn btn-link fake-link" onClick={this.copy}>Copy</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="form-group">
              <label>Join Action</label>
              <select id="joinAction" className="form-control" value={this.state.organization.joinAction} onChange={this.updateField}>
                <option value="accept">Accept anyone with the correct join code</option>
                <option value="request">Users with the join code must be approved</option>
                <option value="block">No one can request to join this group</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="form-group">
              <label>Description</label>
              <MarkdownEditor
                content={this.state.organization.description}
                showToggle={true}
                showHelp={true}
                onChange={this.updateDescription}
                mode="edit"
                rows={5}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="form-group">
              <label>Join Message</label>
              <MarkdownEditor
                content={this.state.organization.joinMessage}
                showToggle={true}
                showHelp={true}
                onChange={this.updateJoinMessage}
                mode="edit"
                rows={5}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="form-group">
              <label>Timezone</label>
              <select id="timezone" className="form-control" value={this.state.organization.timezone} onChange={this.updateField}>
                {timezones.map((tz) => {
                  return (<option key={tz} value={tz}>{tz}</option>)
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
          {this.state.loading ? (
            <div style={{textAlign: "center"}}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) :
            (<button className="btn btn-block btn-primary" onClick={this.saveOrganization}>Save Changes</button>)}
          </div>
        </div>
      </Card>
    );
  }

  private updateField(e: any){
    const ns = this.state.organization;
    ns[e.target.id] = e.target.value;
    this.setState({ organization: ns});
  }

  private updateJoinMessage(content: string){
    const ns = this.state.organization;
    ns.joinMessage = content;
    this.setState({ organization: ns});
  }

  private updateDescription(content: string){
    const ns = this.state.organization;
    ns.description = content;
    this.setState({ organization: ns});
  }

  private copy(){
    const code = this.state.organization.joinCode;
    const escapedCode = encodeURIComponent(code);
    const url = `${window.location.origin}/organizations/join/?jc=${escapedCode}`;
    this.setState({hiddenJoinURL: url}, () => {
      const el = this.hiddenURLInput;
      el.select();
      document.execCommand("copy");
      success("Copied!");
    });
  }

  private saveOrganization(){
    const data = {
      ...this.state.organization,
      name: this.state.organization.name.trim(),
      description: this.state.organization.description.trim(),
      joinMessage: this.state.organization.joinMessage.trim(),
      joinCode: this.state.organization.joinCode.trim(),
      joinAction: this.state.organization.joinAction.trim(),
      timezone: this.state.organization.timezone,
    }
    if(data.name === "" || data.description === ""){
      return error("Name and Description are required");
    }
    if(data.joinCode.length < 7 || data.joinCode.length > 15){
      return error("The Join Code must be between 7 and 16 characters.");
    }

    this.setState({loading: true}, async () => {
      try{
        const result = await OrganizationsAPI.updateOrganization(this.props.organization.id, data);
        success("Saved!");
        this.setState({ loading: false }, () => {
          this.props.organizationUpdated(result.body.data);
        });
      }catch(err){
        this.setState({loading: false}, () => {
          error("Could not save that organization.");
        });
      }
    });
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationInfoScreen);