import * as billing from "./billing";
import * as cancellations from "./cancellations";
import * as incomingContact from "./incomingContact";
import * as events from "./events";
import * as org from "./organizations";
import * as st from "./scheduleTemplates";
import * as user from "./user";

export const BillingAPI = new billing.BillingAPI();
export const CancellationsAPI = new cancellations.CancellationsAPI();
export const IncomingContactAPI = new incomingContact.IncomingContactAPI();
export const EventsAPI = new events.EventsAPI();
export const OrganizationsAPI = new org.OrganizationsAPI();
export const ScheduleTemplatesAPI = new st.ScheduleTemplatesAPI();
export const UserAPI = new user.UserAPI();