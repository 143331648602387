
class Translation {

  public getHelpText(language: string = "en", key: string = "", defaultText: string = "") : string {
    return this.helpText[language] && this.helpText[language][key] ? this.helpText[language][key] : defaultText;
  }

  private helpText = {
    "en": {
      // dashboard
      "dashboard_groups": `Groups are organizations that you belong to. Groups create events and instances of the event. For example, a music studio could have the event 'Afternoon Lessons' and instances that run on weekdays at 4:00 and 6:00 PM. If you have been given a Join Code, you can enter it below to join an organization.`,
      "dashboard_subaccounts": `Subaccounts are accounts that you can create that do not login themselves. For example, you could create a subaccount for a child if you had children you wanted to register in events. Depending on how an event is configured, multiple subaccounts will count as a single reservation, bypassing the maximum participants for an event.`,
      "dashboard_upcoming": `Here you can see and manage all of the reservations you have made for yourself or your subaccounts.`,

      // member organizations
      "member_org_upcoming": "Upcoming events are listed below. If the event is available for registration, you can select Register. If you already have have a reservation, you will see a Manage link.",
      "member_org_accounts": "Subaccounts are accounts that you can create that do not login themselves. For example, you could create a subaccount for a child if you had children you wanted to register in events. Depending on how an event is configured, multiple subaccounts will count as a single reservation, bypassing the maximum participants for an event.",
      
      // member instance reserve
      "member_instance_reserve": "Below you will see the users and subaccounts you may manage. To request a reservation, you may hit the + icon. If the reservation is allowed, you will receive a success message. If you have exceeded any of the maximum reservation limits.",

      //
      // ADMIN
      //
      "organization-create-screen": `Create a new group using the form below. A group is a self-contained group of users that can create events for registration and signup. A join code allows other users to join an organization. If left blank, it will be randomly generated. If the Join Action is set to accept, anyone with the join code will be able to join the group without approval. If the Join Action is set to request, users with the join code can request to join the group, but will be unable to signup until accepted into the group.`,

    },
  };

};


export const Translator = new Translation();