import * as React from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import moment from "moment";

import Card from "src/components/structure/Card";
import DatePicker from "src/components/structure/DatePicker";
import * as AppActions from "src/reducers/appReducer";
import { IOrganization } from "src/api/organizations";
import { CancellationsAPI } from "src/api";
import { ICancellation } from "src/api/cancellations";

interface IAdminCancellationsListProps {
  appActions: any;
  organization: IOrganization;
}

interface IAdminCancellationsListState {
  loading: boolean;
  cancellations: ICancellation[];
  start: moment.Moment;
  end: moment.Moment;
}

class AdminCancellationsList extends React.Component<IAdminCancellationsListProps, IAdminCancellationsListState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      cancellations: [],
      start: moment(),
      end: moment().add(7, "days"),
    };

    this.setEndDate = this.setEndDate.bind(this);
    this.setStartDate = this.setStartDate.bind(this);

    this.fetchCancellations = this.fetchCancellations.bind(this);

  }

  componentDidMount(){
    this.fetchCancellations();
  }

  public render() {
    return (
        <div className="row">
        <div className="col-md-12 col-lg-10 offset-1">
          <Card title="Cancellation" loading={this.state.loading} help="">
            <div>
              <div className="row" style={{marginBottom: 20}}>
                <div className="col-md-6">
                  <label>Reservations from </label>
                  <DatePicker date={this.state.start} onDateSaved={this.setStartDate} />
                </div>
                <div className="col-md-6">
                  <label>To </label>
                  <DatePicker date={this.state.end} onDateSaved={this.setEndDate} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <strong>Event</strong>
              </div>
              <div className="col-1">
                <strong>Instance</strong>
              </div>
              <div className="col-1">
                <strong>Cancelled</strong>
              </div>
              <div className="col-2">
                <strong>Participant</strong>
              </div>
              <div className="col-2">
                <strong>Reason</strong>
              </div>
              <div className="col-4">
                <strong>Message</strong>
              </div>
            </div>
            {this.state.cancellations.length === 0 && (
              <div><strong>No cancellations exist in that time frame</strong></div>
            )}

            {this.state.cancellations.map((c: ICancellation, index: number) => {
              return (
                <div key={index} className="row">
                  <div className="col-2">
                    {c.eventName}
                  </div>
                  <div className="col-1">
                    {c.instanceStartTime.format("MM/DD/YY hh:mm A")}
                  </div>
                  <div className="col-1">
                    {c.cancelledOn.format("MM/DD/YY hh:mm A")}
                  </div>
                  <div className="col-2">
                    {c.participantFirstName} {c.participantLastName}
                  </div>
                  <div className="col-2">
                    {CancellationsAPI.translateReasonToDescription(c.cancelledReason)}
                  </div>
                  <div className="col-4">
                    {c.cancelledMessage}
                  </div>
                </div>
              );
            })}
          </Card>
        </div>
      </div>
    );
  }


  private fetchCancellations(){
    this.setState({loading: true}, async () => {
      try{
        const start = this.state.start.format("YYYY-MM-DDT") + "00:00:00Z";
        const end = this.state.end.format("YYYY-MM-DDT") + "00:00:00Z";
        const resResult = await CancellationsAPI.getOrganizationCancellations(this.props.organization.id, start, end);
        const resBody: ICancellation[] = resResult.body.data;
        const cancellations: ICancellation[] = [];
        for(const r of resBody){
          r.instanceStartTime = moment(r.instanceStartTime);
          r.cancelledOn = moment(r.cancelledOn);

          cancellations.push(r);
        }
        this.setState({loading: false, cancellations: cancellations,});
      }catch(err){ this.setState({loading: false})}
    });
  }


  private setStartDate(newDate: moment.Moment){
    this.setState({start: newDate}, () => this.fetchCancellations());
  }

  private setEndDate(newDate: moment.Moment){
    this.setState({end: newDate}, () => this.fetchCancellations());
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCancellationsList);