import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import Card from "src/components/structure/Card";
import {error, success} from "src/components/structure/Alert";
import * as AppActions from "src/reducers/appReducer";
import { UserAPI } from "src/api";
import { IUser, UserBlank } from "src/api/user";

import { timezones } from "src/utils/datetime";

interface IProfileProps {
  appActions: any;
  history: any;
}

interface IProfileState {
  loading: boolean;
  profile: IUser;
}

class Profile extends React.Component<IProfileProps, IProfileState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      profile: UserBlank,
    };
    this.fetch = this.fetch.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-lg-4 offset-lg-4">
          <Card title="Profile" loading={this.state.loading} help="">
            <div className="form-group">
              <label>First Name</label>
              <input type="text" id="firstName" className="form-control" value={this.state.profile.firstName} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input type="text" id="lastName" className="form-control" value={this.state.profile.lastName} onChange={this.updateField} />
            </div>
            {/* <div className="form-group">
              <label>Email Address</label>
              <input type="text" id="email" className="form-control" value={this.state.profile.email} onChange={this.updateField} />
            </div> */}
            <div className="form-group">
              <label>New Password</label>
              <input type="password" id="newPassword" className="form-control" value={this.state.profile.newPassword} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Timezone</label>
              <select id="timezone" className="form-control" value={this.state.profile.timezone} onChange={this.updateField}>
                {timezones.map((tz) => {
                  return (<option value={tz}>{tz}</option>);
                })}
              </select>
            </div>
            <div className="form-group">
              <button className="btn btn-primary btn-block" onClick={this.updateProfile}>Update</button>
            </div>
            <div className="form-group">
              <p>
                Account Created: {this.state.profile.createdOn.format("MM/DD/YYYY")}<br />
                Last Login: {this.state.profile.lastLogin.format("MM/DD/YYYY")}<br />
              </p>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  private updateField(e: any){
    const profile = this.state.profile;
    profile[e.target.id] = e.target.value;
    this.setState({ profile})
  }

  private fetch(){
    this.setState({ loading: true }, async () => {
      try{
        const profileResult = await UserAPI.getUserProfile();
        const profile: IUser = profileResult.body.data;
        profile.createdOn = moment(profile.createdOn);
        profile.lastLogin = moment(profile.lastLogin);
        this.setState({profile: profile, loading: false});
      }catch(err){}
    });
  }

  private updateProfile(){
    const data = {
      firstName: this.state.profile.firstName.trim(),
      lastName: this.state.profile.lastName.trim(),
      email: this.state.profile.email.trim(),
      password: this.state.profile.newPassword?.trim(),
      timezone: this.state.profile.timezone,
    };
    if(data.password && data.password !== "" && data.password.length < 8){
      return error("Please use a longer password.");
    }
    if(data.firstName === "" || data.lastName === "" || data.email === ""){
      return error("First Name, Last Name, and Email cannot be blank.")
    }
    this.setState({ loading: true}, async () => {
      try{
        await UserAPI.updateUserProfile(data);
        const p = this.state.profile;
        p.newPassword = "";
        success("Saved!");
        this.setState({loading: false, profile: p});
      }catch(err){
        error("Could not update your profile. Contact support. If you were attempted to change your password, it may not have met the basic security requirements.");
        this.setState({loading: false});
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile) as any);