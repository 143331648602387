import * as React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import Screen from "src/components/structure/Screen";
import Card from "../structure/Card";
import * as AppActions from "../../reducers/appReducer";
import { Translator } from "src/utils/translator";

import { OrganizationsAPI, EventsAPI, UserAPI } from "../../api";
import { IEventReservation } from "src/api/events";
import { IOrganization } from "src/api/organizations";
import { ISubaccount } from "src/api/user";
import { Modal } from "react-bootstrap";

interface IDashboardProps {
  appActions: any;
  appState: any;
  userState: any;
  history: any;
}

interface IDashboardState {
  loading: boolean;
  organizations: IOrganization[];
  reservations: IEventReservation[];
  subaccounts: ISubaccount[];
  invitations: any[];

  showInvitiationsModal: boolean;
}

const groupHelp = Translator.getHelpText("en", "dashboard_groups");
const subHelp = Translator.getHelpText("en", "dashboard_subaccounts");
const upcomingHelp = Translator.getHelpText("en", "dashboard_upcoming");

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      organizations: [],
      reservations: [],
      subaccounts: [],
      showInvitiationsModal: false,
      invitations: [],
    };

    this.fetch = this.fetch.bind(this);
    this.toggleShowInvitationsModal = this.toggleShowInvitationsModal.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  public render() {
    return (
      <Screen fileName="Dashboard.tsx">
        <div className="row">
          <div className="col-lg-3 col-md-12">
            <Card title="News" loading={false} help="">
              Thank you for being a part of our beta test! We have new features coming every week, so we appreciate your patience and feedback.
          </Card>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="row">
              <div className="col-12">
                <Card title="My Groups" loading={this.state.loading} help={groupHelp}>
                  {this.state.organizations.length === 0 && (<strong>You do not currently belong to any group.</strong>)}
                  {this.state.organizations.map((org: any) => {
                    if (org.status === "denied") {
                      return null;
                    }
                    // show the invite screen
                    if (org.userStatus === "invited") {
                      let link = `/organizations/${org.id}/invites/respond?ic=${org.requestCode ? org.requestCode : ''}`;
                      return (
                        <div className="row" key={org.id} style={{ marginBottom: 5 }}>
                          <div className="col-lg-9 col-sm-12">
                            <strong style={{ fontSize: 20 }}><Link to={link}>{org.name}</Link></strong>
                          </div>
                          <div className="col-lg-3 col-sm-12">
                            <Link to={link}>Invited</Link>
                          </div>
                        </div>
                      );
                    }
                    // requested?
                    if (org.userStatus === "requested") {
                      return (
                        <div className="row" key={org.id} style={{ marginBottom: 5 }}>
                          <div className="col-lg-9 col-sm-12">
                            <strong style={{ fontSize: 20 }}>{org.name}</strong>
                          </div>
                          <div className="col-lg-3 col-sm-12">
                            Pending Acceptance
                          </div>
                        </div>
                      );
                    }

                    let orgLink = `/organizations/${org.id}`;
                    if (org.userRole === "admin" || org.userRole === "billing") {
                      orgLink = `/organizations/${org.id}/admin/events`;
                    }
                    return (
                      <div className="row" key={org.id} style={{ marginBottom: 5 }}>
                        <div className="col-lg-9 col-sm-12">
                          <strong style={{ fontSize: 20 }}><Link to={orgLink}>{org.name}</Link></strong>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                          <Link to={orgLink}>Events </Link>
                        </div>
                      </div>
                    )
                  })}
                  <div className="row" style={{ marginTop: 30 }}>
                    <div className="col-md-6">
                      <Link to="/organizations/join">I have a Join Code</Link>
                    </div>
                    <div className="col-md-6">
                      <Link to="/organizations/create">Create New Group</Link>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="row" style={{ marginTop: 10 }}>
              <div className="col-12">
                <Card title="My Subaccounts" loading={this.state.loading} help={subHelp}>
                  {this.state.subaccounts.length === 0 && (<strong>No subaccounts created</strong>)}
                  {this.state.subaccounts.map((ac) => {
                    return (
                      <div className="row" key={ac.id}>
                        <div className="col-12">
                          <Link to="/subaccounts" style={{ fontSize: 20 }}>{ac.firstName} {ac.lastName}</Link>
                        </div>
                      </div>
                    );
                  })}
                  <div className="row">
                    <div className="col-6 offset-6">
                      <Link to="/subaccounts">Manage Subaccounts</Link>
                    </div>
                  </div>
                </Card>
              </div>
            </div>

          </div>
          <div className="col-lg-5 col-md-12">
            <Card title="Upcoming Reservations" loading={this.state.loading} help={upcomingHelp}>
              {this.state.reservations.length === 0 && (<strong>No reservations in the next two weeks</strong>)}
              {this.state.reservations.map((res: IEventReservation) => {
                return (
                  <div className="row" key={res.id}>
                    <div className="col-lg-4 col-sm-12">
                      {res.eventName}
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      {res.participantFirstName} {res.participantLastName}
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      {res.instanceStartTime.format("MM/DD h:mm A")}
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <Link className="oi oi-arrow-thick-right icon" to={`/organizations/${res.organizationId}/events/${res.eventTemplateId}/instances/${res.eventInstanceId}/register`} ></Link>
                    </div>

                  </div>
                );
              })}
            </Card>
          </div>
        </div>
        <Modal show={this.state.showInvitiationsModal} onHide={this.toggleShowInvitationsModal} dialogClassName="modal-50">
          <Modal.Header closeButton={true}>
            <Modal.Title>Pending Invitations</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <strong>You have invitations pending to join the following organizations:</strong>
              </div>
            </div>
            {this.state.organizations.map((org: any) => {
              if(org.userStatus !== "invited"){
                return null;
              }
              let link = `/organizations/${org.id}/invites/respond?ic=${org.requestCode ? org.requestCode : ''}`;
              return (
                <div className="row" key={org.id} style={{marginTop: 20}}>
                  <div className="col-6">
                    <strong>{org.name}</strong>
                  </div>
                  <div className="col-6">
                    <Link to={link} className="btn btn-block btn-primary">Respond</Link>
                  </div>
                </div>
              )
            })}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-secondary" onClick={this.toggleShowInvitationsModal} >Close</button>
          </Modal.Footer>
        </Modal>
      </Screen>
    );
  }



  private fetch() {
    // eventually do something
    const start = moment().format("YYYY-MM-DD") + "T00:00:00Z";
    const end = moment().add(14, "days").format("YYYY-MM-DD") + "T23:59:59Z";
    this.setState({ loading: true }, async () => {
      try {
        const orgsResult = await OrganizationsAPI.getUserOrganizations();
        const resResult = await EventsAPI.getUserReservations({ start, end });
        const subResult = await UserAPI.getSubaccounts();
        const reservations: IEventReservation[] = [];
        const current = moment();
        for (const r of resResult.body.data) {
          // there's a bug on the backend that doesn't respect the start / end filters, so just filter them out client side
          r.instanceStartTime = moment(r.instanceStartTime);
          if(r.instanceStartTime.isAfter(current)){
            reservations.push(r);
          }
        }
        let hasPending = false;
        let showWelcomeModal = false;
        if (!this.props.appState.seenWelcomeModal) {
          // they may have cleared cookies, so see if their createOn is newer than two days
          const createdOn = moment(this.props.userState.user.createdOn, "YYYY-MM-DD");
          const thresh = moment().subtract(2, "days");
          if (createdOn.isAfter(thresh)) {
            this.props.appActions.toggleWelcomeModal();
            showWelcomeModal = true;
          }
        } 
        // check if they have any pending invitations
        for(const o of orgsResult.body.data){
          if(o.userStatus === "invited"){
            hasPending = true;
            break;
          }
        }
        const showInvitiationsModal = showWelcomeModal ? false : hasPending;
        this.setState({ loading: false, organizations: orgsResult.body.data, reservations, subaccounts: subResult.body.data, showInvitiationsModal });
      } catch (err) {
        console.log(err);
        this.setState({ loading: false });
      }
    })
  };

  private toggleShowInvitationsModal(){
    this.setState({ showInvitiationsModal: !this.state.showInvitiationsModal });
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard) as any);