import * as React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { IOrganization } from "src/api/organizations";
import { IEventInstance, IEventTemplate } from "src/api/events";
import { EventsAPI } from "src/api";
import { success, error } from "src/components/structure/Alert";

interface IEventInstanceLineItemProps {
  organization: IOrganization;
  template: IEventTemplate;
  instance: IEventInstance;
  onDeleteInstance: (instance: IEventInstance) => void;
}

interface IEventInstanceLineItemState {
  loading: boolean;
  showRemoveModal: boolean;
}

export default class EventInstanceLineItem extends React.Component<IEventInstanceLineItemProps, IEventInstanceLineItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showRemoveModal: false,
    };

    this.toggleRemoveModal = this.toggleRemoveModal.bind(this);
    this.deleteInstance = this.deleteInstance.bind(this);
  }

  public render() {
    const link = `/organizations/${this.props.organization.id}/admin/events/${this.props.instance.eventTemplateId}/instances/${this.props.instance.id}`;
    return (
      <div className="row" style={{marginBottom: 15}}>
        <div className="col-lg-4 col-12">
          {this.props.instance.startTime.format("ddd, MM/DD/YY hh:mm A")}
        </div>
        <div className="col-lg-3 col-4">
          {this.props.instance.numberOfMinutes}m
        </div>
        <div className="col-lg-2 col-6 offset-2 offset-lg-0">
          {this.props.instance.currentReservations} reservations
        </div>
        <div className="col-lg-1 col-6">
          <Link to={link}><span className="oi oi-document icon icon-primary" title="View" /></Link>
        </div>
        <div className="col-lg-1 col-6">
          <span className="oi oi-x icon icon-danger" title="Delete Instance" onClick={this.toggleRemoveModal} />
        </div>
        <Modal show={this.state.showRemoveModal} onHide={this.toggleRemoveModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Instance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <span className="text-danger" style={{fontWeight: "bold"}}>Warning: </span> Deleting this instance will cancel all reservations, with an email being sent for each user with a reservation that the instance has been cancelled. This cannot be undone. Are you absolutely sure you want to delete this instance?
            </p>
            <p style={{fontWeight: "bold"}}>
              {this.props.template.name} on {this.props.instance.startTime.format("ddd, MM/DD/YY")} at {this.props.instance.startTime.format("hh:mm A")} with {this.props.instance.currentReservations} current reservations.
            </p>
          </Modal.Body> 
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.deleteInstance}>Yes, Delete This Instance</button>
            <button className="btn btn-block btn-default" onClick={this.toggleRemoveModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggleRemoveModal(){
    this.setState({showRemoveModal: !this.state.showRemoveModal});
  }

  private deleteInstance(){
    this.setState({ loading: true }, async () => {
      try{
        await EventsAPI.deleteEventTemplateInstance(this.props.organization.id, this.props.instance.eventTemplateId, this.props.instance.id);
        success("Instance cancelled!");
        this.props.onDeleteInstance(this.props.instance);
      }catch(err){
        error("We could not delete that instance.");
        this.setState({loading: false});
      }
    })
  }

}