import moment from "moment";
import * as React from "react";
import { Modal } from "react-bootstrap";
import { EventsAPI, ScheduleTemplatesAPI } from "src/api";
import { IEventTemplate } from "src/api/events";
import { IOrganization } from "src/api/organizations";
import { IScheduleTemplate, IScheduleTemplateItem, ScheduleTemplateBlank, ScheduleTemplateItemBlank } from "src/api/scheduleTemplates";
import { error, success } from "src/components/structure/Alert";
import { Calendar, momentLocalizer } from "react-big-calendar";

import Card from "src/components/structure/Card";
import TimePicker from "src/components/structure/TimePicker";
import DatePicker from "src/components/structure/DatePicker";

const localizer = momentLocalizer(moment);

interface IScheduleTemplatesProps {
  organization: IOrganization;
}

interface IScheduleTemplatesState {
  loading: boolean;
  eventTemplates: IEventTemplate[];
  allTemplates: IScheduleTemplate[];
  selectedTemplate: IScheduleTemplate;

  showCreateTemplateModal: boolean;
  newTemplateName: string;

  showCreateItemModal: boolean;
  newItemEventTemplateId: number;
  newItemStartDay: string;
  newItemStartTime: string;
  newItemNumberOfMinutes: number;
  instanceUpdateKey: number;
  minHourForCalendar: Date;
  maxHourForCalendar: Date;

  selectedItem: IScheduleTemplateItem;

  showDeleteTemplateModal: boolean;
  showEditItemModal: boolean;
  showDeleteItemModal: boolean;

  showReleaseOnModal: boolean;
  releaseOnStart: moment.Moment;
  releaseOnEnd: moment.Moment;
  releaseOnDate: moment.Moment;
  releaseOnTime: string;
  releaseOnType: "immediately" | "pending";
}

export class ScheduleTemplates extends React.Component<IScheduleTemplatesProps, IScheduleTemplatesState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      eventTemplates: [],
      allTemplates: [],
      selectedTemplate: ScheduleTemplateBlank,

      showCreateTemplateModal: false,
      newTemplateName: "",

      showCreateItemModal: false,
      newItemEventTemplateId: 0,
      newItemStartDay: "monday",
      newItemStartTime: "15:00",
      newItemNumberOfMinutes: 30,
      instanceUpdateKey: 0,
      minHourForCalendar: new Date(2020, 1, 1, 7),
      maxHourForCalendar: new Date(2020, 1, 1, 23),

      selectedItem: ScheduleTemplateItemBlank,
      showDeleteTemplateModal: false,
      showEditItemModal: false,
      showDeleteItemModal: false,


      showReleaseOnModal: false,
      releaseOnStart: moment().add(1, "days"),
      releaseOnEnd: moment().add(2, "weeks"),
      releaseOnDate: moment().add(1, "days"),
      releaseOnType: "immediately",
      releaseOnTime: "12:00",
    };

    this.updateField = this.updateField.bind(this);
    this.updateTemplateField = this.updateTemplateField.bind(this);
    this.updateNewItemStartTimeField = this.updateNewItemStartTimeField.bind(this);
    this.updateReleaseOnDateDateField = this.updateReleaseOnDateDateField.bind(this);
    this.updateReleaseOnEndDateField = this.updateReleaseOnEndDateField.bind(this);
    this.updateReleaseOnStartDateField = this.updateReleaseOnStartDateField.bind(this);
    this.updateReleaseOnDateTimeField = this.updateReleaseOnDateTimeField.bind(this);
    this.setup = this.setup.bind(this);
    this.toggleShowCreateTemplateModal = this.toggleShowCreateTemplateModal.bind(this);
    this.toggleShowDeleteTemplateModal = this.toggleShowDeleteTemplateModal.bind(this);
    this.toggleShowCreateItemModal = this.toggleShowCreateItemModal.bind(this);
    this.toggleShowDeleteItemModal = this.toggleShowDeleteItemModal.bind(this);
    this.toggleShowEditItemModal = this.toggleShowEditItemModal.bind(this);
    this.toggleShowReleaseOnModal = this.toggleShowReleaseOnModal.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
    this.applyTemplate = this.applyTemplate.bind(this);

    this.createTemplateItem = this.createTemplateItem.bind(this);
    this.selectTemplateItem = this.selectTemplateItem.bind(this);
    this.updateSelectedItemStartTimeField = this.updateSelectedItemStartTimeField.bind(this);
    this.updateTemplateItemField = this.updateTemplateItemField.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);

  }

  componentDidMount() {
    this.setup();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-lg-3 col-sm-12">
          <Card title="Templates" loading={this.state.loading} help="Schedule Templates help you pre-configure a week's worth of instances and then optionally choose to release them.">
            {this.state.allTemplates.length === 0 && (
              <p><strong>You don't have any Schedule Templates created. Get started below!</strong></p>
            )}
            {this.state.allTemplates.map((temp) => {
              return (
                <div className="row" key={temp.name}>
                  <div className="col-6">
                    {temp.name}
                  </div>
                  <div className="col-4">
                    {temp.status}
                  </div>
                  <div className="col-2">
                    <span className="oi oi-arrow-thick-right icon icon-primary" title="Select Template" onClick={() => { this.selectTemplate(temp) }} />
                  </div>
                </div>
              );
            })}

            <div className="row">
              <div className="col-12">
                <button className="btn btn-block btn-primary" onClick={this.toggleShowCreateTemplateModal}>Create New Template</button>
              </div>
            </div>
          </Card>

          <Modal show={this.state.showCreateTemplateModal} onHide={this.toggleShowCreateTemplateModal}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Create New Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Enter a name for the new template so you can easily identify it for future use.</p>
              <input type="text" className="form-control" id="newTemplateName" value={this.state.newTemplateName} onChange={this.updateField} />
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-block btn-primary" onClick={this.createTemplate}>Create</button>
              <button className="btn btn-block btn-default" onClick={this.toggleShowCreateTemplateModal}>Nevermind</button>
            </Modal.Footer>
          </Modal>
        </div>
        {this.state.selectedTemplate.id !== 0 && (
          <div className="col-lg-9 col-sm-12">
            <Card title={`Managing ${this.state.selectedTemplate.name}`} loading={this.state.loading} help="">
              <div className="row">
                <div className="col-4">
                  <input type="text" className="form-control" id="name" value={this.state.selectedTemplate.name} onChange={this.updateTemplateField} />
                </div>
                <div className="col-4">
                  <select className="form-control" id="status" value={this.state.selectedTemplate.status} onChange={this.updateTemplateField}>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div className="col-4">
                  <button className="btn btn-block btn-primary" onClick={this.updateTemplate}>Update Metadata</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button className="btn btn-block btn-primary" onClick={this.toggleShowCreateItemModal}>Create New Template Item</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button className="btn btn-block btn-success" onClick={this.toggleShowReleaseOnModal}>Apply Template</button>
                </div>
              </div>
              <div className="row" style={{marginTop: 30}}>
                <div className="col-12">
                  <div className="schedule-template-weekday-header">
                    <div className="rbc-calendar" style={{ height: 25 }}>
                      <div className="rbc-time-view">
                        <div className="rbc-time-header">
                          <div className="rbc-label rbc-time-header-gutter" style={{ width: 69.8125, minWidth: 69.8125, maxWidth: 69.8125 }}>
                          </div>
                          <div className="rbc-time-header-content">
                            <div className="rbc-row rbc-time-header-cell">
                              <div className="rbc-header"><span>Sun</span></div>
                              <div className="rbc-header"><span>Mon</span></div>
                              <div className="rbc-header"><span>Tue</span></div>
                              <div className="rbc-header"><span>Wed</span></div>
                              <div className="rbc-header"><span>Thu</span></div>
                              <div className="rbc-header"><span>Fri</span></div>
                              <div className="rbc-header"><span>Sat</span></div>
                            </div>
                            <div className="rbc-allday-cell">
                              <div className="rbc-row-bg">
                                <div className="rbc-day-bg"></div>
                                <div className="rbc-day-bg"></div>
                                <div className="rbc-day-bg"></div>
                                <div className="rbc-day-bg"></div>
                                <div className="rbc-day-bg"></div>
                                <div className="rbc-day-bg"></div>
                                <div className="rbc-day-bg rbc-today"></div>
                              </div>
                              <div className="rbc-row-content">
                                <div className="rbc-row"></div>
                                <div className="rbc-row"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: 800 }} className="schedule-template-calendar-holder">
                    <Calendar
                      key={this.state.instanceUpdateKey}
                      defaultDate={moment().toDate()}
                      localizer={localizer}
                      events={this.state.selectedTemplate.items ? (this.state.selectedTemplate.items as any) : []}
                      startAccessor="startTimeDate"
                      endAccessor="endTimeDate"
                      min={this.state.minHourForCalendar}
                      max={this.state.maxHourForCalendar}
                      defaultView={"week"}
                      views={["week"]}
                      step={10}
                      onSelectEvent={(event: any) => { this.selectTemplateItem(event); }}
                    />
                  </div>
                </div>
              </div>
            </Card>

            <Modal show={this.state.showCreateItemModal} onHide={this.toggleShowCreateItemModal}>
              <Modal.Header closeButton={true}>
                <Modal.Title>Create New Template Item</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label>Event Template</label>
                  <select className="form-control" value={this.state.newItemEventTemplateId} id="newItemEventTemplateId" onChange={this.updateField}>
                    {this.state.eventTemplates.map((temp) => {
                      return (<option key={temp.id} value={temp.id}>{temp.name}</option>)
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Day of the Week</label>
                  <select className="form-control" value={this.state.newItemStartDay} id="newItemStartDay" onChange={this.updateField}>
                    <option value="sunday">Sunday</option>
                    <option value="monday">Monday</option>
                    <option value="tuesday">Tuesday</option>
                    <option value="wednesday">Wednesday</option>
                    <option value="thursday">Thursday</option>
                    <option value="friday">Friday</option>
                    <option value="saturday">Saturday</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Time of Day</label>
                  <TimePicker value={this.state.newItemStartTime} id="newItemStartTime" onChange={this.updateNewItemStartTimeField} />
                </div>
                <div className="form-group">
                  <label>Number of Minutes</label>
                  <input type="text" className="form-control" value={this.state.newItemNumberOfMinutes} id="newItemNumberOfMinutes" onChange={this.updateField} />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-block btn-primary" onClick={this.createTemplateItem}>Create</button>
                <button className="btn btn-block btn-default" onClick={this.toggleShowCreateItemModal}>Nevermind</button>
              </Modal.Footer>
            </Modal>

            <Modal show={this.state.showDeleteTemplateModal} onHide={this.toggleShowDeleteTemplateModal}>
              <Modal.Header closeButton={true}>
                <Modal.Title>Delete Template</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you absolutely sure you want to delete the {this.state.selectedTemplate.name} template? This cannot be undone!
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-block btn-danger" onClick={() => {}}>Delete</button>
                <button className="btn btn-block btn-default" onClick={this.toggleShowDeleteTemplateModal}>Nevermind</button>
              </Modal.Footer>
            </Modal>

            <Modal show={this.state.showDeleteItemModal} onHide={this.toggleShowDeleteItemModal}>
              <Modal.Header closeButton={true}>
                <Modal.Title>Delete Item</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you absolutely sure you want to delete the {this.state.selectedItem.title} item on {this.state.selectedItem.startDay}? This cannot be undone!
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-block btn-danger" onClick={this.deleteItem}>Delete</button>
                <button className="btn btn-block btn-default" onClick={this.toggleShowDeleteItemModal}>Nevermind</button>
              </Modal.Footer>
            </Modal>

            <Modal show={this.state.showEditItemModal} onHide={this.toggleShowEditItemModal}>
              <Modal.Header closeButton={true}>
                <Modal.Title>Edit Item</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label>Event Template</label>
                  <select className="form-control" value={this.state.selectedItem.eventTemplateId} id="eventTemplateId" onChange={this.updateTemplateItemField}>
                    {this.state.eventTemplates.map((temp) => {
                      return (<option key={temp.id} value={temp.id}>{temp.name}</option>)
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Day of the Week</label>
                  <select className="form-control" value={this.state.selectedItem.startDay} id="startDay" onChange={this.updateTemplateItemField}>
                    <option value="sunday">Sunday</option>
                    <option value="monday">Monday</option>
                    <option value="tuesday">Tuesday</option>
                    <option value="wednesday">Wednesday</option>
                    <option value="thursday">Thursday</option>
                    <option value="friday">Friday</option>
                    <option value="saturday">Saturday</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Time of Day</label>
                  <TimePicker value={this.state.selectedItem.startTime} id="startTime" onChange={this.updateSelectedItemStartTimeField} />
                </div>
                <div className="form-group">
                  <label>Number of Minutes</label>
                  <input type="text" className="form-control" value={this.state.selectedItem.numberOfMinutes} id="numberOfMinutes" onChange={this.updateTemplateItemField} />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-block btn-primary" onClick={this.updateItem}>Save</button>
                <button className="btn btn-block btn-danger" onClick={() => {
                  this.setState({ showEditItemModal: false, showDeleteItemModal: true})
                }}>Delete</button>
                <button className="btn btn-block btn-default" onClick={this.toggleShowEditItemModal}>Nevermind</button>
              </Modal.Footer>
            </Modal>

            <Modal show={this.state.showReleaseOnModal} onHide={this.toggleShowReleaseOnModal}>
              <Modal.Header closeButton={true}>
                <Modal.Title>Release Template</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  You can choose to release this template on a given date range with a specified availability date. The system will then start on that date you selected and loop through each day in the range. For that day, it will create instances for that day as set up in this template. Once released, you will have to delete or edit each intance individually, so ensure your range and template are exactly as you want them prior to hitting Release.
                </div>
                <div className="form-group">
                  <label>Start On</label>
                  <DatePicker date={this.state.releaseOnStart} onDateSaved={this.updateReleaseOnStartDateField} />
                </div>
                <div className="form-group">
                  <label>Apply Through</label>
                  <DatePicker date={this.state.releaseOnEnd} onDateSaved={this.updateReleaseOnEndDateField} />
                </div>
                <div className="form-group">
                  <label>Availability</label>
                  <select className="form-control" id="releaseOnType" value={this.state.releaseOnType} onChange={this.updateField}>
                    <option value="immediately">Make Available For Reservations Immediately</option>
                    <option value="pending">Make Available On:</option>
                  </select>
                </div>
                {this.state.releaseOnType === "pending" && (
                  <div>
                    <div className="form-group">
                      <DatePicker date={this.state.releaseOnDate} onDateSaved={this.updateReleaseOnDateDateField} />
                    </div>
                    <div className="form-group">
                      <TimePicker id="releaseOnTime" value={this.state.releaseOnTime} onChange={this.updateReleaseOnDateTimeField} />
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-block btn-primary" onClick={this.applyTemplate}>Release</button>
                <button className="btn btn-block btn-default" onClick={this.toggleShowReleaseOnModal}>Nevermind</button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </div>
    );
  }

  private setup() {
    this.setState({ loading: true }, async () => {
      try {
        const templatesResult = await ScheduleTemplatesAPI.getScheduleTemplates(this.props.organization.id);
        const eventTemplatesResult = await EventsAPI.getEventTemplates(this.props.organization.id);
        const eventTemplates = eventTemplatesResult.body.data;
        
        this.setState({ 
          loading: false, 
          allTemplates: templatesResult.body.data, 
          eventTemplates,
          newItemEventTemplateId: eventTemplates.length > 0 ? eventTemplates[0].id : 0,
        });
      } catch (err) {
        this.setState({ loading: true });
      }
    })
  }

  private updateField(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateTemplateField(e: any) {
    const selectedTemplate = this.state.selectedTemplate;
    selectedTemplate[e.target.id] = e.target.value;
    this.setState({ selectedTemplate });
  }

  private updateTemplateItemField(e: any) {
    const selectedItem = this.state.selectedItem;
    selectedItem[e.target.id] = e.target.value;
    this.setState({ selectedItem });
  }

  private updateNewItemStartTimeField(newTime: string) {
    const ns: any = this.state;
    ns["newItemStartTime"] = newTime;
    this.setState(ns);
  }

  private updateReleaseOnStartDateField(newTime: moment.Moment) {
    const ns: any = this.state;
    ns["releaseOnStart"] = newTime;
    this.setState(ns);
  }

  private updateReleaseOnEndDateField(newTime: moment.Moment) {
    const ns: any = this.state;
    ns["releaseOnEnd"] = newTime;
    this.setState(ns);
  }

  private updateReleaseOnDateDateField(newTime: moment.Moment) {
    const ns: any = this.state;
    ns["releaseOnDate"] = newTime;
    this.setState(ns);
  }

  private updateReleaseOnDateTimeField(newTime: string) {
    const ns: any = this.state;
    ns["releaseOnTime"] = newTime;
    this.setState(ns);
  }

  private updateSelectedItemStartTimeField(newTime: string) {
    const ns: any = this.state.selectedItem;
    ns["startTime"] = newTime;
    this.setState({selectedItem: ns});
  }


  private toggleShowCreateTemplateModal() {
    this.setState({ showCreateTemplateModal: !this.state.showCreateTemplateModal })
  }

  private toggleShowCreateItemModal() {
    this.setState({ showCreateItemModal: !this.state.showCreateItemModal })
  }

  private toggleShowReleaseOnModal(){
    this.setState({ showReleaseOnModal: !this.state.showReleaseOnModal });
  }

  private toggleShowEditItemModal(){
    this.setState({ showEditItemModal: !this.state.showEditItemModal });
  }

  private toggleShowDeleteItemModal(){
    this.setState({ showDeleteItemModal: !this.state.showDeleteItemModal });
  }

  private toggleShowDeleteTemplateModal(){
    this.setState({ showDeleteTemplateModal: !this.state.showDeleteTemplateModal });
  }

  private selectTemplate(selectedTemplate: IScheduleTemplate) {
    this.setState({ loading: true }, () => {
      // we need to loop through the items and set a date 
      const {min, max, items } = this.processItems(selectedTemplate.items);

      const minHourForCalendar = new Date(2020, 1, 1, min)
      const maxHourForCalendar = new Date(2020, 1, 1, max)
      const newKey = this.state.instanceUpdateKey + 1;
      selectedTemplate.items = items;

      this.setState({ loading: false, selectedTemplate, minHourForCalendar, maxHourForCalendar, instanceUpdateKey: newKey });
    });
  }

  private processItems(rawItems: IScheduleTemplateItem[]): any {
    // we need to loop through the items and set a date 
    const items: IScheduleTemplateItem[] = [];
    let min = 23;
    let max = 0;

    // if today is the end of the day saturday, there's a chance all
    // of the events will be in the next week, so we will need to -1 on the
    // week of the events; we may want to consider just changing this into a list
    // since the calendar stuff is pretty messy at this point
    const now = moment().weekday();
    let daysAdjustment = 0;
    if(now === 6){ 
      daysAdjustment = -7;
    }

    
    for (const i of rawItems) {
      const split = splitTime(i.startTime);
      const startTime = moment().utc().day(i.startDay).add(daysAdjustment, "days").hour(split[0]).minute(split[1]).local();
      const endTime = moment(startTime).clone().add(i.numberOfMinutes, "minutes");

      if (startTime.hour() < min) {
        min = startTime.hour();
      }
      if (endTime.hour() > max) {
        max = endTime.clone().add(1, "hour").hour();
      }

      let title = "";
      for (const e of this.state.eventTemplates) {
        if (e.id === i.eventTemplateId) {
          title = e.name;
          break;
        }
      }
      i.title = title;

      i.startTimeDate = startTime.toDate();
      i.endTimeDate = endTime.toDate();
      items.push(i);
    }
    return {
      items,
      min,
      max
    };
  }

  private createTemplate() {
    const name = this.state.newTemplateName.trim();
    if (name === "") {
      return error("You must provide a name for your template.");
    }
    this.setState({ loading: true }, async () => {
      try {
        const result = await ScheduleTemplatesAPI.createScheduleTemplate(this.props.organization.id, name);
        const template = result.body.data;
        let allTemplates = this.state.allTemplates;
        allTemplates.push(template);
        allTemplates = allTemplates.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
        this.setState({ loading: false, showCreateTemplateModal: false, newTemplateName: "", allTemplates, selectedTemplate: template });
      } catch (err) {
        error("Could not create that template.");
        return this.setState({ loading: false });
      }
    });
  }


  private updateTemplate() {
    const name = this.state.selectedTemplate.name.trim();
    if (name === "") {
      return error("You must provide a name for your template.");
    }
    this.setState({ loading: true }, async () => {
      try {
        const data = {
          ...this.state.selectedTemplate
        }
        await ScheduleTemplatesAPI.updateScheduleTemplate(this.props.organization.id, this.state.selectedTemplate.id, data);
        this.setState({ loading: false });
      } catch (err) {
        error("Could not create that template.");
        return this.setState({ loading: false });
      }
    });
  }

  private createTemplateItem() {
    this.setState({ loading: true }, async () => {
      try {
        const minutes = parseInt(this.state.newItemNumberOfMinutes + ""); // even tho we declared it as a number, it's a string from the input
        if (minutes === 0) {
          error("Minutes cannot be 0");
          return this.setState({ loading: false });
        }
        const split = this.state.newItemStartTime.split(":")
        const h = parseInt(split[0]);
        const m = parseInt(split[1]);
        let dayOfWeek = this.state.newItemStartDay;
        const startTimeMoment = moment().day(dayOfWeek).hour(h).minute(m).utc();
        const momentDayOfWeek = startTimeMoment.format("dddd").toLowerCase();
        const momentStartTime = startTimeMoment.format("HH:mm");
        const createData = {
          eventTemplateId: parseInt(this.state.newItemEventTemplateId + ""),
          startDay: momentDayOfWeek,
          startTime: momentStartTime,
          numberOfMinutes: minutes,
        }
        const result = await ScheduleTemplatesAPI.createScheduleTemplateItems(this.props.organization.id, this.state.selectedTemplate.id, [createData]);
        const createdItems = result.body.data.items;

        const selectedTemplate = this.state.selectedTemplate;
        const selectedTemplateItems = selectedTemplate.items;
        selectedTemplateItems.push(...createdItems);
        const { min, max, items } = this.processItems(selectedTemplateItems);
        
        selectedTemplate.items = items;
        const minHourForCalendar = new Date(2020, 1, 1, min);
        const maxHourForCalendar = new Date(2020, 1, 1, max);
        
        this.setState({
          loading: false,
          showCreateItemModal: false,
          selectedTemplate,
          minHourForCalendar,
          maxHourForCalendar,
          instanceUpdateKey: this.state.instanceUpdateKey + 1
        });
        return success("Item(s) created!");
      } catch (err) {
        error("Could not create that template item.");
        return this.setState({ loading: false, showCreateItemModal: false });
      }
    });
  }

  private applyTemplate(){
    this.setState({ loading: true }, async () => {
      try{
        const data: any = {
          start: this.state.releaseOnStart.utc().format("YYYY-MM-DD")+ "T00:00:00Z",
          end: this.state.releaseOnEnd.utc().format("YYYY-MM-DD")+ "T23:59:59Z",
          releaseOnType: this.state.releaseOnType === "immediately" ? "immediately" : "pending",
        };
        if(this.state.releaseOnType !== "immediately"){
          const split = this.state.releaseOnTime.split(":");
          const h = parseInt(split[0]);
          const m = parseInt(split[1]);
          const releaseOnDate = moment(this.state.releaseOnDate).hour(h).minute(m).second(0).utc();
          data.releaseOnDate = releaseOnDate.format("YYYY-MM-DDTHH:mm:00")+"Z";
        }
        await ScheduleTemplatesAPI.releaseScheduleTemplate(this.props.organization.id, this.state.selectedTemplate.id, data);
        success("Template applied to those dates!");
        this.setState({ loading: false, showReleaseOnModal: false });
      }catch(err){
        error("Could not apply that template to that date range.")
        this.setState({ loading: false});
      }
    })
  }

  private selectTemplateItem(item: IScheduleTemplateItem){
    const s = splitTime(item.startTime);
    const startTime = moment().utc().hour(s[0]).minute(s[1]).local();
    item.startTime = startTime.format("HH:mm");
    this.setState({ selectedItem: item, showEditItemModal: true });
  }

  private updateItem(){
    this.setState({ loading: false }, async () => {
      try{
        const minutes = parseInt(this.state.selectedItem.numberOfMinutes + "");
        if(minutes === 0){
          error("Minutes cannot be blank or 0");
          return this.setState({ loading: false });
        }
        const s = splitTime(this.state.selectedItem.startTime);
        const startTime = moment().hour(s[0]).minute(s[1]).utc();
        const data = {
          ...this.state.selectedItem,
          numberOfMinutes: minutes,
          startTime: startTime.format("HH:mm"),
        };
        await ScheduleTemplatesAPI.updateScheduleTemplateItem(this.props.organization.id, this.state.selectedTemplate.id, this.state.selectedItem.id, data);

        const items: IScheduleTemplateItem[] = [];
        for(let i of this.state.selectedTemplate.items){
          if(i.id === this.state.selectedItem.id){
            const split = splitTime(data.startTime);
            const startTime = moment().utc().day(data.startDay).hour(split[0]).minute(split[1]).local();
            const endTime = moment(startTime).clone().add(data.numberOfMinutes, "minutes");
            data.startTimeDate = startTime.toDate();
            data.endTimeDate = endTime.toDate();
            items.push(data);
          } else {
            items.push(i);
          }
        }
        const selectedTemplate = this.state.selectedTemplate;
        selectedTemplate.items = items;
        success("Item updated!");
        this.setState({
          loading: false, 
          showEditItemModal: false, 
          selectedItem: ScheduleTemplateItemBlank, 
          selectedTemplate, 
          instanceUpdateKey: this.state.instanceUpdateKey + 1,
        });
      }catch(err){
        error("Could not update. Try again or contact support.");
        this.setState({ loading: false });
      }
    });
  }

  private deleteItem(){
    this.setState({ loading: true }, async () => {
      try{
        await ScheduleTemplatesAPI.deleteScheduleTemplateItem(this.props.organization.id, this.state.selectedTemplate.id, this.state.selectedItem.id);
        // filter
        const items: IScheduleTemplateItem[] = [];
        for(const i of this.state.selectedTemplate.items){
          if(i.id !== this.state.selectedItem.id){
            items.push(i);
          }          
        }
        const selectedTemplate = this.state.selectedTemplate;
        selectedTemplate.items = items;
        this.setState({
          loading: false,
          selectedTemplate,
          selectedItem: ScheduleTemplateItemBlank,
          showDeleteItemModal: false,
          showEditItemModal: false,
        })
      }catch(err){
        error("Could not delete. Try again or contact support.");
        this.setState({ loading: false });
      }
    })
  }

}

const splitTime = (time: string): number[] => {
  const split = time.split(":")
  return [parseInt(split[0]), parseInt(split[1])];
}
