import * as React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../reducers/appReducer";

import { OrganizationsAPI } from "src/api";
import {IOrganization, OrganizationBlank} from "src/api/organizations";
import Card from "src/components/structure/Card";
import { error } from "src/components/structure/Alert";

import OrganizationInfoScreen from "src/components/screens/Organizations/Admin/OrganizationInfoScreen";
import OrganizationMembersScreen from "src/components/screens/Organizations/Admin/OrganizationMembersScreen";
import EventsScreen from "src/components/screens/Organizations/Admin/EventsScreen";
import ScheduleScreen from "src/components/screens/Organizations/Admin/AdminEventScheduleList";
import CancellationsScreen from "src/components/screens/Organizations/Admin/AdminCancellationsList";
import SubscriptionScreen from "src/components/screens/Organizations/Admin/PaymentMethod/AdminBilling";
import { ScheduleTemplates } from "./Admin/ScheduleTemplates";

interface OrganizationAdminScreenProps {
  appActions: any;
  history: any;
  match: any;
  location: any;
}

interface OrganizationAdminScreenState {
  loading: boolean;
  organization: IOrganization;
}

class Starter extends React.Component<OrganizationAdminScreenProps, OrganizationAdminScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      organization: OrganizationBlank,
    };

    this.organizationUpdated = this.organizationUpdated.bind(this);
  }

  componentDidMount(){
    this.fetchOrganization();
  }

  public render() {
    if(this.state.organization.userStatus !== "accepted" || this.state.organization.userRole === "member"){
      return (
        <Card title="" loading={this.state.loading} help="">
          You do not have permission.
        </Card>
      )
    }
    return (
      <div>
        <div className="row">
          <div id="organizationNavbar" className="col-lg-10 offset-lg-1 col-md-12">
            <nav className="navbar navbar-expand-lg navbar-dark" style={{borderRadius: "5px"}}>

              <div className="navbar-header">
                <Link 
                  to={`/organizations/${this.props.match.params.id}/admin/info`}
                  className="navbar-brand"
                  style={{fontWeight: "bold", marginRight: 50}}>
                  {`${this.state.organization.name}`}
                </Link>
              </div>
              <div className="collapse navbar-collapse">
                {this.menu}
              </div>
            </nav>
          </div>
        </div>
        <div className="row" style={{marginTop: 30}}>
          <div className="col-md-12">
            {this.component}
          </div>
        </div>
      </div>
    );
  }



  get component(){
    switch(this.props.match.params.view){
      case "members":
        return (<OrganizationMembersScreen organization={this.state.organization} location={this.props.location} />);
      case "events":
        return (<EventsScreen history={this.props.history} organization={this.state.organization} organizationUpdated={this.organizationUpdated} />);
      case "subscription":
        return (<SubscriptionScreen organization={this.state.organization} />);
      case "schedule":
        return (<ScheduleScreen organization={this.state.organization} />);
      case "cancellations":
        return (<CancellationsScreen organization={this.state.organization} />);
      case "templates":
        return (<ScheduleTemplates organization={this.state.organization} />);
      case "info":
      default:
        return (<OrganizationInfoScreen organization={this.state.organization} organizationUpdated={this.organizationUpdated} />);
    }
  }

  get menu(){
    const active = this.props.match.params && this.props.match.params.view ? this.props.match.params.view : "info";
    if(this.state.organization.userRole === "billing"){
      return (
        <ul className="nav navbar-nav">
          <li><Link to={`/organizations/${this.props.match.params.id}/admin/billing`} 
            className={active === "info" ? "subNavLinkActive" : "subNavLink"}>Billing</Link></li>
        </ul>
      );
    }
    return (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <Link to={`/organizations/${this.props.match.params.id}/admin/info`} 
            className={active === "info" ? "nav-link subNavLinkActive" : "nav-link subNavLink"}>Info</Link>
        </li>
        <li className="nav-item">
          <Link to={`/organizations/${this.props.match.params.id}/admin/members`} 
          className={active === "members" ? "nav-link subNavLinkActive" : "nav-link subNavLink"}>Members</Link>
        </li>
        <li className="nav-item">
          <Link to={`/organizations/${this.props.match.params.id}/admin/events`} 
          className={active === "events" ? "nav-link subNavLinkActive" : "nav-link subNavLink"}>Events</Link>
        </li>
        <li className="nav-item">
          <Link to={`/organizations/${this.props.match.params.id}/admin/schedule`} 
          className={active === "schedule" ? "nav-link subNavLinkActive" : "nav-link subNavLink"}>Upcoming</Link>
        </li>
        <li className="nav-item">
          <Link to={`/organizations/${this.props.match.params.id}/admin/templates`} 
          className={active === "templates" ? "nav-link subNavLinkActive" : "nav-link subNavLink"}>Schedule Templates</Link>
        </li>
        <li className="nav-item">
          <Link to={`/organizations/${this.props.match.params.id}/admin/cancellations`} 
          className={active === "schedule" ? "nav-link subNavLinkActive" : "nav-link subNavLink"}>Cancellations</Link>
        </li>
        <li className="nav-item">
          <Link to={`/organizations/${this.props.match.params.id}/admin/subscription`} 
          className={active === "billing" ? "nav-link subNavLinkActive" : "nav-link subNavLink"}>Subscription</Link>
        </li>
      </ul>
    );
  }

  private fetchOrganization(){
    this.setState({ loading: true }, async () => {
      try{
        // get the organization
        const result = await OrganizationsAPI.getOrganization(this.props.match.params.id);
        this.setState({ organization: result.body.data});
      }catch(err){
        // redirect to their dashboard
        error("Could not retrieve that organization. Do you have permission?");
        this.props.history.push("/dashboard");
      }
    });
  }

  private organizationUpdated(organization: IOrganization){
    // we only update certain fields
    const newOrganization: IOrganization = {
      ...this.state.organization,
      name: organization.name,
      description: organization.description,
      joinAction: organization.joinAction,
      joinCode: organization.joinCode,
      joinMessage: organization.joinMessage,
      maximumReservationsPerUserPerWeek: organization.maximumReservationsPerUserPerWeek,
    }
    this.setState({organization: newOrganization});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Starter) as any);