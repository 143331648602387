import * as React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { IEventReservation } from "src/api/events";
import { IOrganization } from "src/api/organizations";
import { error, success } from "src/components/structure/Alert";
import { EventsAPI } from "src/api";

interface IAdminReservationListItemProps {
  reservation: IEventReservation;
  organization: IOrganization;
  onDeleteReservation: (reservation: IEventReservation) => void;
  onUpdateStatus?: (reservation: IEventReservation) => void;
}

interface IAdminReservationListItemState {
  loading: boolean;
  currentStatus: "pending" | "confirmed" | "denied";
  newStatus: "pending" | "confirmed" | "denied";
  showDeleteDialog: boolean;
  showStatusModal: boolean;
}

export default class AdminReservationListItem extends React.Component<IAdminReservationListItemProps, IAdminReservationListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      newStatus: "pending",
      currentStatus: "pending",
      showDeleteDialog: false,
      showStatusModal: false,
    };

    this.toggleShowDeleteDialog = this.toggleShowDeleteDialog.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.updateStatusModal = this.updateStatusModal.bind(this);
    this.resetStatusModal = this.resetStatusModal.bind(this);
    this.deleteReservation = this.deleteReservation.bind(this);
  }

  componentDidMount(){
    this.setState({currentStatus: this.props.reservation.status, newStatus: this.props.reservation.status});
  }

  public render() {
    if(this.state.loading){
      return (
        <div style={{textAlign: "center"}}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    return (
      <div className="row" style={{marginBottom: 15}}>
        <div className="col-lg-4 col-sm-6">
          <Link to={`/organizations/${this.props.reservation.organizationId}/users/${this.props.reservation.userId}/events`}>{this.props.reservation.participantFirstName} {this.props.reservation.participantLastName}</Link>
        </div>
        <div className="col-lg-3 col-sm-6">
          {this.props.reservation.instanceStartTime.format("MM/DD/YY")} at {this.props.reservation.instanceStartTime.format("hh:mm A")}
        </div>
        <div className="col-lg-3 col-sm-12">
          <select id="currentStatus" className="form-control form-control-small-table" value={this.state.currentStatus} onChange={this.updateStatusModal}>
            <option value="pending">Pending - Allowed</option>
            <option value="confirmed">Confirmed</option>
            <option value="denied">Denied</option>
          </select>
        </div>
        <div className="col-lg-2 col-sm-12">
          <span className="oi oi-x icon icon-danger" title="Delete Reservation" onClick={this.toggleShowDeleteDialog} />
        </div>

        <Modal show={this.state.showDeleteDialog} onHide={this.toggleShowDeleteDialog}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Reservation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong className="text-danger">Warning!</strong> This cannot be undone. The user will be allowed to apply again. Often, it is better to change the status to <strong>denied</strong>. This will prevent the user from attempting to register again. Are you sure you want to delete this reservation for {this.props.reservation.participantFirstName} {this.props.reservation.participantLastName}?
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.deleteReservation}>Cancel a Reservation</button>
            <button className="btn btn-block btn-default" onClick={this.toggleShowDeleteDialog}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showStatusModal} onHide={this.resetStatusModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Update Reservation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong className="text-info">Warning!</strong> 
            {this.state.currentStatus !== this.state.newStatus && this.state.newStatus === "denied" && (
              <p>This will deny the user the ability to register for this instance and other users will be able to take the slot, if otherwise able. This will also send an email to the user informing them that their reservation has been denied. For the user to be able to reserve the slot again, an administrator will have to change the status to either Pending or Confirmed if the reservation would otherwise be allowed.</p>
            )}
            {this.state.currentStatus === "denied" && this.state.newStatus !== "denied" && (
              <p>This will send an email to the student informing them that their previously denied reservation is now valid again.</p>
            )}
            <p>Are you sure you want to modify this reservation for {this.props.reservation.participantFirstName} {this.props.reservation.participantLastName}?</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.updateStatus}>Change the Reservation</button>
            <button className="btn btn-block btn-default" onClick={this.resetStatusModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggleShowDeleteDialog(){
    this.setState({showDeleteDialog: !this.state.showDeleteDialog});
  }

  private updateStatusModal(e: any){
    const newStatus = e.target.value;
    // we need to look at the settings here
    if(newStatus === this.state.currentStatus){
      return;
    }
    this.setState({ newStatus}, () => {
      if(this.state.currentStatus !== newStatus && newStatus === "denied"){
        // show the modal
        return this.setState({ showStatusModal: true });
      }
      if(this.state.currentStatus === "denied" && newStatus !== "denied"){
        // show the modal
        return this.setState({ showStatusModal: true });
      }
      this.updateStatus();
    });
  }

  private resetStatusModal(){
    this.setState({newStatus: this.state.currentStatus, showStatusModal: false});
  }

  private updateStatus(){
    const newStatus = this.state.newStatus;
    this.setState({loading: true, showDeleteDialog: false}, async () => {
      try{
        await EventsAPI.updateInstanceReservation(this.props.organization.id, this.props.reservation.eventTemplateId, this.props.reservation.eventInstanceId, this.props.reservation.id, {status: newStatus});
        success("Reservation updated!");
        this.setState({ loading: false, newStatus, currentStatus: newStatus, showStatusModal: false});
        if(this.props.onUpdateStatus){
          const reservation = this.props.reservation;
          reservation.status = newStatus;
          this.props.onUpdateStatus(reservation);
        }
      }catch(err){
        error("We could not update that reservation. Please contact support.");
        this.setState({loading: false, newStatus: this.state.currentStatus});
      }
    });
  }

  private deleteReservation(){
    // make the api call then call the prop
    this.setState({loading: true}, async () => {
      try{
        await EventsAPI.cancelInstanceReservation(this.props.organization.id, this.props.reservation.eventTemplateId, this.props.reservation.eventInstanceId, this.props.reservation.id);
        success("Reservation deleted!");
        this.setState({ loading: false, showDeleteDialog: false }, () => {
          this.props.onDeleteReservation(this.props.reservation);
        })
      }catch(err){
        error("We could not delete that reservation. Please contact support.");
        this.setState({loading: false, showDeleteDialog: false});
      }
    });
  }


}