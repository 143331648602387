import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import Card from "src/components/structure/Card";
import DatePicker from "src/components/structure/DatePicker";
import * as AppActions from "src/reducers/appReducer";
import { IEventReservation, IEventTemplate, EventTemplateBlank } from "src/api/events";
import { ISubaccount, IUser, UserBlank } from "src/api/user";
import { OrganizationsAPI, EventsAPI } from "src/api";
import { OrganizationBlank } from "src/api/organizations";

import AdminReservationListItem from "src/components/screens/Events/AdminReservationListItem";
import { IOrganization } from "src/api/organizations";

interface IOrganizationUserEventsProps {
  appActions: any;
  history: any;
  match: any;
}

interface IOrganizationUserEventsState {
  loading: boolean;
  organization: IOrganization;
  templates: IEventTemplate[];
  selectedTemplate: IEventTemplate;
  allReservations: IEventReservation[];
  filteredReservations: IEventReservation[];
  start: moment.Moment;
  end: moment.Moment;
  selectedParticipantType: "user" | "subaccount";
  selectedParticipantId: number;
  user: IUser;
  subaccounts: ISubaccount[];
}

class OrganizationUserEvents extends React.Component<IOrganizationUserEventsProps, IOrganizationUserEventsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      organization: OrganizationBlank,
      templates: [],
      selectedTemplate: EventTemplateBlank,
      allReservations: [],
      filteredReservations: [],
      start: moment(),
      end: moment().add(14, "day"),
      selectedParticipantType: "user",
      selectedParticipantId: 0,
      user: UserBlank,
      subaccounts: [],
    };

    this.fetch = this.fetch.bind(this);
    this.fetchReservations = this.fetchReservations.bind(this);
    this.filterReservations = this.filterReservations.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.selectParticipant = this.selectParticipant.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
    this.handleReservationStatusChanged = this.handleReservationStatusChanged.bind(this);
    this.handleReservationDeleted = this.handleReservationDeleted.bind(this);
  }

  componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-4">
          <Card title="Filters" loading={this.state.loading} help="">
            <div className="form-group">
              <label>Participant</label>
              <select id="participant" className="form-control" value={`${this.state.selectedParticipantType}_${this.state.selectedParticipantId}`} onChange={this.selectParticipant}>
                <option value={0}>All on Account</option>
                <option value={`user_${this.state.user.id}`}>{this.state.user.firstName} {this.state.user.lastName}</option>
                {this.state.subaccounts.map((ac) => {
                  return (<option key={`subaccount_${ac.id}`} value={`subaccount_${ac.id}`} >{ac.firstName} {ac.lastName}</option>)
                })}
              </select>
            </div>
            <div className="form-group">
              <label>For Event</label>
              <select id="template" className="form-control" value={this.state.selectedTemplate.id} onChange={this.selectTemplate}>
                <option value={0}>All</option>
                {this.state.templates.map((temp) => {
                  return (<option key={temp.id} value={temp.id}>{temp.name}</option>)
                })}
              </select>
            </div>
          </Card>
        </div>
        <div className="col-8">
          <Card title="Reservations" loading={this.state.loading} help="">
            <div className="row" style={{marginBottom: 10}}>
              <div className="col-6">
                <label>Showing reservations between</label>
                <DatePicker date={this.state.start} onDateSaved={this.updateStart} />
              </div>
              <div className="col-6">
                <label>To</label>
                <DatePicker date={this.state.end} onDateSaved={this.updateEnd} />
              </div>
            </div>
            {this.state.filteredReservations.length === 0 && (<div className="row"><div className="col-12"><strong>No reservations match that criteria.</strong></div></div>)}
            {this.state.filteredReservations.map((reservation: IEventReservation) => {
              
              return (
                <AdminReservationListItem
                  key={reservation.id}
                  organization={this.state.organization}
                  reservation={reservation}
                  onDeleteReservation={this.handleReservationDeleted}
                  onUpdateStatus={this.handleReservationStatusChanged}
                />
              );
            })}
          </Card>
        </div>
      </div>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try{
        const userResult = await OrganizationsAPI.getUserProfileForOrganization(this.props.match.params.id, this.props.match.params.userId);
        const user = userResult.body.data.user;
        const subaccounts = userResult.body.data.subaccounts;

        const orgResult = await OrganizationsAPI.getOrganization(this.props.match.params.id);
        const organization = orgResult.body.data;

        const tempResult = await EventsAPI.getEventTemplates(this.props.match.params.id);
        const templates = tempResult.body.data;

        this.setState({
          loading: false,
          organization,
          user,
          subaccounts,
          templates,
        }, () => this.fetchReservations());
      }catch(err){
        this.setState({loading: false});
      }
    })
  }

  private fetchReservations(){
    this.setState({loading: true}, async () => {
      try{
        const start = this.state.start.format("YYYY-MM-DDT00:00:00Z");
        const end = this.state.end.format("YYYY-MM-DDT23:59:59Z");
        const reservationsResult = await EventsAPI.getUserOrganizationReserverations(this.props.match.params.id, this.props.match.params.userId, start, end);
        const reservations: IEventReservation[] = [];
        for(const r of reservationsResult.body.data){
          r.instanceStartTime = moment(r.instanceStartTime);
          reservations.push(r);
        }
        this.setState({loading: false, allReservations: reservations}, () => this.filterReservations());
      }catch(err){
        this.setState({ loading: false});
      }
    })
  }

  private filterReservations(){
    this.setState({ loading: true }, async () => {
      // time filtering is already done, so now just filter based upon template and participant
      let templateFilteredReservations: IEventReservation[] = [];
      let filteredReservations: IEventReservation[] = [];
      // template first
      if(this.state.selectedTemplate.id === 0){
        templateFilteredReservations = this.state.allReservations;
      } else {
        for(const r of this.state.allReservations){
          if(r.eventTemplateId === this.state.selectedTemplate.id){
            templateFilteredReservations.push(r);
          }
        }
      }
      // now participants
      if(this.state.selectedParticipantId === 0){
        filteredReservations = templateFilteredReservations;
      } else {
        // the below is due to selects putting the values as string
        const sId = parseInt(this.state.selectedParticipantId +"", 10);
        for(const r of templateFilteredReservations){
          if(r.participantId === sId && r.participantType === this.state.selectedParticipantType){
            filteredReservations.push(r);
          }
        }
      }
      this.setState({filteredReservations, loading: false});
    });
  }

  private updateStart(newDate: moment.Moment){
    this.setState({start: newDate}, () => this.fetchReservations());
  }

  private updateEnd(newDate: moment.Moment){
    this.setState({end: newDate}, () => this.fetchReservations());
  }

  private handleReservationStatusChanged(reservation: IEventReservation){
    const allReservations: IEventReservation[] = [];
    const filteredReservations: IEventReservation[] = [];
    for(const r of this.state.allReservations){
      if(r.id === reservation.id){
        allReservations.push(reservation);
      } else {
        allReservations.push(r);
      }
    }
    for(const r of this.state.filteredReservations){
      if(r.id === reservation.id){
        filteredReservations.push(reservation);
      } else {
        filteredReservations.push(r);
      }
    }
    this.setState({ allReservations, filteredReservations });
  }

  private handleReservationDeleted(reservation: IEventReservation){
    const allReservations: IEventReservation[] = [];
    const filteredReservations: IEventReservation[] = [];
    for(const r of this.state.allReservations){
      if(r.id !== reservation.id){
        allReservations.push(r);
      }
    }
    for(const r of this.state.filteredReservations){
      if(r.id !== reservation.id){
        filteredReservations.push(r);
      }
    }
    this.setState({ allReservations, filteredReservations });
  }

  private selectParticipant(e: any){
    const parts = e.target.value.split("_");
    const participantType = parts[0];
    const participantId = parts[1];
    this.setState({selectedParticipantType: participantType, selectedParticipantId: participantId}, () => this.filterReservations());

  }

  private selectTemplate(e: any){
    let selectedTemplate = EventTemplateBlank;
    const id = parseInt(e.target.value, 10);
    for(const c of this.state.templates){
      if(c.id === id){
        selectedTemplate = c;
        break;
      }
    }
    this.setState({selectedTemplate}, () => this.filterReservations());
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationUserEvents) as any);