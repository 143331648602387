import * as React from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN || "pk_test_51GsBOcFFgWgDW4jv2BhbxnfiwDSXA8xxclLUflov6b7WKb7mA1BkNDFAFZCXbb19Iv8YxUd3QiKsv0DffG19IEuC00BSm1R8Bg");

export const StripeElementsContainer = (props: any) => {
  return (
    <Elements stripe={stripePromise}>
      {props.children}
    </Elements>
  );
};