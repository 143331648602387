import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import { ChangePaymentMethod } from "./ChangePaymentMethod";
import Card from "src/components/structure/Card";
import * as AppActions from "src/reducers/appReducer";
import { IOrganization } from "src/api/organizations";
import { BillingAPI } from "src/api";
import { BlankPaymentMethod, BlankPlan, IPaymentMethod, IPlan } from "src/api/billing";
import { error, success } from "src/components/structure/Alert";
import { formatCurrency } from "src/utils/currency";
import PlansDisplay from "src/components/structure/PlansDisplay";

interface IAdminBillingProps {
  appActions: any;
  organization: IOrganization;
}

interface IAdminBillingState {
  loading: boolean;
  organization: IOrganization;
  plans: IPlan[];
  currentPlan: IPlan;
  paymentMethod: IPaymentMethod;
  showChangePlanModal: boolean;
  showConfirmSubscriptionModal: boolean;
  showConfirmCancelModal: boolean;
  selectedPlanId: number;
  selectedPlan: IPlan;
}

class AdminBilling extends React.Component<IAdminBillingProps, IAdminBillingState> {
  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      organization: props.organization,
      currentPlan: BlankPlan,
      plans: [],
      paymentMethod: BlankPaymentMethod,
      showChangePlanModal: false,
      showConfirmSubscriptionModal: false,
      showConfirmCancelModal: false,
      selectedPlanId: 0,
      selectedPlan: BlankPlan,
    };

    this.fetchOrganizationAndPlans = this.fetchOrganizationAndPlans.bind(this);
    this.handleSavePaymentMethod = this.handleSavePaymentMethod.bind(this);
    this.toggleChangePlanModal = this.toggleChangePlanModal.bind(this);
    this.toggleShowConfirmSubscriptionModal = this.toggleShowConfirmSubscriptionModal.bind(this);
    this.toggleConfirmCancelModal = this.toggleConfirmCancelModal.bind(this);
    this.updateField = this.updateField.bind(this);
    this.selectPlan = this.selectPlan.bind(this);
    this.changePlan = this.changePlan.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
  }

  componentDidMount(){
    // first, set the current plan id to the correct plan for the org
    const currentPlan = this.props.organization.plan ? this.props.organization.plan : this.state.currentPlan;
    currentPlan.id = this.props.organization.planId;
    
    this.setState({ currentPlan, selectedPlanId: currentPlan.id }, () => {
      this.fetchOrganizationAndPlans();
    });
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-4 offset-1">
          <Card title="Payment Method">
            <div className="row">
              <div className="col-6">Current Payment Method:</div>
              {this.state.paymentMethod.lastFour === "" ? (
                <div className="col-6">
                  <strong>None</strong>
                </div>
              ) : (
                <div className="col-6">
                  <strong><span className="capitalize">{this.state.paymentMethod.brand}</span> ending in {this.state.paymentMethod.lastFour}</strong>
                </div>
              )}
            </div>
            <hr />
            <ChangePaymentMethod onSavePaymentMethod={this.handleSavePaymentMethod} />
          </Card>
        </div>
        <div className="col-md-6">
          <Card title="Subscription" loading={this.state.loading} help="">
            <div className="row">
              <div className="col-4">
                Current Subscription Plan:
              </div>
              <div className="col-8">
                <strong>{this.state.currentPlan.name}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                This plan allows:
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <ul>
                  <li>{this.state.currentPlan.eventInstancesAllowed} event instances</li>
                  <li>{this.state.currentPlan.usersAllowed} members</li>
                  <li>{this.state.currentPlan.dataArchivalDays} days of data retention</li>
                </ul>
              </div>
              <div className="col-6">
                {this.state.currentPlan.term === "free" ?
                (<div>For Free!</div>) :
                (<div>Paying {formatCurrency(this.state.currentPlan.amount)} {this.state.currentPlan.term}</div>)}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {this.state.currentPlan.status === "hidden" ? 
                  (<div><p>You are on a special plan. You must contact support to change your subscription.</p></div>) : 
                  (<button className="btn btn-block btn-primary" onClick={this.toggleChangePlanModal}>Change Plan</button>)}
                
              </div>
            </div>
          </Card>
        </div>
        <Modal show={this.state.showChangePlanModal} onHide={this.toggleChangePlanModal} dialogClassName="modal-50">
          <Modal.Header closeButton={true}>
            <Modal.Title>Change Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <PlansDisplay currentPlanId={this.state.currentPlan.id} toggleConfirmCancelModal={this.toggleConfirmCancelModal} selectPlan={this.selectPlan} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-default" onClick={this.toggleChangePlanModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showConfirmSubscriptionModal} onHide={this.toggleChangePlanModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Confirm Subscription Change</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Current Plan</th>
                    <th>New Plan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name:</td>
                    <td>{this.state.currentPlan.name}</td>
                    <td>{this.state.selectedPlan.name}</td>
                  </tr>
                  <tr>
                    <td>Instances:</td>
                    <td>{this.state.currentPlan.eventInstancesAllowed}</td>
                    <td>{this.state.selectedPlan.eventInstancesAllowed}</td>
                  </tr>
                  <tr>
                    <td>Members:</td>
                    <td>{this.state.currentPlan.usersAllowed}</td>
                    <td>{this.state.selectedPlan.usersAllowed}</td>
                  </tr>
                  <tr>
                    <td>Archival Days:</td>
                    <td>{this.state.currentPlan.dataArchivalDays}</td>
                    <td>{this.state.selectedPlan.dataArchivalDays}</td>
                  </tr>
                  <tr>
                    <td>Paying:</td>
                    <td>{this.state.currentPlan.payingDisplay}</td>
                    <td>{this.state.selectedPlan.payingDisplay}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="form-group">
                <strong>By changing your plan, you agree to the Terms of Service. Subscriptions are non-refundable. You will be charged the new amount immediately and at the specified interval.</strong>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.changePlan}>Change Plan</button>
            <button className="btn btn-block btn-default" onClick={this.toggleShowConfirmSubscriptionModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showConfirmCancelModal} onHide={this.toggleConfirmCancelModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Cancel Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <strong><span className="text-danger">Warning:</span> Cancelling your plan will end your subscription at the end of the currently paid period. On that date, you will revert to the Starter plan and be subject to those limitations. If you would like to contact support prior to cancelling your plan, please close this warning and select Contact.</strong>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.cancelSubscription}>Yes, Cancel My Subscription</button>
            <button className="btn btn-block btn-default" onClick={this.toggleConfirmCancelModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }

  private async handleSavePaymentMethod(event: any){
    this.setState({ loading: true }, async () => {
      try{
        await BillingAPI.savePaymentMethod(this.props.organization.id, event.paymentMethod.id);
        const currentMethodResult = await BillingAPI.getPaymentMethod(this.props.organization.id);
        success("Payment method updated!");
        this.setState({loading: false, paymentMethod: currentMethodResult.body.data});
      }catch(err){
        error("We could not update your payment method. Please contact support or try again later");
        this.setState({loading: false});
      }
    })
  }


  private fetchOrganizationAndPlans(){
    this.setState({loading: true}, async () => {
      let plans: IPlan[] = [];
      let currentPlan = this.state.currentPlan;
      let selectedPlan = this.state.selectedPlan;
      let paymentMethod: IPaymentMethod = this.state.paymentMethod;
      try{
        const plansResult = await BillingAPI.getPlans();
        plans = plansResult.body.data;
        for(const p of plans){
          if(p.term === "free"){
            p.payingDisplay = "Free"
          } else {
            const amount = formatCurrency(p.amount);
            const term = p.term === "monthly" ? "per month" : "per year";
            p.payingDisplay = `${amount} ${term}`;
          }
          if(p.id === currentPlan.id){
            currentPlan = p;
            selectedPlan = p;
          }
        }
        const currentMethodResult = await BillingAPI.getPaymentMethod(this.props.organization.id);
        paymentMethod = currentMethodResult.body.data;
      }catch(err){ 
        // ignore
      }finally{
        this.setState({ plans, paymentMethod, currentPlan, loading: false, selectedPlan});
      }
    });
  }

  private toggleChangePlanModal(){
    this.setState({ showChangePlanModal: !this.state.showChangePlanModal});
  }

  private toggleShowConfirmSubscriptionModal(){
    this.setState({ showConfirmSubscriptionModal: !this.state.showConfirmSubscriptionModal});
  }

  private toggleConfirmCancelModal(){
    this.setState({ showConfirmCancelModal: !this.state.showConfirmCancelModal});
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private selectPlan(planId: number){
    let selectedPlan = this.state.selectedPlan;
    for(const p of this.state.plans){
      if(p.id === planId){
        selectedPlan = p;
      }
    }
    this.setState({selectedPlanId: planId, showConfirmSubscriptionModal: true, showChangePlanModal: false, selectedPlan});
  }

  private changePlan(){
    this.setState({ loading: true, showChangePlanModal: false, showConfirmSubscriptionModal: false  }, async () => {
      try{
        const result = await BillingAPI.changeSubscription(this.props.organization.id, this.state.selectedPlan.id);
        success("We have changed your subscription!");
        const organization = result.body.data;
        this.setState({ organization, currentPlan: this.state.selectedPlan }, () => {this.fetchOrganizationAndPlans()});
      }catch(err){
        error("We could not change that subscription. Please try again later or contact support.")
        this.setState({ loading: false });
      }
    })
  }

  private cancelSubscription(){
    this.setState({ loading: true, showChangePlanModal: false, showConfirmSubscriptionModal: false, showConfirmCancelModal: false  }, async () => {
      try{
        const result = await BillingAPI.cancelSubscription(this.props.organization.id);
        success("We have cancelled your subscription!");
        const organization = result.body.data;
        this.setState({ organization, currentPlan: this.state.selectedPlan }, () => {this.fetchOrganizationAndPlans()});
      }catch(err){
        error("We could not cancel that subscription. Please try again later or contact support.")
        this.setState({ loading: false });
      }
    })
  }


}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminBilling);