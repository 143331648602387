import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import Card from "src/components/structure/Card";
import * as AppActions from "src/reducers/appReducer";

import { IEventTemplate, EventTemplateBlank } from "src/api/events";
import EventsScreenTemplateItem from "./EventsScreenTemplateListItem";
import MarkdownEditor from "src/components/structure/MarkdownEditor";
import { IOrganization } from "src/api/organizations";
import { EventsAPI } from "src/api";
import { error, success } from "src/components/structure/Alert";

interface IEventsScreenTemplateListProps {
  templates: IEventTemplate[];
  organization: IOrganization;

  // handle the events we need to bubble up
  onTemplateCreated: any;
  onTemplateDeleted: any;
  onTemplateUpdated: any;
  onTemplateViewToggle: any;
}

interface IEventsScreenTemplateListState {
  loading: boolean;

  selectedTemplate: IEventTemplate;

  showEditModal: boolean;
  showDeleteModal: boolean;
}

class EventsScreenTemplateList extends React.Component<IEventsScreenTemplateListProps, IEventsScreenTemplateListState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      selectedTemplate: { ...EventTemplateBlank },

      showEditModal: false,
      showDeleteModal: false,
    };

    this.updateDescription = this.updateDescription.bind(this);
    this.updateTemplateField = this.updateTemplateField.bind(this);

    this.startCreate = this.startCreate.bind(this);
    this.startEdit = this.startEdit.bind(this);
    this.startDelete = this.startDelete.bind(this);

    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);

    this.saveTemplate = this.saveTemplate.bind(this);
  }

  public render() {
    return (
      <div className="row">
        <div className="col-12">
          <Card title="Templates" loading={this.state.loading} help="">
            {this.props.templates.length === 0 && (<strong>No templates exist for this group</strong>)}
            {this.props.templates.map((temp) => {
              return (
                <EventsScreenTemplateItem
                  item={temp}
                  key={temp.id}
                  editTemplate={this.startEdit}
                  deleteTemplate={this.startDelete}
                  selectTemplate={null} />
              );
            })}

            <div className="row" style={{ marginTop: 30 }}>
              <div className="col-md-12">
                <button className="btn btn-block btn-primary" onClick={this.startCreate}>Create Template</button>
              </div>
            </div>
          </Card>

          <Modal show={this.state.showEditModal} onHide={this.closeEditModal} dialogClassName="modal-94">
            <Modal.Header closeButton={true}>
              <Modal.Title>Save Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Event Name</label>
                    <input type="text" id="name" className="form-control" value={this.state.selectedTemplate.name} onChange={this.updateTemplateField} />
                  </div>
                  <div className="form-group">
                    <label>Location Address (Leave Blank for Virtual)</label>
                    <input type="text" id="locationStreet" className="form-control" value={this.state.selectedTemplate.locationStreet} onChange={this.updateTemplateField} placeholder="Street Address" />

                    <input type="text" id="locationCity" className="form-control" value={this.state.selectedTemplate.locationCity} onChange={this.updateTemplateField} placeholder="City" />
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <input type="text" id="locationState" className="form-control" value={this.state.selectedTemplate.locationState} onChange={this.updateTemplateField} placeholder="State" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <input type="text" id="locationZip" className="form-control" value={this.state.selectedTemplate.locationZip} onChange={this.updateTemplateField} placeholder="Zip" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Send Event Summaries To These Email Addresses</label>
                    <input type="text" id="sendSummariesTo" className="form-control" value={this.state.selectedTemplate.sendSummariesTo} onChange={this.updateTemplateField} />
                  </div>
                  <div className="form-group">
                    <label>Description (Shown During Reservations)</label>
                    <MarkdownEditor
                      content={this.state.selectedTemplate.description}
                      showToggle={true}
                      showHelp={true}
                      onChange={this.updateDescription}
                      mode="edit"
                      rows={5}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Status</label>
                    <select id="status" className="form-control" value={this.state.selectedTemplate.status} onChange={this.updateTemplateField}>
                      <option value="pending">Pending - Do Not Show</option>
                      <option value="active">Active - Available</option>
                      <option value="archived">Archived - Not Available</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Maximum Number of Participants Per Instance</label>
                    <input type="number" id="maximumReservationsPerInstance" className="form-control" value={this.state.selectedTemplate.maximumReservationsPerInstance} onChange={this.updateTemplateField} />
                  </div>
                  <div className="form-group">
                    <label>Allow subaccounts to exceed maximum?</label>
                    <select id="maximumReservationsPerInstanceRule" className="form-control" value={this.state.selectedTemplate.maximumReservationsPerInstanceRule} onChange={this.updateTemplateField}>
                      <option value="absolute">No, strictly enforce the maximum</option>
                      <option value="allow_subaccounts_over">Yes, multiple subaccounts on a single user may exceed maximum</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Maximum number of instances a participant may register for each day?</label>
                    <input type="number" id="maximumReservationsPerUserPerDay" className="form-control" value={this.state.selectedTemplate.maximumReservationsPerUserPerDay} onChange={this.updateTemplateField} />
                  </div>
                  <div className="form-group">
                    <label>Maximum number of instances a participant may register for each week?</label>
                    <input type="number" id="maximumReservationsPerUserPerWeek" className="form-control" value={this.state.selectedTemplate.maximumReservationsPerUserPerWeek} onChange={this.updateTemplateField} />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-block btn-primary" onClick={this.saveTemplate}>Save</button>
              <button className="btn btn-block btn-default" onClick={this.closeEditModal}>Nevermind</button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Delete Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p><strong className="text-danger">Warning: </strong> This will completely delete the template named <strong>{this.state.selectedTemplate.name}</strong>. Doing so will also delete all instances and reservations for this event. Most of the time, you will want to change the template to Archived instead.</p>
              <p>Are you are you want to continue? This cannot be undone!</p>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-block btn-danger" onClick={this.deleteTemplate}>Yes, Permanently Delete It</button>
              <button className="btn btn-block btn-default" onClick={this.closeDeleteModal}>Nevermind</button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }

  private updateTemplateField(e: any) {
    const ns = this.state.selectedTemplate;
    const id = e.target.id;
    let val = e.target.value;
    if (id === "maximumReservationsPerUserPerDay" || id === "maximumReservationsPerUserPerWeek" || id === "maximumReservationsPerInstance") {
      val = parseInt(val, 10);
    }
    ns[id] = val;
    this.setState({ selectedTemplate: ns });
  }


  private updateDescription(description: string) {
    const t = this.state.selectedTemplate;
    t.description = description;
    this.setState({ selectedTemplate: t });
  }

  private startCreate(){
    // if the selectedTemplateId is not 0, we set it to a blank, since it means they were editing one and the fields are still set
    let selectedTemplate = this.state.selectedTemplate;
    if(selectedTemplate.id !== 0){
      selectedTemplate = {...EventTemplateBlank };
    }
    this.setState({ showEditModal: true, selectedTemplate });
  }

  private startEdit(selectedTemplate: IEventTemplate){
    this.setState({ selectedTemplate, showEditModal: true });
  }

  private startDelete(selectedTemplate: IEventTemplate){
    this.setState({ selectedTemplate, showDeleteModal: true });
  }

  private closeDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private closeEditModal() {
    this.setState({ showEditModal: !this.state.showEditModal });
  }

  private saveTemplate() {
    const data = {
      ...this.state.selectedTemplate
    };
    data.name = data.name.trim();
    // the name is the only required field
    if (data.name === "") {
      return error("Name cannot be blank");
    }
    this.setState({ loading: true }, async () => {
      try {
        let result: any = {};
        let action: string = "create";
        if (this.state.selectedTemplate.id === 0) {
          // create
          result = await EventsAPI.createTemplate(this.props.organization.id, data);
        } else {
          // edit
          result = await EventsAPI.updateEventTemplate(this.props.organization.id, this.state.selectedTemplate.id, data);
          action = "edit";
        }
        this.setState({ loading: false, showEditModal: false, selectedTemplate: {...EventTemplateBlank}}, () => {
          if(action === "create"){
            this.props.onTemplateCreated(result.body.data);
          } else {
            this.props.onTemplateUpdated(result.body.data);
          }
        })
      } catch (err) {
        error("Could not save that template");
        this.setState({ loading: false });
      }
    })
  }


  private deleteTemplate() {
    this.setState({ loading: false }, async () => {
      try {
        await EventsAPI.deleteEventTemplate(this.props.organization.id, this.state.selectedTemplate.id);
        success("Deleted that template!");
        this.setState({ loading: false, selectedTemplate: {...EventTemplateBlank}, showDeleteModal: false }, () => {
          this.props.onTemplateDeleted(this.state.selectedTemplate);
        });
      } catch (err) {
        error("We could not delete that template. Please contact support.");
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsScreenTemplateList);