import { makeCall } from "./client";
import moment from "moment";
import { IEventReservation } from "./events";

export interface ISubaccount {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  createdOn: moment.Moment;

  // these are for the reservation screen
  reserved?: boolean;
  reservation?: IEventReservation;
}

export const SubaccountBlank: ISubaccount = {
  id: 0,
  userId: 0,
  firstName: "",
  lastName: "",
  createdOn: moment(),
}

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string,
  newPassword?: string;
  lastLogin: moment.Moment;
  createdOn: moment.Moment;
  organizationRole: string;
  status: string;
  timezone: string;
  
  // these are for the reservation screen
  reserved?: boolean;
  reservation?: IEventReservation;

  // for filtering
  filterKey?: string;
}

export const UserBlank: IUser = {
  id: 0,
  firstName: "",
  lastName: "",
  email: "",
  lastLogin: moment(),
  createdOn: moment(),
  organizationRole: "member",
  status: "accepted",
  timezone: "America/New_York"
};

export class UserAPI {
  /**
   * Attempts to login a user
   * @param username 
   * @param password 
   */
  public loginUser(email: string, password: string): Promise<any> {
    return makeCall("post", "users/login", {email, password});
  }

  /**
   * Signup a new user
   * @param firstName 
   * @param lastName 
   * @param email 
   * @param password 
   * @param otherData 
   */
  public signup(firstName: string, lastName: string, email: string, password: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      firstName,
      lastName,
      email,
      password,
    }
    return makeCall("post", "users/signup", data);
  }

  /**
   * Verifies a signup
   * @param email 
   * @param token 
   */
  public signupVerify(email: string, token: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      email,
      token,
    };
    return makeCall("post", `users/signup/verify`, data);
  }

  /**
   * Resets a password
   * @param email 
   */
  public resetPassword(email: string): Promise<any> {
    const data = {
      email,
    };
    return makeCall("post", `users/login/reset`, data);
  }

  /**
   * Verifies the email and code for a password reset
   * @param email 
   * @param token 
   * @param newPassword 
   */
  public resetPasswordVerify(email: string, token: string, newPassword: string): Promise<any> {
    const data = {
      email,
      token,
      password: newPassword
    };
    return makeCall("post", `users/login/reset/verify`, data);
  }

  /**
   * Get a user profile
   * @param userId 
   */
  public getUserProfile(): Promise<any> {
    return makeCall("get", `me`);
  }

  /**
   * Update the profile information for a user
   * @param data 
   */
  public updateUserProfile(data: any): Promise<any> {
    return makeCall("patch", `me`, data);
  }

  /**
   * Create a new subaccount, which is an entity that can register but not login
   * @param firstName 
   * @param lastName 
   * @param otherData 
   */
  public createSubaccount(firstName: string, lastName: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      firstName, 
      lastName
    };
    return makeCall("post", `me/subaccounts`, data);
  }

  /**
   * Get all subaccount for a user
   * @param data 
   */
  public getSubaccounts(data: any = {}): Promise<any> {
    return makeCall("get", `me/subaccounts`, data);
  }

  /**
   * Update a subaccount
   * @param id 
   * @param data 
   */
  public updateSubaccount(id: number, data: any = {}): Promise<any> {
    return makeCall("patch", `me/subaccounts/${id}`, data);
  }

  /**
   * Delete a subaccount
   * @param id 
   * @param data 
   */
  public deleteSubaccount(id: number, data: any = {}): Promise<any> {
    return makeCall("delete", `me/subaccounts/${id}`, data);
  }

}