import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import logo from "../../img/hero_small.png";
import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";

import PlansDisplay from "src/components/structure/PlansDisplay";

class Home extends React.Component<any, any> {
  public render() {
    return (
      <Screen
        fileName="Home.tsx"
      >
        <div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-sm-12" style={{ textAlign: "center" }}>
              <img src={logo} alt="Logo" style={{ maxWidth: "100%", marginBottom: 20 }} />
              <h3>Simple, Easy Schedule Management</h3>
            </div>
          </div>

          <div className="row justify-content-center" style={{marginBottom:15}}>
            <div className="col-lg-8 col-sm-12">
              <Card title="">
                <h3>Easy to Use Event Management System</h3>
                <p>Do you need to manage event reservations and limit the number of attendees? TLSchedule is the solution you need! We make it easy to set up your organization with a clean, easy to understand calendar. You then set up your events and control how many attendees can reserve a spot for each instance of that event. You control the numbers, the schedule, and the members.</p>
              </Card>
            </div>
            <div className="col-lg-4 col-sm-12">
              <Card title="">
                <h3>Get Started</h3>
                <p>To get started, you can <Link to="/signup" className="home-started-link">Signup</Link> or <Link to="/login" className="home-started-link">Login</Link>. From there, you can <Link to="/organizations/create" className="home-started-link">Create a new group</Link>, <Link to="/organizations/join" className="home-started-link">Join a group</Link>, or see your <Link to="/dashboard" className="home-started-link">Dashboard</Link>. It's never been easier to manage your events!</p>
              </Card>
            </div>
          </div>

          <Card title="" style={{marginBottom:15}}>
            <div className="row justify-content-center feature-section">
              <div className="col-lg-7 text-left">
                <img src="assets/img/screens/event_create.png" alt="Event creation is simple!" style={{ width: "90%", marginBottom: 20 }} />
              </div>
              <div className="col-lg-4 offset-lg-1 text-left">
                <h3 style={{ textAlign: "center" }}>Events</h3>
                <p>
                  Events can be configured with simple or complex rule sets that enable you to successfully manage crucial limits to attendance. The events can handle a wide-range of settings, including the ability to treat an entire family as a single reservation.
                      </p>
                <p>
                  Save time by allowing TLSchedule to worry about tracking who has signed up for events and denying reservations beyond per-instance, daily, and weekly quotas!
                      </p>
              </div>
            </div>
          </Card>

          <Card title="" style={{marginBottom:15}}>
            <div className="row justify-content-center feature-section">
              <div className="col-lg-4 offset-lg-1 text-left">
                <h3 style={{ textAlign: "center" }}>Instances</h3>
                <p>
                  Each Event has multiple instances, which you can think of as individual time periods for that event. For example, a Taekwondo school could create an Afternoon Training class, with instances happening at 3:00, 4:00, and 5:00 PM every Monday, Wednesday, and Friday. Students could be limited to 1 per day and 2 per week, with all nine instances being considered together!
                      </p>
              </div>
              <div className="col-lg-7 text-left">
                <img src="assets/img/screens/instance_create.png" alt="Instances allow multiple events" style={{ width: "90%", marginBottom: 20 }} />
              </div>
            </div>
          </Card>

          <Card title="" style={{marginBottom:15}}>
            <div className="row justify-content-center feature-section">
              <div className="col-lg-7 text-left">
                <img src="assets/img/screens/reservations.png" alt="Reservations allow signup control and management" style={{ width: "90%", marginBottom: 20 }} />
              </div>
              <div className="col-lg-4 offset-lg-1 text-left">
                <h3 style={{ textAlign: "center" }}>Reservations</h3>
                <p >
                  Keep track of who has signed up for your events. You can deny users who haven't submitted required forms, manually add users to allow additional attendees, and generate custom scheduling reports!
                      </p>
              </div>
            </div>
          </Card>



          <Card title="" style={{marginBottom:15}}>
            <div className="row justify-content-center feature-section">
              <div className="col-lg-4 offset-lg-1 text-left">
                <h3 style={{ textAlign: "center" }}>Simple Pricing</h3>
                <p>
                  Start free and upgrade as your team grows!
                      </p>

              </div>
              <div className="col-lg-7 text-left">
                
              <PlansDisplay currentPlanId={0} />
              </div>
            </div>
          </Card>

        </div>
      </Screen>
    );
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home) as any);