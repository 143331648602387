import * as React from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import * as qs from "query-string";

import Card from "../../structure/Card";
import { error } from "../../structure/Alert";
import MarkdownEditor from "src/components/structure/MarkdownEditor";

import { OrganizationsAPI, UserAPI } from "../../../api";
import { IOrganization, OrganizationBlank } from "src/api/organizations";

import * as UserActions from "../../../reducers/userReducer";
import { bindActionCreators } from "redux";
import logo from "src/img/hero_small.png";

interface ISignupScreenProps {
  alertsActions: any;
  userActions: any;
  userState: any;
  history: any;
  location: any;
}

interface ISignupScreenState {
  step: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  joinCode: any;
  showOrganizationInformationModal: boolean;
  organization: IOrganization;
  errorMessage: string;
  loading: boolean;
}

const helpText = ``;
class Signup extends React.Component<ISignupScreenProps, ISignupScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      step: 1,
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      joinCode: "",
      showOrganizationInformationModal: false,
      organization: OrganizationBlank,
      errorMessage: "",
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.signupUser = this.signupUser.bind(this);
    this.updateField = this.updateField.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
    this.toggleShowInformationModal = this.toggleShowInformationModal.bind(this);
    this.fetchOrganization = this.fetchOrganization.bind(this);
  }

  public componentDidMount(){
    const params = qs.parse(this.props.location.search);
    this.setState({
      joinCode: params.jc ? params.jc : "",
    });
  }
  public render() {
    if (this.props.userState.loggedIn) {
      return (<Redirect
        to="/dashboard" />);
    }
    if (this.state.step === 2) {
      return (
        <div>
          <div className="row justify-content-center">
            <div className="col-12" style={{ textAlign: "center" }}>
              <img src={logo} alt="Logo" style={{ maxWidth: "100%", marginBottom: 20 }} />
              <h3>Simple, Easy Schedule Management</h3>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12">
              <Card title="Verify your Email" help={helpText}>
                <div className="row">
                  <div className="col-12">
                    <strong>Thanks for registering!</strong>
                    <p>You need to check your email to confirm your account. A code should be sent momentarily. If you do not receive your code within 10 minutes, please contact support or try to register again. Thanks!</p>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-12" style={{ textAlign: "center" }}>
            <img src={logo} alt="Logo" style={{ maxWidth: "100%", marginBottom: 20 }} />
            <h3>Simple, Easy Schedule Management</h3>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-lg-6 col-sm-12">
            {!this.props.userState.loggedIn ? (
              <Card title="Signup" help={helpText}>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <strong>NOTE: </strong> TLSchedule is currently in an Open Beta phase. There may be bugs. Let us know and we will fix them as soon as we can. We always appreciate feedback!
                  </div>
                    <div className="form-group">
                      <label>First Name</label>
                      <input type="text" id="firstName" className="form-control" value={this.state.firstName} onChange={this.updateField} onKeyUp={this.checkEnter} />
                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <input type="text" id="lastName" className="form-control" value={this.state.lastName} onChange={this.updateField} onKeyUp={this.checkEnter} />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.updateField} onKeyUp={this.checkEnter} />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input type="password" id="password" className="form-control" value={this.state.password} onChange={this.updateField} onKeyUp={this.checkEnter} />
                    </div>
                    <div className="form-group">
                      <label>Join Code, If Provided</label>
                      <input type="text" id="joinCode" className="form-control" value={this.state.joinCode} onChange={this.updateField} onKeyUp={this.checkEnter} />
                    </div>
                    <div className="form-group">
                      {this.state.loading ? (
                        <div style={{textAlign: "center"}}>
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) :
                        (<button className="btn btn-block btn-primary" onClick={this.handleSubmit}>Signup</button>)}

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 pull-right">
                    <Link to="/login">Login</Link><br />
                  </div>
                  <div className="col-6 pull-right">
                    <Link to="/reset">Forgot?</Link><br />
                  </div>
                </div>
              </Card>) : (
                <Card title="Signup">
                  <div className="row">
                    <div className="col-md-6">
                      You are logged in!
              </div>
                  </div>
                </Card>
              )}
          </div>
        </div>
        
        <Modal show={this.state.showOrganizationInformationModal} onHide={this.toggleShowInformationModal} dialogClassName="modal-50">
          <Modal.Header closeButton={true}>
            <Modal.Title>Signup and Join {this.state.organization.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="row">
              <div className="col-12">
                <MarkdownEditor
                  content={this.state.organization.description}
                  mode="view"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <MarkdownEditor
                  content={this.state.organization.joinMessage}
                  mode="view"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-10 offset-1">
                {this.displayJoinAction}
              </div>
            </div>
            
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-success" onClick={this.signupUser}>Yes, Sign Me Up!</button>
            <button className="btn btn-block btn-default" onClick={this.toggleShowInformationModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private checkEnter(e: any) {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  }



  public async handleSubmit() {
    const firstName = this.state.firstName.trim();
    const lastName = this.state.lastName.trim();
    const email = this.state.email.trim();
    const password = this.state.password.trim();
    if (firstName === "" || lastName === "" || email === "" || password === "") {
      return error("All fields are required!")
    }

    // ok, so new logic
    // if they gave a join code, we are going to fetch the org data
    // and figure out what to show
    // TODO: just pass it on, as that path is currently JWT blocked and I want to
    // think through the performance ramifications
    if(this.state.joinCode !== ""){
      return this.fetchOrganization();
    } else {
      this.signupUser();
    }
    
  }

  private toggleShowInformationModal(){
    this.setState({ showOrganizationInformationModal: !this.state.showOrganizationInformationModal});
  }

  private signupUser(){
    const firstName = this.state.firstName.trim();
    const lastName = this.state.lastName.trim();
    const email = this.state.email.trim();
    const password = this.state.password.trim();
    if (firstName === "" || lastName === "" || email === "" || password === "") {
      return error("All fields are required!")
    }
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.signup(firstName, lastName, email, password, {joinCode: this.state.joinCode});
        this.setState({ loading: false, step: 2 });

      } catch (e) {
        error("Could not register. Please try again or contact support.");
        this.setState({ loading: false });
      }
    });
  }

  private fetchOrganization(){
    this.setState({ loading: true, errorMessage: "" }, async () => {
      try{
        const orgResult = await OrganizationsAPI.getOrganizationByJoinCode(this.state.joinCode);
        const organization = orgResult.body.data;
        this.setState({ loading: false, organization, errorMessage: "", showOrganizationInformationModal: true});
      }catch(err){
        this.setState({ loading: false, errorMessage: "Could not load a group with that join code. Please try again or contact the group you wish to join."})
      }
    });
  }

  get displayJoinAction(){
    if(this.state.organization.joinAction === "accept"){
      return (
        <p>This group will accept anyone who requests to join automatically. By continuing, you will automatically join the group.</p>
      );
    }
    if(this.state.organization.joinAction === "request"){
      return (
      <p>This group will approve requests to join individually. By continuing, you will request to join the group and an administrator from {this.state.organization.name} will need to approve or deny your request for membership.</p>
      );
    }
    if(this.state.organization.joinAction === "block"){
      return (
        <p><strong>This group does not allow joining with a join code. You will need to receive an invitation from the group prior to being allowed to join the group.</strong></p>
      );
    }
    return null;
  }

}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup) as any);