import * as React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { IUser } from "src/api/user";
import { IOrganization } from "src/api/organizations";

interface IMemberListItemProps {
  loggedInUser: IUser;
  member: IUser;
  organization: IOrganization;
  onStatusChange: any;
  onRoleChange: any;
  onRemoveUser: any;
  isHighlighted: boolean;
}

interface IMemberListItemState {
  loading: boolean;
  showRemoveModal: boolean;
}

export class MemberListItem extends React.Component<IMemberListItemProps, IMemberListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      showRemoveModal: false,
    };

    this.toggleRemoveModal = this.toggleRemoveModal.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  public render() {
    return (
      <div className={`row list-row ${this.props.isHighlighted && "member-highlighted"}`} style={{marginBottom: 7}}>
        <div className="col-lg-4 col-md-12">
          <Link to={`/organizations/${this.props.organization.id}/users/${this.props.member.id}/events`}><strong>{`${this.props.member.firstName} ${this.props.member.lastName}`}</strong></Link>
        </div>
        <div className="col-lg-3 col-md-12">
          {this.props.loggedInUser.id === this.props.member.id ? (
            <select id="organizationRole" className="form-control form-control-small-table" disabled={true} defaultValue={this.props.member.organizationRole}>
                <option value="member">Member</option>
                <option value="billing">Billing Manager</option>
                <option value="admin">Administrator</option>
              </select>
            )
          : (
            <select id="organizationRole" className="form-control form-control-small-table" value={this.props.member.organizationRole} onChange={this.onChangeRole}>
              <option value="member">Member</option>
              <option value="billing">Billing Manager</option>
              <option value="admin">Administrator</option>
            </select>
          )}
        </div>
        <div className="col-lg-3 col-md-12">
          {this.props.loggedInUser.id === this.props.member.id ? (
            <select id="status" className="form-control form-control-small-table" defaultValue={this.props.member.status} disabled={true}>
              <option value="accepted">Accepted</option>
              {this.props.member.status === "requested" && (<option value="requested">Requested</option>)}
              <option value="denied">Denied</option>
            </select>
          ) : (
            <select id="status" className="form-control form-control-small-table" value={this.props.member.status} onChange={this.onChangeStatus}>
              <option value="accepted">Accepted</option>
              {this.props.member.status === "requested" && (<option value="requested">Requested</option>)}
              <option value="denied">Denied</option>
            </select>
          )}
        </div>
        <div className="col-lg-2 col-md-12">
          {this.props.loggedInUser.id !== this.props.member.id && (<span className="oi oi-x icon icon-danger" title="Delete User" onClick={this.toggleRemoveModal} />)}
        </div>


        <Modal show={this.state.showRemoveModal} onHide={this.toggleRemoveModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Remove User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove {this.props.member.firstName} {this.props.member.lastName}? This will remove all events and event history for the user. Instead, you may want to change their status to <strong>denied</strong> which will prevent any future event registrations but will leave the previous history in tact.
            This cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.onRemove}>Yes, Remove This User Completely</button>
            <button className="btn btn-block btn-default" onClick={this.toggleRemoveModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private toggleRemoveModal(){
    this.setState({ showRemoveModal: !this.state.showRemoveModal });
  }

  private onChangeStatus(e: any){
    this.props.onStatusChange(this.props.member, "status", e.target.value);
  }

  private onChangeRole(e: any){
    this.props.onRoleChange(this.props.member, "organizationRole", e.target.value);
  }

  private onRemove(){
    this.props.onRemoveUser(this.props.member);
  }

}