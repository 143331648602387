import * as React from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Card from "../../structure/Card";
import { error } from "../../structure/Alert";

import { UserAPI } from "../../../api";

import * as UserActions from "../../../reducers/userReducer";
import { bindActionCreators } from "redux";
import logo from "src/img/hero_small.png";

interface IResetPasswordProps {
  userState: any;
  history: any;
}

const helpText = ``;
class ResetPassword extends React.Component<IResetPasswordProps, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      step: 1,
      email: "",
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateField = this.updateField.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
  }

  public async handleSubmit() {
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.resetPassword(this.state.email);
        this.setState({ step: 2, loading: false });
      } catch (e) {
        error("Could not reset that email address.");
        this.setState({ loading: false });
      }
    });
  }

  public render() {
    if (this.props.userState.loggedIn) {
      return (<Redirect
        to="/dashboard" />);
    }

    if (this.state.step === 2) {
      return (
        <div>
          <div className="row justify-content-center">
            <div className="col-12" style={{ textAlign: "center" }}>
              <img src={logo} alt="Logo" style={{ maxWidth: "100%", marginBottom: 20 }} />
              <h3>Simple, Easy Schedule Management</h3>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12">
              <Card title="Verify your Email" help={helpText}>
                <div className="row">
                  <div className="col-12">
                    <strong>We have begun the password reset process.</strong>
                    <p>You need to check your email to confirm your account. A code should be sent momentarily. If you do not receive your code within 10 minutes, please contact support or try again. Thanks!</p>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-12" style={{ textAlign: "center" }}>
            <img src={logo} alt="Logo" style={{ maxWidth: "100%", marginBottom: 20 }} />
            <h3>Simple, Easy Schedule Management</h3>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-lg-6 col-sm-12">
            <Card title="Reset Password" help={helpText}>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.updateField} onKeyUp={this.checkEnter} />
                  </div>
                  <div className="form-group">
                    {this.state.loading ? (<div style={{textAlign: "center"}}>
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>) :
                      (<button className="btn btn-block btn-primary" onClick={this.handleSubmit}>Reset Password</button>)}

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12 pull-right">
                  <Link to="/signup">Signup</Link><br />
                </div>
                <div className="col-lg-6 col-sm-12 pull-right">
                  <Link to="/reset">Forgot?</Link><br />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private checkEnter(e: any) {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword) as any);