import moment from "moment";
import * as React from "react";
import { IEventInstance } from "src/api/events";
import { IOrganization } from "src/api/organizations";

interface IEventInstanceMemberListItemProps {
  organization: IOrganization
  instance: IEventInstance;
  isRegistered: boolean;
  canRegister: boolean;
  instanceSelected: any;
}

interface IEventInstanceMemberListItemState {
  loading: boolean;
  now: moment.Moment;
}

export default class EventInstanceMemberListItem extends React.Component<IEventInstanceMemberListItemProps, IEventInstanceMemberListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      now: moment(),
    };
    this.handleInstanceSelected = this.handleInstanceSelected.bind(this);

  }

  componentDidMount(){
    this.setState({ now: moment() });
  }
  

  public render() {
    return (
      <div className="row" onClick={this.handleInstanceSelected} style={{marginBottom: 25}}>
        <div className="col-lg-4 col-sm-6">
          <strong>{this.props.instance.eventName}</strong>
        </div>
        <div className="col-lg-2 col-sm-6">
          {this.props.instance.startTime.format("MM/DD/YY")}
        </div>
        <div className="col-lg-2 col-sm6">
          {this.props.instance.startTime.format("hh:mm A")}
        </div>
        <div className="col-lg-4 col-sm-6">
          {this.props.instance.startTime.isBefore(this.state.now) ? 
          (null) : 
          (<button className="btn btn-link">{this.props.isRegistered ? "Already Signed Up - Manage" : "Register"}</button>)}
          
        </div>
      </div>
    );
  }

  private handleInstanceSelected(){
    this.props.instanceSelected(this.props.instance);
  }

}