import { makeCall } from "./client";

export interface IPlan {
  id: number;
  amount: number;
  dataArchivalDays: number;
  eventInstancesAllowed: number;
  name: string;
  productId: string;
  shortCode: string;
  status: string;
  term: string;
  usersAllowed: number;
  payingDisplay?: string;
}

export const BlankPlan: IPlan = {
  id: 0,
  amount: 0,
  dataArchivalDays: 0,
  eventInstancesAllowed: 0,
  name: "",
  productId: "",
  shortCode: "",
  status: "",
  term: "",
  usersAllowed: 0,
}

export interface IPaymentMethod {
  brand: string;
  expiresMonth: string;
  expiresYear: string
  lastFour: string;
}

export const BlankPaymentMethod: IPaymentMethod = {
  brand: "",
  expiresMonth: "01",
  expiresYear: "2020",
  lastFour: "",
}

export class BillingAPI {

  /**
   * Get the acitve plans
   * @param otherData 
   */
  public getPlans(otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("get", `plans`, data);
  }

  /**
   * Get the organization's current payment method
   * @param organizationId 
   * @param otherData 
   */
  public getPaymentMethod(organizationId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("get", `organizations/${organizationId}/subscriptions/paymentMethod`, data);
  }

  /**
   * Save a new payment method
   * @param organizationId 
   * @param token 
   * @param otherData 
   */
  public savePaymentMethod(organizationId: number, token: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      token,
    };
    return makeCall("post", `organizations/${organizationId}/subscriptions/paymentMethod`, data);
  }

  /**
   * Changes the organization's subscription
   * @param organizationId 
   * @param planId 
   * @param otherData 
   */
  public changeSubscription(organizationId: number, planId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      planId,
    };
    return makeCall("post", `organizations/${organizationId}/subscriptions`, data);
  }

  /**
   * Cancel a subscription for an organization. The organization will revert to a free plan on the next charge date.
   * @param organizationId 
   * @param otherData 
   */
  public cancelSubscription(organizationId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("delete", `organizations/${organizationId}/subscriptions`, data);
  }

}