import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import Card from "src/components/structure/Card";
import MarkdownEditor from "src/components/structure/MarkdownEditor";
import * as AppActions from "src/reducers/appReducer";
import { IOrganization } from "src/api/organizations";
import { OrganizationsAPI } from "src/api";

interface IOrganizationsListProps {
  appActions: any;
  history: any;
}

interface IOrganizationsListState {
  loading: boolean;
  organizations: IOrganization[];
}

class OrganizationsList extends React.Component<IOrganizationsListProps, IOrganizationsListState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      organizations: [],
    };

    this.fetchOrganizations = this.fetchOrganizations.bind(this);
  }

  componentDidMount(){
    this.fetchOrganizations();
  }

  public render() {
    if(this.state.organizations.length === 0){
      return (
        <div className="row">
          <div className="col-12">
            <Card title="" loading={this.state.loading} help="">
              <strong>You are not a member of any groups.</strong>
            </Card>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        {this.state.organizations.map((o) => {
          return (
            <div className="col-lg-4 col-sm-12" key={o.id}>
              <Card title="" loading={false} help="" style={{marginBottom: 20}}>
                <h2>{o.name}</h2>
                <MarkdownEditor
                  content={o.description}
                  mode="view" />
                <div className="row">
                  <div className="col-12" style={{textAlign: "right"}}>
                    <Link to={`/organizations/${o.id}`}>More</Link>
                  </div>
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    );
  }

  private fetchOrganizations(){
    this.setState({loading: false}, async () => {
      try{
        const orgResult = await OrganizationsAPI.getUserOrganizations();
        const organizations: IOrganization[] = [];
        for(const o of orgResult.body.data){
          if(o.nextCharge && o.nextCharge !== ""){
            o.nextCharge = moment(o.nextCharge);
          }
          if(o.lastCharge && o.lastCharge !== ""){
            o.lastCharge = moment(o.lastCharge);
          }
          organizations.push(o);
        }
        this.setState({ loading: false, organizations});
      }catch(err){}
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationsList) as any);