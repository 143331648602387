import { createAction, createReducer } from "redux-act";
import moment from "moment";
import { IEventTemplate, EventTemplateBlank } from "src/api/events";

export const setUpcomingEventsMemberScreenDates: any = createAction("events setUpcomingEventsMemberScreenDates");
export const setUpcomingEventsAdminScreenDates: any = createAction("events setUpcomingEventsAdminScreenDates");
export const setSelectedAdminEvent: any = createAction("events setSelectedAdminEvent");

interface IUpcomingEventsPayload {
  which: "start" | "end";
  newDate: moment.Moment;
}

interface ISetSelectedAdminEventPayload {
  event: IEventTemplate;
}

export default createReducer({
  [setUpcomingEventsMemberScreenDates]: (state, payload: IUpcomingEventsPayload) => {
    let start = state.upcomingEventsMemberScreenStart;
    let end = state.upcomingEventsMemberScreenEnd;
    if(payload.which === "start"){
      start = payload.newDate;
    }
    if(payload.which === "end"){
      end = payload.newDate;
    }
    return {
      ...state,
      upcomingEventsMemberScreenStart: start,
      upcomingEventsMemberScreenEnd: end,
    };
  },
  [setUpcomingEventsAdminScreenDates]: (state, payload: IUpcomingEventsPayload) => {
    let start = state.upcomingEventsAdminScreenStart;
    let end = state.upcomingEventsAdminScreenEnd;
    if(payload.which === "start"){
      start = payload.newDate;
    }
    if(payload.which === "end"){
      end = payload.newDate;
    }
    return {
      ...state,
      upcomingEventsAdminScreenStart: start,
      upcomingEventsAdminScreenEnd: end,
    };
  },
  [setSelectedAdminEvent]: (state, payload: ISetSelectedAdminEventPayload) => {
    
    return {
      ...state,
      selectedAdminEvent: payload.event,
    };
  },
  }, {
    upcomingEventsMemberScreenStart: moment(),
    upcomingEventsMemberScreenEnd: moment().add(7, "days"),
    upcomingEventsAdminScreenStart: moment(),
    upcomingEventsAdminScreenEnd: moment().add(7, "days"),
    selectedAdminEvent: EventTemplateBlank,
});