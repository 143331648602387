import * as React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as qs from "query-string";

import Card from "../../structure/Card";
import { error } from "../../structure/Alert";

import { UserAPI } from "../../../api";

import logo from "src/img/hero_small.png";
import * as UserActions from "../../../reducers/userReducer";
import { bindActionCreators } from "redux";

interface ILoginScreenProps {
  alertsActions: any;
  userActions: any;
  userState: any;
  history: any;
  location: any;
}

interface ILoginScreenState {
  email: string;
  password: string;
  loading: boolean;
  expired: boolean;
}

const helpText = ``;
class Login extends React.Component<ILoginScreenProps, ILoginScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      expired: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateField = this.updateField.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
  }

  componentDidMount(){
    const expired = window.localStorage.getItem("expired");
    if(expired && expired === "yes"){
      this.props.userActions.logoutUser();
      window.localStorage.setItem("expired", "no");
      this.setState({expired: true});
    }
  }

  public render() {    
    if(this.props.userState.loggedIn){
      return (<Redirect
        to="/dashboard" />);
    }
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-12" style={{ textAlign: "center" }}>
            <img src={logo} alt="Logo" style={{ maxWidth: "100%", marginBottom: 20 }} />
            <h3>Simple, Easy Schedule Management</h3>
          </div>
        </div>
      <div className="row justify-content-md-center">
        <div className="col-lg-6 col-sm-12">
          {!this.props.userState.loggedIn ? (
            <Card title="Login" help={helpText}>
              <div className="row">
                <div className="col-12">
                {this.state.expired  && (
                  <div className="row">
                    <div className="col-md-12 bg-danger text-white" style={{ padding: 15, marginBottom: 20 }}>
                      <strong>You have been logged out as your authentication has expired. Please log back in.</strong>
                    </div>
                  </div>
                )}
                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.updateField} onKeyUp={this.checkEnter} />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input type="password" id="password" className="form-control" value={this.state.password} onChange={this.updateField} onKeyUp={this.checkEnter} />
                  </div>
                  <div className="form-group">
                    {this.state.loading ? (<div style={{textAlign: "center"}}>
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>) :
                      (<button className="btn btn-block btn-primary" onClick={this.handleSubmit}>Login</button>)}
                    
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 pull-right">
                  <Link to="/signup">Signup</Link><br />
                </div>
                <div className="col-6 pull-right">
                  <Link to="/reset">Forgot?</Link><br />
                </div>
              </div>
            </Card>) : (
              <Card title="Login">
                <div className="row">
                  <div className="col-md-6">
                    You are logged in!
              </div>
                </div>
              </Card>
            )}
        </div>

      </div>
      </div>
    );
  }

  public async handleSubmit() {
    this.setState({loading: true}, async () => {
      
      try {
        const result = await UserAPI.loginUser(this.state.email, this.state.password);
        const user = result.body.data;
        user.jwt = "";
        user.secretKey = "";
        user.access_token = "";
        user.refresh_token = "";
        window.localStorage.user = JSON.stringify(user);

        // set the action
        this.props.userActions.loginUser({loggedIn: true, user});
        // now, if there is a return, we bring them there
        // the return can be either in the query string or in local storage
        const params = qs.parse(this.props.location.search);
        let ret: any = params.return && params.return !== "" ? params.return : "";
        if(ret === ""){
          // check localStorage
          ret = localStorage.getItem("return");
        } 
        if(ret && ret !== ""){
          // lookup where we should be going
          if(ret === "organization_join"){
            // was a join code provided?
            let joinCode: any = params.jc ? params.jc : "";
            // check ls in case it's missing
            if(joinCode === ""){
              joinCode = localStorage.getItem("jc");
              if(!joinCode){
                joinCode = ""; // don't put null in the url
              }
            }
            const jcEnc = encodeURIComponent(joinCode);
            this.props.history.push(`/organizations/join?joinCode=${jcEnc}`);
            return
          }
          if(ret === "organization_invite_respond"){
            // was an invite code provided?
            let ic: any = params.ic ? params.ic : "";
            let oi: any = params.oi ? params.oi : "";

            // check ls in case it's missing
            if(ic === ""){
              ic = localStorage.getItem("ic");
              if(!ic){
                ic = ""; // don't put null in the url
              }
            }
            // same with oi
            if(oi === ""){
              oi = localStorage.getItem("oi");
              if(!oi){
                oi = "";
              }
            }
            // if oi is blank, we have no idea what to do, so let's send them to their dashboard?
            if(oi === ""){
              return this.props.history.push(`/organizations`);
            }
            const icEnc = encodeURIComponent(ic);
            this.props.history.push(`/organizations/${oi}/invites/respond?ic=${icEnc}`);
            return
          }
        }

        // otherwise, dashboard
        // this.props.history.push("/dashboard");

      } catch (e) {
        error("Could not log you in.");
        this.setState({loading: false});
      }
    });
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private checkEnter(e: any){
    if(e.keyCode === 13) {
      this.handleSubmit();
    }
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login) as any);