import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import * as AppActions from "src/reducers/appReducer";

interface IStarterProps {
  appActions: any;
  history: any;
}

interface IStarterState {
  loading: boolean;
}

class Starter extends React.Component<IStarterProps, IStarterState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

  }

  public render() {
    return (
      <div className="row">
        <div className="col-3">
          <Card title="My Reservations" loading={this.state.loading} help="">
            Show Them
          </Card>
        </div>
        <div className="col-4">
          <Card title="Events" loading={this.state.loading} help="">
            Org Select
          </Card>
        </div>
        <div className="col-5">
          <Card title="Instances" loading={this.state.loading} help="">
            From selected org and event
          </Card>
        </div>
      </div>
    );
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Starter) as any);