import React from "react";

interface ILoadingButton {
  loading: boolean;
  children: any;
  type?: any;
  className?: string;
  onClick?: any;
}

export const LoadingButton = (props: ILoadingButton) => {
  
  const className = props.className ? props.className : "btn btn-block btn-primary";

  if(props.loading){
    return (
      <div style={{textAlign: "center"}}>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <button className={className} type={props.type} onClick={props.onClick}>{props.children}</button>
  )
}