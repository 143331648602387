import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import {ISubaccount, SubaccountBlank } from "../../../api/user";

import Card from "../../structure/Card";
import { error } from "../../structure/Alert";
import * as AppActions from "../../../reducers/appReducer";
import { UserAPI } from "src/api";
import { Modal } from "react-bootstrap";

interface ISubaccountsProps {
  appActions: any;
  history: any;
}

interface ISubaccountsState {
  loading: boolean;
  subaccounts: ISubaccount[];
  selectedSubaccount: ISubaccount;
  showCreateModal: boolean;
  showEditModal: boolean;
  showDeleteModal: boolean;
}

class Starter extends React.Component<ISubaccountsProps, ISubaccountsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      subaccounts: [],
      selectedSubaccount: SubaccountBlank,
      showCreateModal: false,
      showDeleteModal: false,
      showEditModal: false,
    };

    this.updateSubaccountField = this.updateSubaccountField.bind(this);
    this.fetchSubaccounts = this.fetchSubaccounts.bind(this);

    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);

    this.createSubaccount = this.createSubaccount.bind(this);
    this.editSubaccount = this.editSubaccount.bind(this);
    this.deleteSubaccount = this.deleteSubaccount.bind(this);
  }

  public componentDidMount(){
    this.fetchSubaccounts();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-4 offset-1">
          <Card title="" loading={this.state.loading} help="">
            <p>Subaccounts are people you want to manage event registrations for, but shouldn't have their own accounts. For example, if you wanted to register a child for an event, you would create a subaccount for the child and then register that subaccount for the event.</p>
            <p><strong>Depending on how an event is configured, in some cases multiple subaccounts will only account as a single participant for a reservation.</strong></p>
          </Card>
        </div>
        <div className="col-md-6">
          <Card title="Current Subaccounts" loading={this.state.loading} help="">
            {this.state.subaccounts.length === 0 && (<strong>No subaccounts have been created</strong>)}
            {this.state.subaccounts.map((account: ISubaccount) => {
              return (
                <div className="row" key={account.id}>
                  <div className="col-8">
                    {`${account.firstName} ${account.lastName}`}
                  </div>
                  <div className="col-2">
                  <span className="oi oi-pencil icon icon-primary" title="Edit Subaccount" onClick={() => {
                    this.setState({ selectedSubaccount: account }, () => {
                      this.toggleEditModal();
                    });
                  }} />
                  </div>
                  <div className="col-2">
                  <span className="oi oi-x icon icon-danger" title="Delete Subaccount" onClick={() => {
                    this.setState({ selectedSubaccount: account }, () => {
                      this.toggleDeleteModal();
                    });
                  }} />
                  </div>
                </div>
              );
            })}
            <div className="row" style={{marginTop: 10}}>
              <div className="col-12">
                <div className="btn btn-block btn-primary" onClick={this.toggleCreateModal}>Create New Subaccount</div>
              </div>
            </div>
          </Card>
        </div>

        <Modal show={this.state.showCreateModal} onHide={this.toggleCreateModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Create Subaccount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>First Name</label>
              <input type="text" className="form-control" id="firstName" value={this.state.selectedSubaccount.firstName} onChange={this.updateSubaccountField} />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input type="text" className="form-control" id="lastName" value={this.state.selectedSubaccount.lastName} onChange={this.updateSubaccountField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.createSubaccount}>Create</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Subaccount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the subaccount for {`${this.state.selectedSubaccount.firstName} ${this.state.selectedSubaccount.lastName}`}? This will cancel all registrations for the subaccount.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.deleteSubaccount}>Yes, Delete This Subaccount</button>
            <button className="btn btn-block btn-default" onClick={this.toggleDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showEditModal} onHide={this.toggleEditModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Edit Subaccount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>First Name</label>
              <input type="text" className="form-control" id="firstName" value={this.state.selectedSubaccount.firstName} onChange={this.updateSubaccountField} />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input type="text" className="form-control" id="lastName" value={this.state.selectedSubaccount.lastName} onChange={this.updateSubaccountField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.editSubaccount}>Update</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateSubaccountField(e: any){
    const ns = this.state.selectedSubaccount;
    ns[e.target.id] = e.target.value;
    this.setState({ selectedSubaccount: ns});
  }

  private toggleCreateModal(){
    this.setState({ showCreateModal: !this.state.showCreateModal});
  }

  private toggleDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private toggleEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private fetchSubaccounts(){
    this.setState({ loading: true }, async () => {
      try{
        const accountResult = await UserAPI.getSubaccounts();
        // process
        const accounts: ISubaccount[] = [];
        for(const account of accountResult.body.data){
          account.createdOn = moment(account.createdOn);
          accounts.push(account)
        }
        this.setState({ subaccounts: accounts, loading: false });
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

  private createSubaccount(){
    const firstName = this.state.selectedSubaccount.firstName.trim();
    const lastName = this.state.selectedSubaccount.lastName.trim();
    if(firstName === "" || lastName === ""){
      return error("First Name and Last Name are required");
    }
    this.setState({ loading: true }, async () => {
      try{
        const result = await UserAPI.createSubaccount(firstName, lastName);
        const account = result.body.data;
        account.createdOn = moment(account.createdOn);
        const accounts = this.state.subaccounts;
        accounts.push(account);
        accounts.sort((a: ISubaccount, b: ISubaccount) => {
          const r = a.lastName === b.lastName ? 0 : a.lastName > b.lastName ? 1 : -1;
          if(r !== 0){
            return r;
          }
          return a.firstName > b.lastName ? 1 : -1;
        });
        const sa = this.state.selectedSubaccount;
        sa.firstName = ""
        sa.lastName = ""
        sa.id = 0;
        sa.createdOn = moment();
        this.setState({ selectedSubaccount: sa, subaccounts: accounts, loading: false, showCreateModal: false});
      }catch(err){
        this.setState({ loading: false }, () => {
          error("Could not create that subaccount");
        })
      }
    });
  }

  private editSubaccount(){
    const firstName = this.state.selectedSubaccount.firstName.trim();
    const lastName = this.state.selectedSubaccount.lastName.trim();
    if(firstName === "" || lastName === ""){
      return error("First Name and Last Name are required");
    }
    this.setState({ loading: true }, async () => {
      try{
        const result = await UserAPI.updateSubaccount(this.state.selectedSubaccount.id, {firstName, lastName});
        const account = result.body.data;
        account.createdOn = moment(account.createdOn);
        const accounts: ISubaccount[] = [];
        // find and update
        for(let sa of this.state.subaccounts){
          if(sa.id === account.id){
            sa = account;
          }
          accounts.push(sa);
        }
        // sort
        accounts.sort((a: ISubaccount, b: ISubaccount) => {
          const r = a.lastName === b.lastName ? 0 : a.lastName > b.lastName ? 1 : -1;
          if(r !== 0){
            return r;
          }
          return a.firstName > b.lastName ? 1 : -1;
        });
        this.setState({ selectedSubaccount: SubaccountBlank, subaccounts: accounts, loading: false, showEditModal: false});
      }catch(err){
        this.setState({ loading: false }, () => {
          error("Could not edit that subaccount");
        })
      }
    });
  }

  private deleteSubaccount(){
    this.setState({ loading: true }, async () => {
      try{
        await UserAPI.deleteSubaccount(this.state.selectedSubaccount.id);
        // remove it from the list
        const subaccounts: ISubaccount[] = [];
        for(const sa of this.state.subaccounts){
          if(sa.id !== this.state.selectedSubaccount.id){
            subaccounts.push(sa);
          }
        }
        this.setState({ loading: false, selectedSubaccount: SubaccountBlank, showDeleteModal: false, subaccounts});
      }catch(err){
        this.setState({ loading: false }, () => {
          error("Could not delete that subaccount");
        })
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Starter) as any);