import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { LoadingButton } from "src/components/structure/LoadingButton";
import { error } from "src/components/structure/Alert";

export const ChangePaymentMethod = (props: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, toggleLoading] = useState(false);

  const handleSavePaymentMethod = async (event: any) => {
    event.preventDefault()
    toggleLoading(true);
    if (!stripe || !elements) {
      return;
    }
    const el = elements.getElement(CardElement);
    if (el === null) {
      toggleLoading(false);
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: el,
    });
    if (payload.error) {
      toggleLoading(false);
      return error("We could not save that payment method. Please verify your information or contact support");
    }
    props.onSavePaymentMethod(payload);
    toggleLoading(false);
  }

  return (
    <div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={handleSavePaymentMethod}>
              <label className="stripe-label">
                New Payment Method
                <CardElement />
              </label>
              <LoadingButton loading={loading} type="submit">Save Payment Method</LoadingButton>
            </form>
          </div>
        </div>
    </div>
  )
}
