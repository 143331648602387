import { makeCall } from "./client";
import moment from "moment";

export interface ICancellation {
  id: number;
  organizationId: number;
  eventTemplateId: number;
  eventInstanceId: number;
  eventReservationId: number;
  userId: number;
  participantId: number;
  participantType: "user" | "subaccount";
  cancelledOn: moment.Moment;
  cancelledReason: string;
  cancelledMessage: string;

  eventName: string;
  participantFirstName: string;
  participantLastName: string;
  userFirstName: string;
  userLastName: string;
  instanceStartTime: moment.Moment;
}

export class CancellationsAPI {

  public translateReasonToDescription(reason: string): string {
    switch(reason){
      case "accidental_reservation":
        return "The user accidentally created the reservation.";
      case "time_conflict":
        return "There was a time conflict for the user.";
      case "better_time_slot":
        return "The user found a better time slot available.";
      case "no_longer_interested":
        return "The user is no longer interested in that reservation.";
      case "other":
      case "unknown":
      default:
        return "Unknown reason or reason not provided.";
    }
  }

  public getOrganizationCancellations(organizationId: number, start: string, end: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      start,
      end
    };
    return makeCall("get", `organizations/${organizationId}/cancellations`, data);
  }

  public getCancellationNotificationPreferences(organizationId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("get", `organizations/${organizationId}/cancellations/notify`, data);
  }

  public saveCancellationNotificationPreference(organizationId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("put", `organizations/${organizationId}/cancellations/notify`, data);
  }

  public deleteCancellationNotificationPreference(organizationId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("delete", `organizations/${organizationId}/cancellations/notify`, data);
  }

}