import { makeCall } from "./client";
import moment from "moment";

export interface IEventTemplate {
 id: number;
 organizationId: number;
 name: string;
 description: string;
 status: "active" | "pending" | "archived";
 locationStreet: string;
 locationCity: string;
 locationState: string;
 locationZip: string;
 sendSummariesTo: string;
 maximumReservationsPerInstance: number;
 maximumReservationsPerInstanceRule: "absolute" | "allow_subaccounts_over";
 maximumReservationsPerUserPerDay: number;
 maximumReservationsPerUserPerWeek: number;
}

export const EventTemplateBlank: IEventTemplate = {
  id: 0,
  organizationId: 0,
  name: "",
  description: "",
  status: "pending",
  locationStreet: "",
  locationCity: "",
  locationState: "",
  locationZip: "",
  sendSummariesTo: "",
  maximumReservationsPerInstance: 0,
  maximumReservationsPerInstanceRule: "absolute",
  maximumReservationsPerUserPerDay: 0,
  maximumReservationsPerUserPerWeek: 0,
};

export interface IEventInstance {
  id: number;
  organizationId: number;
  eventTemplateId: number;
  startTime: moment.Moment;
  numberOfMinutes: number;
  eventTemplate?: IEventTemplate;
  currentReservations?: any;
  createTimes?: any;
  errors?: any;
  maximumReservationsPerInstance?: number;
  eventName?: string;
  userReservations?: number;
  uniqueUserReservations?: number;
  maximumReservationsPerInstanceRule?: "absolute" | "allow_subaccounts_over";
  status: string;
  releaseOnDate: moment.Moment;

  // for the calendar
  startTimeDate?: Date;
  endTimeDate?: Date
  title?: string;
  canRegister?: boolean;
  isRegistered?: boolean;
  adminCanAnotherRegister?: boolean;
};

export const EventInstanceBlank = {
  id: 0,
  organizationId: 0,
  eventTemplateId: 0,
  startTime: moment(),
  numberOfMinutes: 0,
  status: "active",
  releaseOnDate: moment(),
}

export interface IEventReservation {
  id: number;
  organizationId: number;
  eventTemplateId: number;
  eventInstanceId: number;
  eventName: string;
  participantId: number;
  participantType: "user" | "subaccount";
  participantFirstName: string;
  participantLastName: string;
  userFirstName: string;
  userLastName: string;
  userId: number;
  status: "pending" | "confirmed" | "denied";
  requestedOn: moment.Moment;
  updatedOn: moment.Moment;
  instanceStartTime: moment.Moment;
  
  additionalParticipants: any[];
}

export const EventReservationBlank: IEventReservation = {
  id: 0,
  organizationId: 0,
  eventTemplateId: 0,
  eventInstanceId: 0,
  eventName: "",
  participantId: 0,
  participantType: "user",
  participantFirstName: "",
  participantLastName: "",
  userFirstName: "",
  userLastName: "",
  userId: 0,
  status: "pending",
  requestedOn: moment(),
  updatedOn: moment(),
  instanceStartTime: moment(),
  
  additionalParticipants: [],
};

export class EventsAPI {

  /**
   * Get the user's reservations
   * @param otherData 
   */
  public getUserReservations(otherData: any = {}): Promise<any> {
    return makeCall("get", `events/reservations`, otherData);
  }

  /**
   * Get the reservations for the organization
   * @param organizationId 
   * @param otherData 
   */
  public getOrganizationReserverations(organizationId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `organizations/${organizationId}/events/reservations`, otherData);
  }

  /**
   * Get the reservations for a user in an organization
   * @param organizationId 
   * @param userId 
   * @param start 
   * @param end 
   * @param otherData 
   */
  public getUserOrganizationReserverations(organizationId: number, userId: number, start: string, end: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      start,
      end
    }
    return makeCall("get", `organizations/${organizationId}/users/${userId}/events`, data);
  }

  public getEventTemplates(organizationId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `organizations/${organizationId}/events`, otherData);
  }

  public createTemplate(organizationId: number, otherData: any = {}): Promise<any> {
    return makeCall("post", `organizations/${organizationId}/events`, otherData);
  }

  public getEventTemplate(organizationId: number, templateId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `organizations/${organizationId}/events/${templateId}`, otherData);
  }

  public updateEventTemplate(organizationId: number, templateId: number, otherData: any = {}): Promise<any> {
    return makeCall("patch", `organizations/${organizationId}/events/${templateId}`, otherData);
  }

  public deleteEventTemplate(organizationId: number, templateId: number, otherData: any = {}): Promise<any> {
    return makeCall("delete", `organizations/${organizationId}/events/${templateId}`, otherData);
  }

  //
  // Instances
  //


  public getInstancesForOrganization(organizationId: number, start: string, end: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      start,
      end
    };
    return makeCall("get", `organizations/${organizationId}/events/instances`, data);
  }

  public getEventTemplateInstances(organizationId: number, templateId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `organizations/${organizationId}/events/${templateId}/instances`, otherData);
  }

  public createEventTemplateInstances(organizationId: number, templateId: number, otherData: any = {}): Promise<any> {
    return makeCall("post", `organizations/${organizationId}/events/${templateId}/instances`, otherData);
  }

  public getEventTemplateInstance(organizationId: number, templateId: number, instanceId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `organizations/${organizationId}/events/${templateId}/instances/${instanceId}`, otherData);
  }

  public updateEventTemplateInstance(organizationId: number, templateId: number, instanceId: number, otherData: any = {}): Promise<any> {
    return makeCall("patch", `organizations/${organizationId}/events/${templateId}/instances/${instanceId}`, otherData);
  }

  public deleteEventTemplateInstance(organizationId: number, templateId: number, instanceId: number, otherData: any = {}): Promise<any> {
    return makeCall("delete", `organizations/${organizationId}/events/${templateId}/instances/${instanceId}`, otherData);
  }

  //
  // Reservations
  // 
  public getInstanceReservations(organizationId: number, templateId: number, instanceId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `organizations/${organizationId}/events/${templateId}/instances/${instanceId}/reservations`, otherData);
  }
  
  public createInstanceReservation(organizationId: number, templateId: number, instanceId: number, otherData: any = {}): Promise<any> {
    return makeCall("post", `organizations/${organizationId}/events/${templateId}/instances/${instanceId}/reservations`, otherData);
  }
  
  public createInstanceReservationAdminOverride(organizationId: number, templateId: number, instanceId: number, otherData: any = {}): Promise<any> {
    return makeCall("post", `organizations/${organizationId}/events/${templateId}/instances/${instanceId}/reservations/override`, otherData);
  }
  
  public getInstanceReservation(organizationId: number, templateId: number, instanceId: number, reservationId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `organizations/${organizationId}/events/${templateId}/instances/${instanceId}/reservations/${reservationId}`, otherData);
  }
  
  public updateInstanceReservation(organizationId: number, templateId: number, instanceId: number, reservationId: number, otherData: any = {}): Promise<any> {
    return makeCall("patch", `organizations/${organizationId}/events/${templateId}/instances/${instanceId}/reservations/${reservationId}`, otherData);
  }
  
  public cancelInstanceReservation(organizationId: number, templateId: number, instanceId: number, reservationId: number, otherData: any = {}): Promise<any> {
    return makeCall("post", `organizations/${organizationId}/events/${templateId}/instances/${instanceId}/reservations/${reservationId}/cancel`, otherData);
  }
}