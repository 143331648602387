import * as React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import store from "./store";

import {StripeElementsContainer} from "src/components/structure/StripeElementsContainer";
import Menu from "./components/structure/Menu";
import Nav from "./components/structure/Nav";

// get the screens
import Home from "./components/screens/Home";
import Dashboard from "./components/screens/Dashboard";
import Terms from "./components/screens/Terms";

import Login from "./components/screens/Login/Login";
import Signup from "./components/screens/Login/Signup";
import SignupVerify from "./components/screens/Login/SignupVerify";
import ResetPassword from "./components/screens/Login/ResetPassword";
import ResetPasswordVerify from "./components/screens/Login/ResetPasswordVerify";
import ProfileScreen from "./components/screens/User/Profile";
import SubaccountScreen from "./components/screens/User/Subaccounts";

// organizations
import OrganizationsList from "./components/screens/Organizations/OrganizationsList";
import OrganizationViewScreen from "./components/screens/Organizations/OrganizationViewScreen";
import JoinOrganizationScreen from "./components/screens/Organizations/JoinOrganizationScreen";
import InvitationRespondScreen from "./components/screens/Organizations/InvitationRespondScreen";
import CreateOrganizationScreen from "./components/screens/Organizations/CreateOrganizationScreen";
import OrganizationAdminScreen from "src/components/screens/Organizations/OrganizationAdminScreen";
import OrganizationUserEvents from "src/components/screens/Organizations/Admin/OrganizationUserEvents";

// events
import MemberReservationScreen from "src/components/screens/Events/MemberReservationScreen";
import AdminManageReservationsScreen from "src/components/screens/Events/AdminManageReservationsScreen";
import MyEventsScreen from "src/components/screens/Events/MyEventsScreen";
import moment from "moment";

class App extends React.Component {

  public render() {
    return (
      <Provider store={store}>
        <Router>
          <StripeElementsContainer>
          <Menu>
            <div className="container-fluid">
              <Nav />
              <div>
                <div className="row" style={{marginTop: 20}}>
                  <div className="col-10 offset-1 justify-content-center">
                    <main>
                      <Switch>
                        <Route exact={true} path="/" component={Home} />
                        <Route exact={true} path="/terms" component={Terms} />
                        <Route exact={true} path="/login" component={Login} />
                        <Route exact={true} path="/signup" component={Signup} />
                        <Route exact={true} path="/verify" component={SignupVerify} />
                        <Route exact={true} path="/reset" component={ResetPassword} />
                        <Route exact={true} path="/reset/verify" component={ResetPasswordVerify} />
                        <Route exact={true} path="/dashboard" component={Dashboard} />


                        <Route exact={true} path="/me" component={ProfileScreen} />
                        <Route exact={true} path="/events" component={MyEventsScreen} />
                        <Route exact={true} path="/subaccounts" component={SubaccountScreen} />

                        <Route exact={true} path="/organizations" component={OrganizationsList} />
                        <Route exact={true} path="/organizations/create" component={CreateOrganizationScreen} />
                        <Route exact={true} path="/organizations/join" component={JoinOrganizationScreen} />
                        <Route exact={true} path="/organizations/:id/invites/respond" component={InvitationRespondScreen} />
                        <Route exact={true} path="/organizations/:id/admin" component={OrganizationAdminScreen} />
                        <Route exact={true} path="/organizations/:id/admin/:view" component={OrganizationAdminScreen} />
                        <Route exact={true} path="/organizations/:id" component={OrganizationViewScreen} />
                        <Route exact={true} path="/organizations/:id/users/:userId/events" component={OrganizationUserEvents} />


                        <Route exact={true} path="/organizations/:organizationId/events/:templateId/instances/:instanceId/register" component={MemberReservationScreen} />

                        
                        <Route exact={true} path="/organizations/:organizationId/admin/events/:templateId/instances/:instanceId" component={AdminManageReservationsScreen} />
                        

                      </Switch>
                    </main>
                  </div>
                </div>

                <footer>
                  <div className="row" style={{marginTop: 20}}>
                    <div className="col-12" style={{ "textAlign": "center" }}>
                      <p className="small">Copyright 2020 - {moment().format("YYYY")} <a target="_new" href="https://treelightsoftware.com">Treelight Software Inc</a></p>
                      <p className="small">Use of this application constitutes agreement with our <a href="/terms" target="_new">Terms of Service and Privacy Policy</a></p>
                    </div>
                  </div>
                </footer>
              </div>


              <ReduxToastr
                timeOut={3000}
                newestOnTop={false}
                preventDuplicates={true}
                position="top-center"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar={true} />
            </div>
          </Menu>
          </StripeElementsContainer>
        </Router>
      </Provider>
    );
  }
}

export default App;
