import * as React from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";

interface IStarterProps {
  appActions: any;
  appState: any;
  history: any;
}

interface IStarterState {
  loading: boolean;
}

class Starter extends React.Component<IStarterProps, IStarterState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

  }

  public render() {
    return (
      <div>
        
        <Modal show={this.props.appState.showWelcomeModal} onHide={this.props.appActions.toggleWelcomeModal} dialogClassName="modal-50">
          <Modal.Header closeButton={true}>
            <Modal.Title>Welcome!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <h2>Welcome to TLSchedule!</h2>
                <p>This dialog will explain some of the concepts of TLSchedule. You can choose to show or hide tips in the top navigation bar, which are useful when first navigating the app.</p>
              </div>
              <div className="col-12">
                <h3>Concepts</h3>
                <p>
                  <strong>Subaccounts</strong> - Subaccounts are people that you want to manage. They will not have their own login and will exist simply for you to place them in a reservation. For example, if you wanted to register your child for a class, you would want to create a "subaccount" for that child so that their name would appear on the registration.
                </p>
                <p>
                  <strong>Groups / Organizations</strong> - Groups (sometimes called Organizations) are organizations that create events and users can join. To join a group, you either need a Join Code (provided by the organization) or an Invitation (sent by the group). Once accepted into the group, you may make reservations for their events!
                </p>
                <p>
                  <strong>Events and Instances</strong> - An event is something that will happen. For example, a Taekwondo school may have an event called "Afternoon Class". There may be one or more "instances" of that class. For example, that class may run at 4:00 PM and 5:00 PM every weekday. Users register for those specific instances. Events may have rules, such as "only one reservation for this event type per user per day" or "only two reservations for this event type per user per week".
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-secondary" onClick={this.props.appActions.toggleWelcomeModal} >Close</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Starter) as any);