import * as React from "react";

interface IEventsScreenTemplateListItemProps {
  item: any;
  selectTemplate: any;
  editTemplate: any;
  deleteTemplate: any;
}

interface IEventsScreenTemplateListItemState {
  loading: boolean;
}

export default class EventsScreenTemplateListItem extends React.Component<IEventsScreenTemplateListItemProps, IEventsScreenTemplateListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };
    this.selectTemplate = this.selectTemplate.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);

  }

  public render() {
    return (
      <div className="row" key={this.props.item.id} style={{marginBottom: 15}}>
        <div className="col-lg-6 col-12">
          <span className="fake-link" onClick={this.selectTemplate}>{this.props.item.name}</span>
        </div>
        <div className="col-lg-4 col-8">
          {this.props.item.status}
        </div>
        <div className="col-lg-1 col-2">
          <span className="oi oi-pencil icon icon-primary" title="Edit Template" onClick={this.editTemplate} />
        </div>
        <div className="col-lg-1 col-2">
          <span className="oi oi-x icon icon-danger" title="Delete Template" onClick={this.deleteTemplate} />
        </div>
      </div>
    );
  }


  private selectTemplate(){
    this.props.selectTemplate(this.props.item);
  }

  private editTemplate(){
    this.props.editTemplate(this.props.item);
  }

  private deleteTemplate(){
    this.props.deleteTemplate(this.props.item);
  }

}