import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import moment from "moment";

import Card from "src/components/structure/Card";
import DatePicker from "src/components/structure/DatePicker";
import * as AppActions from "src/reducers/appReducer";
import { IOrganization } from "src/api/organizations";
import { EventsAPI } from "src/api";
import { IEventReservation } from "src/api/events";

interface IAdminEventScheduleProps {
  appActions: any;
  organization: IOrganization;
}

interface IAdminEventScheduleState {
  loading: boolean;
  reservations: IEventReservation[];
  reservationsByDay: any;
  view: "list" | "calendar";
  start: moment.Moment;
  end: moment.Moment;
}

class AdminEventSchedule extends React.Component<IAdminEventScheduleProps, IAdminEventScheduleState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      reservations: [],
      reservationsByDay: {},
      view: "list",
      start: moment(),
      end: moment().add(7, "days"),
    };

    this.setEndDate = this.setEndDate.bind(this);
    this.setStartDate = this.setStartDate.bind(this);


    this.fetchReservations = this.fetchReservations.bind(this);
    this.formatAsList = this.formatAsList.bind(this);
    this.displayList = this.displayList.bind(this);

  }

  componentDidMount(){
    this.fetchReservations();
  }

  public render() {
    return (
        <div className="row">
        <div className="col-md-12 col-lg-8 offset-2">
          <Card title="Schedule" loading={this.state.loading} help="">
            {this.state.view === "list" && (this.displayList())}
          </Card>
        </div>
      </div>
    );
  }

  private displayList(){
    return (
      <div>
        <div className="row" style={{marginBottom: 20}}>
          <div className="col-md-6">
            <label>Showing from </label>
            <DatePicker date={this.state.start} onDateSaved={this.setStartDate} />
          </div>
          <div className="col-md-6">
            <label>To </label>
            <DatePicker date={this.state.end} onDateSaved={this.setEndDate} />
          </div>
        </div>
        {Object.keys(this.state.reservationsByDay).map((day) => {
          const r = this.state.reservationsByDay[day];
          return (
            <div className="row" key={r.display} style={{marginBottom: 25}}>
              <div className="col-12">
                <p style={{fontWeight: "bold", fontSize: 24, marginBottom: 5}}>{r.display}</p>
                {r.reservations.length === 0 && (<div className="row"><div className="col-11 offset-1"><strong>NONE</strong></div></div>)}
                {r.reservations.map((res: IEventReservation) => {
                  const link = `/organizations/${this.props.organization.id}/admin/events/${res.eventTemplateId}/instances/${res.eventInstanceId}`;
                  return (
                    <div className="row" key={res.id} style={{marginBottom: 10}}>
                      <div className="col-1" />
                      <div className="col-2">
                        <Link to={link}>
                          {res.instanceStartTime.format("hh:mm A")}
                        </Link>
                      </div>
                      <div className="col-6">
                        <Link to={link}>
                          {`${res.participantFirstName} ${res.participantLastName}`} {res.userId !== res.participantId && (`(${res.userFirstName} ${res.userLastName})`)}
                        </Link>
                      </div>
                      <div className="col-3">
                        <Link to={link}>
                          {res.eventName}
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  private fetchReservations(){
    this.setState({loading: true}, async () => {
      try{
        const resResult = await EventsAPI.getOrganizationReserverations(this.props.organization.id);
        const rawReservations: IEventReservation[] = [];
        for(const r of resResult.body.data){
          r.instanceStartTime = moment(r.instanceStartTime);
          r.requestedOn = moment(r.requestedOn);
          r.updatedOn = moment(r.updatedOn);

          rawReservations.push(r);
        }
        const days = this.formatAsList(rawReservations);
        this.setState({loading: false, reservations: rawReservations, reservationsByDay: days});
      }catch(err){ this.setState({loading: false})}
    });
  }

  private formatAsList(input: IEventReservation[]){
    // start at current day, create object with by day
    // shape is 
    // { "YYYY-MM-DD": {"display": "Monday, MM/DD", reservations: []}}
    let days: any = {};
    const start = this.state.start.clone().hour(0).minute(0);
    const end = this.state.end.clone().hour(23).minute(59);

    while(start.isBefore(end)){
      days[start.format("YYYY-MM-DD")] = { display: start.format("ddd MM/DD/YY"), reservations: []}
      start.add(1, "day");
    }
    // loop over input
    for(const r of input){
      const startTime = r.instanceStartTime.format("YYYY-MM-DD");
      // I hate utc
      if(days[startTime]){
        days[startTime].reservations.push(r);
      }
    }
    return days;
  }

  private setStartDate(newDate: moment.Moment){
    this.setState({start: newDate}, () => this.fetchReservations());
  }

  private setEndDate(newDate: moment.Moment){
    this.setState({end: newDate}, () => this.fetchReservations());
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEventSchedule);