import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";

interface IScreenProps {
  appActions: any;
  appState: any;
  userState: any;
  children: any;
  fileName?: string;
  information?: string;

}

interface IScreenState {}

class Screen extends React.Component<IScreenProps, IScreenState> {

  constructor(props: any){
    super(props);
    this.state = {};

  }

  public render() {
    return (
      <div>
        {this.props.appState.debug && this.props.userState && this.props.userState.loggedIn && this.props.userState.user.systemRole === 'admin' && 
          <div className="debugWindow">
            {this.props.fileName ? (<div>{`File name: ${this.props.fileName}`}</div>) : null}
            {this.props.information ? (<div>{`Additional Info: ${this.props.information}`}</div>)  : null}
          </div>}
        {this.props.children}
      </div>
    );
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);