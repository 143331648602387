import * as React from "react";

interface IPlansDisplayProps {
  currentPlanId: number;
  selectPlan?: any;
  toggleConfirmCancelModal?: any;
}

interface IPlansDisplayState {
  loading: boolean;
}

export default class PlansDisplay extends React.Component<IPlansDisplayProps, IPlansDisplayState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

  }

  public render() {
    return (
      <div id="plans-display">
        <div className="form-group">
              <div className="table-responsive">
                <table className="table" style={{width: "100%"}}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Starter</th>
                      <th>Professional - Monthly</th>
                      <th>Professional - Annual</th>
                      <th>Enterprise</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Instances</td>
                      <td>200</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Members</td>
                      <td>25</td>
                      <td>500</td>
                      <td>500</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Archival Days</td>
                      <td>60</td>
                      <td>365</td>
                      <td>365</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Price</td>
                      <td>Free!</td>
                      <td>$4.99 / month</td>
                      <td>$99.99 / year</td>
                      <td></td>
                    </tr>
                    {this.props.currentPlanId > 0 && (
                      <tr>
                        <td />
                        <td>{this.props.currentPlanId === 1 ? (<div></div>) : (<button className="btn btn-block btn-danger" onClick={this.props.toggleConfirmCancelModal}>Cancel</button>)}</td>
                        <td>{this.props.currentPlanId === 2 ? (<div></div>) : (<button className="btn btn-block btn-success" onClick={() => this.props.selectPlan(2)}>Select</button>)}</td>
                        <td>{this.props.currentPlanId === 3 ? (<div></div>) : (<button className="btn btn-block btn-success" onClick={() => this.props.selectPlan(3)}>Select</button>)}</td>
                        <td>Contact us!</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
      </div>
    );
  }

}