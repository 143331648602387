import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";

import Screen from "src/components/structure/Screen";
import Card from "src/components/structure/Card";
import DatePicker from "src/components/structure/DatePicker";
import * as AppActions from "src/reducers/appReducer";
import * as EventActions from "src/reducers/eventReducers";
import { EventsAPI, OrganizationsAPI } from "src/api";
import { IOrganization } from "src/api/organizations";
import { IEventTemplate, EventTemplateBlank, IEventInstance, EventInstanceBlank } from "src/api/events";
import { error, success } from "src/components/structure/Alert";

import EventsScreenTemplateList from "./EventsScreenTemplateList";
import EventInstanceCreateModal from "./EventInstanceCreateModal";
import EventInstanceLineItem from "./EventInstanceListItem";
import AdminManageReservationsScreen from "../../Events/AdminManageReservationsScreen";


const localizer = momentLocalizer(moment);

interface IEventsScreenProps {
  appActions: any;
  organization: IOrganization;
  history: any;
  eventState: any;
  eventActions: any;
  organizationUpdated: any;
}

interface IEventsScreenState {
  loading: boolean;
  loadingSettings: boolean;
  templates: IEventTemplate[];
  showMode: "list" | "calendar";

  instances: IEventInstance[];

  showCreateInstancesModal: boolean;

  start: moment.Moment;
  end: moment.Moment;
  minHourForCalendar: Date;
  maxHourForCalendar: Date;

  windowWidth: number;
  windowHeight: number;

  organizationLimitSelector: "limited" | "unlimited";
  organizationLimit: number;

  selectedInstance: IEventInstance;
  showEditInstanceModal: boolean;

  instanceUpdateKey: number;
}

class EventsScreen extends React.Component<IEventsScreenProps, IEventsScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      loadingSettings: false,
      templates: [],
      instances: [],
      showMode: "calendar",

      showCreateInstancesModal: false,

      start: moment(),
      end: moment().add(7, "day").hour(23).minute(59),
      minHourForCalendar: new Date(2020, 1, 1, 7),
      maxHourForCalendar: new Date(2020, 1, 1, 23),

      // the below is for the calendar to decide what view to start on
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,

      organizationLimitSelector: "unlimited",
      organizationLimit: props.organization.maximumReservationsPerUserPerWeek ? props.organization.maximumReservationsPerUserPerWeek : 0,
    
      selectedInstance: EventInstanceBlank,
      showEditInstanceModal: false,

      instanceUpdateKey: 0,
    };

    this.fetchTemplates = this.fetchTemplates.bind(this);

    this.toggleShowCreateInstancesModal = this.toggleShowCreateInstancesModal.bind(this);
    this.toggleShowEditModal = this.toggleShowEditModal.bind(this);

    this.handleTemplateDeleted = this.handleTemplateDeleted.bind(this);
    this.handleTemplateCreated = this.handleTemplateCreated.bind(this);
    this.handleTemplateUpdated = this.handleTemplateUpdated.bind(this);

    this.handleInstancesCreated = this.handleInstancesCreated.bind(this);
    this.handleInstanceDeleted = this.handleInstanceDeleted.bind(this);
    this.handleInstanceEdited = this.handleInstanceEdited.bind(this);

    this.updateField = this.updateField.bind(this);
    this.updateFieldAsNumber = this.updateFieldAsNumber.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.updateShowMode = this.updateShowMode.bind(this);
    this.getListOrCalendarComponent = this.getListOrCalendarComponent.bind(this);
    this.handleInstanceSelected = this.handleInstanceSelected.bind(this);
    this.calendarDateChanges = this.calendarDateChanges.bind(this);

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateOrganizationSettings = this.updateOrganizationSettings.bind(this);
  }

  componentDidMount() {
    // we want to look at the state here
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const eventState = this.props.eventState;
    const start = eventState && eventState.upcomingEventsAdminScreenStart ? moment(eventState.upcomingEventsAdminScreenStart) : moment();
    const end = eventState && eventState.upcomingEventsAdminScreenEnd ? moment(eventState.upcomingEventsAdminScreenEnd) : moment().add(7, "days").hour(23).minute(59);
    const organizationLimit = this.props.organization.maximumReservationsPerUserPerWeek ? this.props.organization.maximumReservationsPerUserPerWeek : 0;
    const organizationLimitSelector = organizationLimit === 0 ? "unlimited" : "limited";
    this.setState({ start, end, organizationLimit, organizationLimitSelector }, () => this.fetchTemplates());
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  public render() {
    return (
      <Screen fileName="Organizations/Admin/EventsScreen.tsx">
        <div className="row">
          <div className={"col-lg-4 col-md-12"}>
          <div className="row">
              <div className="col-12">
                <EventsScreenTemplateList
                  templates={this.state.templates}
                  organization={this.props.organization}
                  onTemplateCreated={this.handleTemplateCreated}
                  onTemplateDeleted={this.handleTemplateDeleted}
                  onTemplateUpdated={this.handleTemplateUpdated}
                  onTemplateViewToggle={() => {}}
                />
              </div>
            </div>
            <div className="row" style={{marginTop: 20}}>
              <div className="col-12">
                <Card title="Organizational Settings" loading={this.state.loading || this.state.loadingSettings}>
                  <div className="form-group">
                    <label>Maximum reservations per week per member:</label>
                    <select className="form-control" id="organizationLimitSelector" value={this.state.organizationLimitSelector} onChange={this.updateField}>
                      <option value="unlimited">Unlimited, per instance rules</option>
                      <option value="limited">Limited to:</option>
                    </select>
                    {this.state.organizationLimitSelector === "limited" && (
                      <input type="number" className="form-control" id="organizationLimit" value={this.state.organizationLimit} onChange={this.updateFieldAsNumber} />
                    )}
                  </div>
                  <div className="form-group">
                    <button className="btn btn-block btn-primary" onClick={this.updateOrganizationSettings}>Update</button>
                  </div>
                </Card>
              </div>
            </div>            
          </div>

          <div className="col-lg-8 col-md-12">
            <Card title="Instances" loading={this.state.loading && !(this.state.showMode === "calendar")} help="">
              <div className="row" style={{ marginBottom: 20 }}>
                <div className="col-md-12">
                  <button className="btn btn-block btn-primary" onClick={this.toggleShowCreateInstancesModal}>Create Instances</button>
                </div>
              </div>
              {this.getListOrCalendarComponent()}
            </Card>
          </div>          

          <EventInstanceCreateModal
            templates={this.state.templates}
            show={this.state.showCreateInstancesModal}
            organization={this.props.organization}
            hide={this.toggleShowCreateInstancesModal}
            onInstancesCreated={this.handleInstancesCreated} />
        </div>
      </Screen>
    );
  }

  private getListOrCalendarComponent() {
    if (this.state.showMode === "calendar") {
      return (
        <div>
          <div className="row" style={{ marginBottom: 15 }}>
            <div className="col-lg-12 col-sm-12">
              <label>Show As</label>
              <select id="showMode" className="form-control" value={this.state.showMode} onChange={this.updateShowMode}>
                <option value="list">List</option>
                <option value="calendar">Calendar</option>
              </select>
            </div>
          </div>
          <div className="row" style={{ marginBottom: 15 }}>
            <div className="col-lg-3 col-sm-12">
              <div className="schedule-instance-is-available calendar-legend">
                Available
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="schedule-instance-is-full calendar-legend">
                Full
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="schedule-instance-is-registered calendar-legend">
                Active Reservation
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="schedule-instance-is-pending calendar-legend">
                Pending Availability
              </div>
            </div>
          </div>
          <div style={{ height: 800 }}>
            <Calendar
              key={this.state.instanceUpdateKey}
              date={this.state.start.toDate()}
              localizer={localizer}
              events={this.state.instances}
              startAccessor="startTimeDate"
              endAccessor="endTimeDate"
              defaultView={this.state.windowWidth < 600 ? "day" : "week"}
              views={["week", "day"]}
              min={this.state.minHourForCalendar}
              max={this.state.maxHourForCalendar}
              step={10}
              onNavigate={this.calendarDateChanges}
              eventPropGetter={this.eventPropGetter}
              onSelectEvent={this.handleInstanceSelected}
            />
          </div>
          <AdminManageReservationsScreen 
            onHide={this.toggleShowEditModal}
            organization={this.props.organization}
            templateId={this.state.selectedInstance.eventTemplateId}
            instance={this.state.selectedInstance}
            onEditSaved={this.handleInstanceEdited}
            onInstanceDeleted={this.handleInstanceDeleted}
            show={this.state.showEditInstanceModal} />
        </div>
      );
    }
    return (
      <div>
        <div className="row">
          <div className="col-lg-4 col-sm-4">
            <label>Showing Instances Between</label>
            <DatePicker date={this.state.start} onDateSaved={this.updateStart} />
          </div>
          <div className="col-lg-4 col-sm-4">
            <label>And</label>
            <DatePicker date={this.state.end} onDateSaved={this.updateEnd} />
          </div>
          <div className="col-lg-4 col-sm-4">
            <label>Show As</label>
            <select id="showMode" className="form-control" value={this.state.showMode} onChange={this.updateShowMode}>
              <option value="list">List</option>
              <option value="calendar">Calendar</option>
            </select>
          </div>
        </div>

        {this.state.instances.length === 0 && (<strong>No instances exist in that timeframe</strong>)}
        {this.state.instances.map((instance) => {
          return (
            <EventInstanceLineItem
              key={instance.id}
              organization={this.props.organization}
              template={EventTemplateBlank}
              instance={instance}
              onDeleteInstance={this.handleInstanceDeleted} />
          )
        })}
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateFieldAsNumber(e: any){
    const ns = this.state;
    ns[e.target.id] = parseInt(e.target.value, 10);
    this.setState(ns);
  }

  private fetchTemplates() {
    this.setState({ loading: true }, async () => {
      try {
        const templatesResult = await EventsAPI.getEventTemplates(this.props.organization.id);
        this.setState({ templates: templatesResult.body.data }, () => {
          this.fetchInstances();
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    })
  }

  private handleTemplateDeleted(template: IEventTemplate){
    const templates: IEventTemplate[] = [];
    for(const t of this.state.templates){
      if(template.id !== t.id){
        templates.push(t);
      }
    }
    // since the instance was deleted, nuke the instances
    this.setState({ templates }, () => {
      this.fetchInstances();
    });
  }

  private handleTemplateCreated(template: IEventTemplate){
    const templates: IEventTemplate[] = this.state.templates;
    templates.push(template);
    templates.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    })
    this.setState({ templates });
  }


  private handleTemplateUpdated(template: IEventTemplate){
    const templates: IEventTemplate[] = [];
    for(const t of this.state.templates){
      if(template.id === t.id){
        templates.push(template);
      } else {
        templates.push(t);
      }
    }
    // since the instance was updated, they will have new names
    this.setState({ templates }, this.fetchInstances);
  }

  private toggleShowCreateInstancesModal() {
    this.setState({ showCreateInstancesModal: !this.state.showCreateInstancesModal });
  }

  private toggleShowEditModal(){
    this.setState({ showEditInstanceModal: !this.state.showEditInstanceModal});
  }

  private fetchInstances() {
    this.setState({ loading: true }, async () => {
      const instances: IEventInstance[] = [];
      let min = 23;
      let max = 0;
      try {
        const data = {
          start: this.state.start.format("YYYY-MM-DDT00:00:00Z"),
          end: this.state.end.format("YYYY-MM-DDT23:59:00Z"),
        }
        const instancesResult = await EventsAPI.getInstancesForOrganization(this.props.organization.id, data.start, data.end, data);
        const result: IEventInstance[] = instancesResult.body.data;

        for (const instance of result) {
          // TODO: can we take this logic and put it somewhere centralized for re-use?
          // maybe put it on the server?

          instance.startTime = moment(instance.startTime);
          instance.startTimeDate = instance.startTime.toDate();
          const et = moment(instance.startTime).clone().add(instance.numberOfMinutes, "minutes");
          instance.endTimeDate = et.toDate();

          if (instance.startTime.hour() < min) {
            min = instance.startTime.hour();
          }
          if (et.hour() > max) {
            max = et.clone().add(1, "hour").hour();
          }

          // we need to figure out whether there are slots available on this
          instance.adminCanAnotherRegister = (instance.uniqueUserReservations || 0) < (instance.maximumReservationsPerInstance || 100);
          let title = instance.eventName;
          if(instance.status === "pending"){
            title += " (Pending)";
          } else{
            if(instance.adminCanAnotherRegister){
              title += " (Available)";
            } else {
              title += " (Full)";
            }
          }
          instance.title = title;
          instance.startTime = moment(instance.startTime);
          instances.push(instance);
        }

      } catch (err) {
      } finally {
        const minHourForCalendar = new Date(2020, 1, 1, min)
        const maxHourForCalendar = new Date(2020, 1, 1, max)
        const newKey = this.state.instanceUpdateKey + 1;
        this.setState({ loading: false, instances, minHourForCalendar, maxHourForCalendar, instanceUpdateKey: newKey });
      }
    })
  }


  private updateStart(newDate: moment.Moment) {
    newDate.hour(0).minute(0).seconds(0);
    this.props.eventActions.setUpcomingEventsAdminScreenDates({ which: "start", newDate });
    this.setState({ start: newDate }, () => this.fetchInstances());
  }

  private updateEnd(newDate: moment.Moment) {
    newDate.hour(23).minute(59).seconds(59);
    this.props.eventActions.setUpcomingEventsAdminScreenDates({ which: "end", newDate });
    this.setState({ end: newDate }, () => this.fetchInstances());
  }

  private updateShowMode(e: any) {
    const val = e.target.value;
    this.setState({ showMode: val }, () => this.fetchInstances());
  }


  private calendarDateChanges(info: any) {
    const selectedDay = moment(info);
    const currentSetDay = this.props.eventState.upcomingEventsAdminScreenStart ? moment(this.props.eventState.upcomingEventsAdminScreenStart) : moment();
    const diff = Math.abs(currentSetDay.diff(selectedDay, "days"));
    let instanceStart = moment();
    let instanceEnd = moment();
    if(diff === 1){
      // day view
      instanceStart = moment(info).hour(0).utc();
      instanceEnd = moment(info).hour(23).minute(59).utc();
    } else {
      // week view
      instanceStart = moment(info).day("Sunday").utc();
      instanceEnd = moment(info).day("Saturday").hour(23).minute(59).utc();
    }
    
    this.props.eventActions.setUpcomingEventsAdminScreenDates({ which: "start", newDate: instanceStart });
    this.props.eventActions.setUpcomingEventsAdminScreenDates({ which: "end", newDate: instanceEnd });
    this.setState({
      start: instanceStart,
      end: instanceEnd
    }, () => this.fetchInstances());
  }

  private eventPropGetter(event: IEventInstance) {
    let className = "";
    if (event.adminCanAnotherRegister) {
      className = "schedule-instance-is-available";
    } else {
      className = "schedule-instance-is-full";
    }
    // if it's in the past, set it to cannot register
    const startTime = moment(event.startTime);
    if (startTime.isBefore(moment())) {
      className = "schedule-instance-cannot-register";
    } else {
      // it's in the future; if it is pending, we mark it as such
      if (event.status === "pending") {
        className = "schedule-instance-is-pending";
      }
    }
    return {
      className
    };
  }

  private handleInstanceSelected(selectedInstance: IEventInstance) {
    this.setState({
      selectedInstance,
      showEditInstanceModal: true,
    });
  }

  private handleInstancesCreated() {
    this.setState({ loading: true, showCreateInstancesModal: false }, () => {
      this.fetchInstances();
    });
  }

  private handleInstanceDeleted(instance: IEventInstance) {
    // const instances: IEventInstance[] = [];
    // for (const i of this.state.instances) {
    //   if (i.id !== instance.id) {
    //     instances.push(i);
    //   }
    // }
    this.setState({ selectedInstance: EventInstanceBlank, showEditInstanceModal: false }, () => {
      this.fetchInstances();
    });
  }

  private handleInstanceEdited(instanceId: number, newData: any){
    console.log("Edited");
    console.log(instanceId);
    console.log(newData);
    // const instances: IEventInstance[] = [];
    // for (const i of this.state.instances) {
    //   if (i.id === instanceId) {
    //     i.eventTemplateId = newData.eventTemplateId;
    //     i.startTime = newData.startTime;
    //     i.numberOfMinutes = newData.numberOfMinutes;
    //   }
    //   instances.push(i);
    // }
    
    this.setState({ selectedInstance: EventInstanceBlank, showEditInstanceModal: false }, () => {
      this.fetchInstances();
    });
  }

  private updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  private updateOrganizationSettings(){
    this.setState({ loadingSettings: true }, async () => {
      try{
        const updateData = {
          maximumReservationsPerUserPerWeek: this.state.organizationLimitSelector === "unlimited" ? -1 : this.state.organizationLimit
        }
        await OrganizationsAPI.updateOrganization(this.props.organization.id, updateData);
        success("Organization settings saved.");
        this.setState({ loadingSettings: false }, () => {
          const organization: IOrganization = {
            ...this.props.organization,
            maximumReservationsPerUserPerWeek: updateData.maximumReservationsPerUserPerWeek >= 0 ? updateData.maximumReservationsPerUserPerWeek : 0,
          }
          this.props.organizationUpdated(organization);
        });
      }catch(err){
        error("Could not save your organization settings.");
        this.setState({ loadingSettings: false });
      }
    })
  };

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    eventState: s.eventState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    eventActions: bindActionCreators(EventActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsScreen);