//
// This handles the reservations from the user's side. It's a bit complicated because it can either be access from a page (such as when linking from the dashboard) OR in a modal
// (such as when linking from the calendar). The page version won't send the details via props, so the match is needed and the callback needs to be ignored.
//

import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import AddToCalendar from "react-add-to-calendar";

import Card from "src/components/structure/Card";
import MarkdownEditor from "src/components/structure/MarkdownEditor";
import { error, success } from "src/components/structure/Alert";
import * as AppActions from "src/reducers/appReducer";
import { IOrganization, OrganizationBlank } from "src/api/organizations";
import { IEventTemplate, IEventInstance, EventTemplateBlank, EventInstanceBlank, IEventReservation, EventReservationBlank } from "src/api/events";
import { OrganizationsAPI, EventsAPI, UserAPI } from "src/api";
import { IUser, ISubaccount, UserBlank } from "src/api/user";
import { Translator } from "src/utils/translator";

import "react-add-to-calendar/dist/react-add-to-calendar.css";

const reservationHelp = Translator.getHelpText("en", "member_instance_reserve");

interface IMemberReservationScreenProps {
  appActions: any;
  history: any;
  match: any;
  userState: any;
  organizationId: number;
  templateId: number;
  instanceId: number;

  onChange: any;
}

interface IMemberReservationScreenState {
  loading: boolean;
  user: IUser;
  registerLoading: boolean;
  organization: IOrganization;
  template: IEventTemplate;
  instance: IEventInstance;
  existingReservations: IEventReservation[];
  subaccounts: ISubaccount[];
  showCancelReservationModal: boolean;

  selectedReservation: IEventReservation;
  cancelReason: string;
  cancelMessage: string;

  hasReservation: boolean;
  calendarEvent: any;
}

class MemberReservationScreen extends React.Component<IMemberReservationScreenProps, IMemberReservationScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      user: UserBlank,
      registerLoading: false,
      organization: OrganizationBlank,
      template: EventTemplateBlank,
      instance: EventInstanceBlank,
      existingReservations: [],
      subaccounts: [],
      showCancelReservationModal: false,
      selectedReservation: EventReservationBlank,

      cancelReason: "other",
      cancelMessage: "",
      hasReservation: false,
      calendarEvent: null,
    };

    this.initialize = this.initialize.bind(this);
    this.toggleCancelReservationModal = this.toggleCancelReservationModal.bind(this);
    this.cancelReservation = this.cancelReservation.bind(this);
    this.requestReservation = this.requestReservation.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  componentDidMount() {
    this.initialize();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-lg-3 col-sm-12">
          <Card title="Event Information" loading={this.state.loading} help="" style={{marginBottom: 15}}>
            <h2>{this.state.organization.name}</h2>
            <h3>{this.state.template.name}</h3>
            <p style={{ fontWeight: "bold" }}>
              Starts: {moment(this.state.instance.startTime).format("MM/DD/YY hh:mm A")}<br />
              Ends: {moment(this.state.instance.startTime).clone().add(this.state.instance.numberOfMinutes, "minutes").format("MM/DD/YY hh:mm A")}
            </p>
            <p>
              Maximum Reservations Allowed: {this.state.template.maximumReservationsPerInstance}<br />
              {this.state.template.maximumReservationsPerInstanceRule !== "absolute" && (
                <span><em>Note: All users and subaccounts can join and will count as one reservation</em><br /></span>
              )}
              Total Reservations: {this.state.instance.currentReservations}<br />
            </p>
            <p>
              Each participant may register for {this.state.template.maximumReservationsPerUserPerDay} instances each day and {this.state.template.maximumReservationsPerUserPerWeek} each week.
            </p>

          </Card>
        </div>
        {this.state.template.description !== "" && (
          <div className="col-lg-4 col-sm-12">
            <Card title="Description" loading={this.state.loading} help="">
              <MarkdownEditor content={this.state.template.description} mode="view" />
            </Card>
          </div>
        )}
        <div className="col-lg-5 col-sm-12">
          <Card title="Reservations" loading={this.state.loading} help={reservationHelp} style={{marginBottom: 15}}>
            <div className="row">
              <div className="col-md-4 col-12">
                {this.props.userState.user.firstName} {this.props.userState.user.lastName}
              </div>
              <div className="col-md-4 col-8">
                {this.state.user.reserved ? (<strong className="text-success">Reserved</strong>): (<strong className="text-danger">Not Reserved</strong>)}
              </div>
              <div className="col-md-2 col-4">
                {this.state.user.reserved ? 
                  (<span className="oi oi-x icon icon-danger" onClick={()=>{this.toggleCancelReservationModal(this.state.user.reservation)}} title="Delete" />): 
                  (<span className="oi oi-plus icon icon-success" onClick={() => {this.requestReservation(this.state.user.id, "user")}} title="Request" />)}
              </div>
            </div>

            {this.state.subaccounts.map((a) => {
              return (
                <div className="row" key={a.id}>
                  <div className="col-md-4 col-sm-12">
                    {a.firstName} {a.lastName}
                  </div>
                  <div className="col-md-4 col-sm-12">
                    {a.reserved ? (<strong className="text-success">Reserved</strong>): (<strong className="text-danger">Not Reserved</strong>)}
                  </div>
                  <div className="col-md-4 col-sm-12">
                    {a.reserved ? 
                      (<span className="oi oi-x icon icon-danger" onClick={()=>{this.toggleCancelReservationModal(a.reservation)}} title="Delete" />): 
                      (<span className="oi oi-plus icon icon-success"onClick={() => {this.requestReservation(a.id, "subaccount")}}  title="Request" />)}
                  </div>
                </div>
              );
            })}
                        
            {this.state.hasReservation && this.state.calendarEvent !== null && (
              <div className="row" style={{ marginTop: 20}}>
                <div className="col-12" style={{textAlign: "right"}}>
                  <AddToCalendar event={this.state.calendarEvent} />
                </div>
              </div>
            )}
          </Card>
        </div>

        <Modal show={this.state.showCancelReservationModal} onHide={this.toggleCancelReservationModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Cancel Reservation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <strong className="text-danger">Warning!</strong> This cannot be undone. There is a risk that by cancelling, you will not be able to attend if maximum capacity is reached. Are you sure you want to cancel this reservation for {this.state.selectedReservation.participantFirstName} {this.state.selectedReservation.participantLastName}?
            </div>
            <div className="form-group">
              <label>Reason for Cancellation</label>
              <select className="form-control" id="cancelReason" onChange={this.updateField} value={this.state.cancelReason}>
                <option value="accidental_reservation">Accidental Reservation</option>
                <option value="time_conflict">Time Conflict</option>
                <option value="better_time_slot">I Found a Better Time Slot </option>
                <option value="no_longer_interested">No Longer Interested</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label>Message (optional)</label>
              <textarea rows={3} id="cancelMessage" value={this.state.cancelMessage} className="form-control" onChange={this.updateField} />
            </div>
            
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.cancelReservation}>Cancel a Reservation</button>
            <button className="btn btn-block btn-default" onClick={() => this.toggleCancelReservationModal(EventReservationBlank)}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleCancelReservationModal(reservation: IEventReservation | undefined) {
    if(!reservation){
      reservation = EventReservationBlank;
    }
    this.setState({ showCancelReservationModal: !this.state.showCancelReservationModal, selectedReservation: reservation });
  }

  private initialize() {
    this.setState({ loading: true }, async () => {
      try {
        const user: IUser = this.props.userState.user;
        let orgId = this.props.organizationId;
        if(!orgId && this.props.match && this.props.match.params && this.props.match.params.organizationId){
          orgId = this.props.match.params.organizationId;
        }
        let tempId = this.props.templateId;
        if(!tempId && this.props.match && this.props.match.params && this.props.match.params.templateId){
          tempId = this.props.match.params.templateId;
        }
        let instId = this.props.instanceId;
        if(!instId && this.props.match && this.props.match.params && this.props.match.params.instanceId){
          instId = this.props.match.params.instanceId;
        }

        // get the organization
        const orResult = await OrganizationsAPI.getOrganization(orgId);

        // get the template
        const tempResult = await EventsAPI.getEventTemplate(orgId, tempId);

        // get the instance
        const instResult = await EventsAPI.getEventTemplateInstance(orgId, tempId, instId);

        // any reservations
        const resResult = await EventsAPI.getInstanceReservations(orgId, tempId, instId);

        // get any sub accounts
        const subResult = await UserAPI.getSubaccounts();

        const organization = orResult.body.data;
        const template = tempResult.body.data;
        const instance = instResult.body.data;
        const reservations = resResult.body.data;
        const subaccounts = subResult.body.data;
        user.reserved = false;
        for(const sa of subaccounts){
          sa.reserved = false;
        }

        let hasReservation = false;

        for(const r of reservations){
          // find the account
          if(r.participantType === "user" && r.participantId === user.id){
            user.reserved = true;
            user.reservation = r;
            hasReservation = true;
          } else {
            for(const sa of subaccounts){
              if(sa.id === r.participantId && r.participantType === "subaccount"){
                sa.reserved = true;
                sa.reservation = r;
                hasReservation = true;
              }
            }
          }
        }
        const event: any = {
          title: organization.name + " - " + template.name,
          description: template.description,
          location: template.locationCity !== "" && template.locationState !== "" ? template.locationCity + ", " + template.locationState : "",
          startTime: moment(instance.startTime).toDate(),
          endTime: moment(instance.startTime).add(instance.numberOfMinutes, "minutes").toDate(),
        }

        this.setState({
          loading: false,
          user,
          organization,
          template,
          instance,
          existingReservations: reservations,
          subaccounts,
          hasReservation,
          calendarEvent: event,
        });
      } catch (err) {
        // we should redirect them?
        console.log(err);
      }
    });

  }

  private cancelReservation(){
    this.setState({loading: true}, async () => {
      try{
        const reason = this.state.cancelReason;
        const message = this.state.cancelMessage.trim();
        await EventsAPI.cancelInstanceReservation(this.state.organization.id, this.state.template.id, this.state.instance.id, this.state.selectedReservation.id, {reason, message});
        success("We have cancelled that reservation! If you added it to an external calendar, you will need to remove it from there on your own.");
        // for now, just re-get everything
        // I am running out of time :(
        if(this.props.onChange){
          this.props.onChange(this.state.instance);
        }
        this.setState({ showCancelReservationModal: false, selectedReservation: EventReservationBlank, cancelMessage: "" }, () => {
          this.initialize();
        });
      }catch(err){
        this.setState({ showCancelReservationModal: false, loading: false }, () => {
          error("We could not cancel that reservation. Please contact support.")
        });        
      }
    })
  }

  private requestReservation(participantId: number, participantType: "user" | "subaccount"){
    this.setState({loading: true}, async () => {
      try{
        const data = {
          participantId,
          participantType,
        };
        await EventsAPI.createInstanceReservation(this.state.organization.id, this.state.template.id, this.state.instance.id, data);
        success("Reservation placed!");
        // for now, just re-get everything
        // I am running out of time :(
        if(this.props.onChange){
          this.props.onChange(this.state.instance);
        }
        this.setState({ showCancelReservationModal: false, selectedReservation: EventReservationBlank }, () => {
          this.initialize();
        });
      }catch(err){
        let errMsg = "";
        let errTitle = "";
        if(err.code === 409){
          if(err.body.data.code === "reservation_max_participant_day_reached"){
            errTitle = "Maximum Per Day Reached";
            errMsg = "The maximum number of reservations for that participant each day has been reached. You will need to cancel another reservation before requesting this one."
          }
          if(err.body.data.code === "reservation_max_participant_week_reached"){
            errTitle = "Maximum Per Week Reached";
            errMsg = "The maximum number of reservations for that participant each week has been reached. You will need to cancel another reservation before requesting this one."
          }
          if(err.body.data.code === "reservation_max_reached"){
            errTitle = "Instance Full";
            errMsg = "That instance is now full and cannot accept new reservations."
          }
          if(err.body.data.code === "reservation_max_participant_week_org_reached"){
            errTitle = "Group Maximum Per Week Reached";
            errMsg = `You have reserved the maximum reservations per week for that participant. The organization has specified that each user may only make ${this.state.organization.maximumReservationsPerUserPerWeek} reservations per week across all instances.`
          }
          
        }

        if(errMsg === ""){
          errMsg = "We could not place that reservation. Please refresh the page. It is possible the event reached its maximum capacity.";
        }
        error(errMsg, errTitle, {timeOut: 20000, className: "alert-large"});
        
        this.setState({loading: false});
      }
    })
  }


}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberReservationScreen) as any;