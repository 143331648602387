import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import moment from "moment";
import * as qs from "query-string";

import Card from "src/components/structure/Card";
import * as AppActions from "src/reducers/appReducer";
import { error, success } from "src/components/structure/Alert";
import { IOrganization, OrganizationBlank, IPendingInvitation } from "src/api/organizations";
import { IUser, UserBlank } from "src/api/user";
import { OrganizationsAPI } from "src/api";

import {InvitedUserListItem} from "./InvitedUserListItem";
import { MemberListItem } from "./MemberListItem";

interface IOrganizationMembersScreenProps {
  appActions: any;
  organization: IOrganization;
  userState: any;
  location: any;
}

interface IOrganizationMembersScreenState {
  loading: boolean;
  organization: IOrganization;
  invitations: any[];
  allUsers: any[];
  filteredUsers: any[];
  selectedInvitation: any;
  selectedUser: any;

  inviteEmail: string;

  showRemoveModal: boolean;
  showInviteModal: boolean;

  sortDir: "asc" | "desc";
  sortField: "firstName" | "role" | "status";
  filter: string;
  lastUpdate: number;

  highlightedUserId: number;
}

class OrganizationMembersScreen extends React.Component<IOrganizationMembersScreenProps, IOrganizationMembersScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      organization: OrganizationBlank,
      allUsers: [],
      filteredUsers: [],
      selectedUser: UserBlank,
      invitations: [],
      inviteEmail: "",
      selectedInvitation: UserBlank,
      showRemoveModal: false,
      showInviteModal: false,
      filter: "",

      sortDir: "asc",
      sortField: "firstName",
      lastUpdate: 0,

      highlightedUserId: 0,
    };

    this.updateField = this.updateField.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.fetch = this.fetch.bind(this);
    this.invite = this.invite.bind(this);
    this.handleDeleteInvitation = this.handleDeleteInvitation.bind(this);
    this.handleUpdateUser = this.handleUpdateUser.bind(this);
    this.handleRemoveUser = this.handleRemoveUser.bind(this);
    

    this.toggleShowInviteModal = this.toggleShowInviteModal.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
    this.toggleSort = this.toggleSort.bind(this);
  }

  componentDidMount(){
    const params = qs.parse(this.props.location.search);
    let highlightedUserId = 0;
    if(params && params.memberId && params.memberId !== "" && typeof params.memberId === "string"){
      try{
        highlightedUserId = parseInt(params.memberId);
      }catch(err){}
    }
    this.setState({organization: this.props.organization, highlightedUserId}, () => {
      this.fetch();
    });
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <Card title="Members" loading={this.state.loading} help="">
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  Filter:
                </div>
                <div className="col-lg-8 col-md-12">
                  <input id="filter" type="text" className="form-control" value={this.state.filter} onChange={this.updateFilter} />
                </div>
              </div>
              <div className="row list-row-header" style={{marginBottom: 10}}>
                <div className="col-lg-4 col-md-12">
                  <span className="cursor-link" onClick={() => this.toggleSort("firstName")}>Name</span>
                  {this.state.sortField === "firstName" && this.state.sortDir === "asc" && (<span className="oi oi-arrow-top icon sort-icon" />)}
                  {this.state.sortField === "firstName" && this.state.sortDir === "desc" && (<span className="oi oi-arrow-bottom icon sort-icon" />)}
                  
                </div>
                <div className="col-lg-3 col-md-12">
                  <span className="cursor-link" onClick={() => this.toggleSort("role")}>Role</span>
                  {this.state.sortField === "role" && this.state.sortDir === "asc" && (<span className="oi oi-arrow-top icon sort-icon" />)}
                  {this.state.sortField === "role" && this.state.sortDir === "desc" && (<span className="oi oi-arrow-bottom icon sort-icon" />)}
                </div>
                <div className="col-lg-3 col-md-12">
                  <span className="cursor-link" onClick={() => this.toggleSort("status")}>Status</span>
                  {this.state.sortField === "status" && this.state.sortDir === "asc" && (<span className="oi oi-arrow-top icon sort-icon" />)}
                  {this.state.sortField === "status" && this.state.sortDir === "desc" && (<span className="oi oi-arrow-bottom icon sort-icon" />)}
                </div>
                <div className="col-2" />
              </div>
              {this.state.filteredUsers.map((user: any) => {
                return (
                  <MemberListItem
                    key={user.id}
                    loggedInUser={this.props.userState.user} 
                    member={user} 
                    isHighlighted={this.state.highlightedUserId === user.id && user.status === "requested"}
                    organization={this.state.organization}
                    onRoleChange={this.handleUpdateUser}
                    onStatusChange={this.handleUpdateUser}
                    onRemoveUser={this.handleRemoveUser} />
                );
              })}
            </Card>
          </div>
          <div className="col-lg-5 col-md-12">
            <Card title="Invitations" loading={this.state.loading} help="">
              {this.state.invitations.length === 0 && (<strong>No pending invitations</strong>)}
              {this.state.invitations.map((invitation: IPendingInvitation) => {
                return (
                  <InvitedUserListItem key={invitation.email} invitation={invitation} onDeleteInvitation={this.handleDeleteInvitation} />
                )
              })}
              <div className="row" style={{marginTop:20}}>
                <div className="col-12">
                  <button className="btn btn-block btn-primary" onClick={this.toggleShowInviteModal}>Invite to Join</button>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <Modal show={this.state.showInviteModal} onHide={this.toggleShowInviteModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Remove User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Enter the email address of the user you would like to invite to join your group.</p>
            <input type="text" className="form-control" id="inviteEmail" value={this.state.inviteEmail} onChange={this.updateField} />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.invite}>Invite</button>
            <button className="btn btn-block btn-default" onClick={this.toggleShowInviteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateFilter(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns, () => {
      const filteredUsers = this.filterUsers(this.state.allUsers, this.state.sortField, this.state.sortDir);
      this.setState({filteredUsers});
    });
  }

  private fetch(){
    this.setState({ loading: true }, async () => {
      try{
        const orgResult = await OrganizationsAPI.getOrganization(this.props.organization.id);
        const allUsers = orgResult.body.data.users;
        let highlightedUser: any = { id: 0 };
        for(const u of allUsers){
          u.filterKey = `${u.firstName.toLowerCase()}${u.lastName.toLowerCase()}`;
          if(this.state.highlightedUserId !== 0 && this.state.highlightedUserId === u.id && u.status === "requested"){
            highlightedUser = u;
          }
        }
        let sortedUsers = this.filterUsers(allUsers, this.state.sortField, this.state.sortDir);
        // if there is a highlighted user id, we want to push that member to the top
        if(highlightedUser.id !== 0){
          // we need to loop through and remove it to avoid the key conflict
          sortedUsers = sortedUsers.filter((user) => {
            return user.id !== this.state.highlightedUserId;
          })
          sortedUsers = [highlightedUser, ...sortedUsers];
        }
        this.setState({ loading: false, organization: orgResult.body.data, allUsers, filteredUsers: sortedUsers }, () => this.fetchInvitations());
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

  private fetchInvitations(){
    this.setState({ loading: true }, async () => {
      try{
        const invitesResult = await OrganizationsAPI.getInvitations(this.props.organization.id);
        const invitations: IPendingInvitation[] = [];
        for(const i of invitesResult.body.data){
          i.requestedOn = moment(i.requestedOn);
          invitations.push(i);
        }
        // sort by email
        invitations.sort((a: IPendingInvitation, b: IPendingInvitation) => {
          return a.email > b.email ? 1 : -1;
        })
        this.setState({ loading: false, invitations, });
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

  private filterUsers(input: IUser[] = [], sortField: string = "admin", sortDir: string = "asc"): IUser[]{
    const filter = this.state.filter.toLowerCase();
    let sortedUsers: IUser[] = [];
    if(input.length === 0){
      input = this.state.allUsers;
    }
    // first, filter through the names, then we sort
    for(const user of input){
      if(user.filterKey && user.filterKey.indexOf(filter) > -1){
        sortedUsers.push(user);
      }
    }
    // for large lists, it's better to have a conditional that branches into
    // different sorts rather than putting the sort conditional inside the
    // sort function
    const sortSign = sortDir === "asc" ? 1 : -1;
    if(sortField === "firstName"){
      sortedUsers = sortedUsers.sort((a: IUser, b: IUser) => {
        if(a.firstName === b.firstName){
          return a.lastName > b.lastName ? 1 * sortSign : -1 * sortSign;
        }
        return a.firstName > b.firstName ? 1 * sortSign : -1 * sortSign;
      });
    }
    if(sortField === "role"){
      sortedUsers = sortedUsers.sort((a: IUser, b: IUser) => {
        if(a.organizationRole === b.organizationRole){
          return a.firstName > b.firstName ? 1 : -1;
        }
        return a.organizationRole > b.organizationRole ? 1 * sortSign : -1 * sortSign;
      });
    }
    if(sortField === "status"){
      sortedUsers = sortedUsers.sort((a: IUser, b: IUser) => {
        if(a.status === b.status){
          return a.firstName > b.firstName ? 1 : -1;
        }
        return a.status > b.status ? 1 * sortSign : -1 * sortSign;
      });
    }
    return sortedUsers;
  }

  private toggleShowInviteModal(){
    this.setState({showInviteModal: !this.state.showInviteModal});
  }

  private toggleSort(sortField: string){
    const ns: IOrganizationMembersScreenState = this.state;
    let currentSortField = this.state.sortField;
    let currentSortDir = this.state.sortDir;

    if(sortField === currentSortField){
      ns.sortDir = currentSortDir === "asc" ? "desc" : "asc";
    } else if (sortField === "firstName" || sortField === "role" || sortField === "status") {
      ns.sortField = sortField;
    } else {
      ns.sortField = "firstName";
      ns.sortDir = "asc";
    }
    const sortedUsers = this.filterUsers(this.state.organization.users, ns.sortField, ns.sortDir);
    ns.filteredUsers = sortedUsers;
    ns.lastUpdate = this.state.lastUpdate + 1;
    this.setState(ns);
  }

  private invite(){
    const email = this.state.inviteEmail.trim();
    if(email === "" || email.indexOf("@") < 0){
      return error("Invalid email address");
    }
    this.setState({ loading: false }, async () => {
      try{
        await OrganizationsAPI.inviteEmailToOrganization(this.props.organization.id, email);
        success("Invited!")
        this.setState({ showInviteModal: false, inviteEmail: "" }, () => this.fetchInvitations());
      }catch(err){
        this.setState({ loading: false, showInviteModal: false }, () => {
          if((err as any).code && (err as any).code === 402){
            return error("You have exceeded the maximum number of users for your subscription! Please upgrade or contact support.", "Quota Exceeded", {timeOut: 5000});
          }
          error("Could not invite that email. Please contact support");
        })
      }
    });
  }

  private handleDeleteInvitation(invitation: IPendingInvitation){
    this.setState({ loading: false }, async () => {
      try{
        await OrganizationsAPI.deleteInvitation(this.props.organization.id, invitation.email);
        const invitations: IPendingInvitation[] = [];
        for(const i of this.state.invitations){
          if(i.email !== invitation.email){
            invitations.push(i);
          }
        }
        this.setState({ showInviteModal: false, loading: false, invitations}, () => {
          success("User Invited");
        });
      }catch(err){
        this.setState({ loading: false, showRemoveModal: false }, () => {
          error("Could not invite that email. Please contact support");
        })
      }
    });
  }

  private handleRemoveUser(user: IUser){
    this.setState({ loading: false }, async () => {
      try{
        await OrganizationsAPI.removeUserFromOrganization(this.props.organization.id, user.id);
        // find and remove from list
        const users = [];
        for(const u of this.state.organization.users){
          if(u.id !== user.id){
            users.push(u);
          }
        }
        const org = this.state.organization;
        org.users = users;
        this.setState({ showRemoveModal: false, loading: false, organization: org, selectedUser: UserBlank}, () => {
          success("User removed");
        });
      }catch(err){
        this.setState({ loading: false, showRemoveModal: false }, () => {
          error("Could not remove that user. Please contact support");
        })
      }
    });
  }

  private handleUpdateUser(user: IUser, field: string, newValue: string){
    this.setState({ loading: true }, async () => {
      try{
        const data = {};
        data[field] = newValue;
        await OrganizationsAPI.updateUserOrganizationLink(this.state.organization.id, user.id, data);
        // find the user and change the field
        const users = [];
        for(const u of this.state.organization.users){
          if(u.id === user.id){
            u[field] = newValue;
          }
          users.push(u);
        }
        const organization = this.state.organization;
        const sortedUsers = this.filterUsers(users, this.state.sortField, this.state.sortDir);
        organization.users = users;
        this.setState({loading: false, organization, filteredUsers: sortedUsers})
      }catch(err){
        error("Could not update that member.");
        this.setState({ loading: false });
      }
    });
  }


}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationMembersScreen);