import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as qs from "query-string";

import Card from "src/components/structure/Card";
import MarkdownEditor from "src/components/structure/MarkdownEditor";
import { IOrganization, OrganizationBlank } from "src/api/organizations";
import { OrganizationsAPI } from "src/api";

interface IInvitationRespondScreenProps {
  location: any;
  history: any;
  userState: any;
  match: any;
}

interface IInvitationRespondScreenState {
  loading: boolean;
  inviteCode: string;
  status: "accepted" | "declined";
  organization: IOrganization;
  errorMessage: string;
}

class InvitationRespondScreen extends React.Component<IInvitationRespondScreenProps, IInvitationRespondScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      inviteCode: "",
      status: "accepted",
      organization: OrganizationBlank,
      errorMessage: "",
    };
    this.updateField = this.updateField.bind(this);
    this.lookupOrganization = this.lookupOrganization.bind(this);
    this.submitRequest = this.submitRequest.bind(this);

  }

  componentDidMount(){
    // if the user is not logged in, we prompt them to login and then redirect them here
    const params = qs.parse(this.props.location.search);
    let inviteCode: any = params.ic && params.ic !== "" ? params.ic : "";
    if(inviteCode === "") {
      // check local storage
      inviteCode = localStorage.getItem("ic")
      if(!inviteCode){
        inviteCode = "";
      }
    }
    if(!this.props.userState.loggedIn){
      window.localStorage.ic = inviteCode;
      window.localStorage.return = "organization_invite_respond";
      window.localStorage.oi = this.props.match.params.id;
      const icEncoded = encodeURIComponent(inviteCode);
      this.props.history.push(`/signup?return=organization_invite_respond&ic=${icEncoded}&oi=${this.props.match.params.id}`);
    }
    if(inviteCode !== ""){
      if(typeof inviteCode === "object"){
        inviteCode = inviteCode[0];
      }

      // set the state, then do the lookup
      this.setState({ inviteCode }, () => this.lookupOrganization());
    }
    // view should be lookup so they can lookup the organization by the join code
  }

  public render() {
    
    return (
      <div className="row">
        <div className="col-8 offset-2">
          <Card title="" loading={this.state.loading} help="">
            <h2 style={{textAlign: "center"}}>Join {this.state.organization.name}</h2>
            <div className="row">
              <div className="col-12">
                <MarkdownEditor
                  content={this.state.organization.description}
                  mode="view"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <MarkdownEditor
                  content={this.state.organization.joinMessage}
                  mode="view"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <label>Invitiation Code</label>
                <input type="text" id="inviteCode" className="form-control" value={this.state.inviteCode} onChange={this.updateField} />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <label>Response</label>
                <select id="status" className="form-control" value={this.state.status} onChange={this.updateField}>
                  <option value="accepted">I accept the invitation to join {this.state.organization.name}</option>
                  <option value="denied">I decline the invitation to join {this.state.organization.name}</option>
                </select>
              </div>
            </div>
            <div className="row" style={{marginTop: 30}}>
              <div className="col-12">
                {this.state.organization.joinAction !== "block" && (<button className="btn btn-block btn-primary" onClick={this.submitRequest}>Respond to Invitation</button>)}
                {this.state.errorMessage !== "" && (<p className="text-danger">{this.state.errorMessage}</p>)}
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private lookupOrganization(){
    this.setState({ loading: true, errorMessage: "" }, async () => {
      try{
        const orgResult = await OrganizationsAPI.getOrganization(this.props.match.params.id);
        const org = orgResult.body.data;

        // if the user is already a member, redirect them to the appropriate area
        if(org.status && org.status !== ""){
          if(org.status === "accepted"){
            if(org.status === "admin" || org.status === "billing"){
              return this.props.history.push(`/organizations/${org.id}/admin`);
            }
            return this.props.history.push(`/organizations/${org.id}`);
          }
         
        }

        this.setState({ loading: false, organization: orgResult.body.data, errorMessage: ""});
      }catch(err){
        this.setState({ loading: false, errorMessage: "Could not load that group. It is possible the invitation has been rescinded. Please try again or contact the group you wish to join."})
      }
    });
  }

  private submitRequest(){
    this.setState({ loading: true, errorMessage: "" }, async () => {
      try{
        await OrganizationsAPI.respondToInvitation(this.state.organization.id, this.state.inviteCode, this.state.status);
        this.props.history.push(`/organizations/${this.state.organization.id}`);
      }catch(err){
        this.setState({ loading: false, errorMessage: "We could not complete that response. It is possible your invitation has expired or has been rescinded. Please try again or contact support."});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvitationRespondScreen) as any);