import { makeCall } from "./client";
import moment from "moment";
import { IPlan } from "./billing";

export interface IOrganization{
  id: number;
  name: string;
  description: string;
  timezone: string;
  joinMessage: string;
  joinCode: string;
  joinAction: "accept" | "request" | "block";
  subscriptionStatus: "free" | "subscribed";
  planId: number;
  customerId: string;
  subscriptionId: string;
  lastCharged: moment.Moment | "";
  nextCharge: moment.Moment | "";
  userStatus: "invited" | "requested" | "accepted" | "denied";
  userRole: "member" | "billing" | "admin";
  users: any[];
  userSubAccounts: any[];
  plan?: IPlan;
  maximumReservationsPerUserPerWeek: number;
}

export const OrganizationBlank: IOrganization = {
  id: 0,
  name: "",
  description: "",
  timezone: "America/New_York",
  joinMessage: "",
  joinCode: "",
  joinAction: "request",
  subscriptionStatus: "free",
  planId: 0,
  customerId: "",
  subscriptionId: "",
  lastCharged: "",
  nextCharge: "",
  userStatus: "accepted",
  userRole: "member",
  users: [],
  userSubAccounts: [],
  maximumReservationsPerUserPerWeek: 0,
};

export interface IPendingInvitation {
  email: string;
  organizationId: number;
  organizationRole: "member" | "billing" | "admin";
  requestCode: string;
  requestedOn: moment.Moment;
  status: "invited" | "requested" | "accepted" | "denied";
}

export class OrganizationsAPI {

  /**
   * Gets the user's organizations
   * @param otherData 
   */
  public getUserOrganizations(otherData: any = {}): Promise<any> {
    return makeCall("get", "organizations", otherData);
  }

  /**
   * Create a new organization. Name is required, everything else is extra
   * @param name 
   * @param extraData 
   */
  public createOrganization(name: string, extraData: any = {}): Promise<any> {
    const data = {
      ...extraData,
      name
    };
    return makeCall("post", "organizations", data);
  }

  /**
   * Get a single organization
   * @param organizationId 
   * @param otherData 
   */
  public getOrganization(organizationId: number, otherData: any = {}): Promise<any> {
    return makeCall("get", `organizations/${organizationId}`, otherData);
  }

  /**
   * Get an organization by its join code
   * @param joinCode 
   * @param otherData 
   */
  public getOrganizationByJoinCode(joinCode: string, otherData: any = {}): Promise<any> {
    return makeCall("get", `organizations/sc/${joinCode}`, otherData);
  }

  /**
   * Update a single organization
   * @param organizationId 
   * @param data 
   */
  public updateOrganization(organizationId: number, data: any = {}): Promise<any> {
    return makeCall("patch", `organizations/${organizationId}`, data);
  }

  /**
   * Attempt to delete the organization
   * @param organizationId 
   * @param data 
   */
  public deleteOrganization(organizationId: number, data: any = {}): Promise<any> {
    return makeCall("delete", `organizations/${organizationId}`, data);
  }

  /**
   * Request to join an organization
   * @param organizationId 
   * @param joinCode 
   * @param otherData 
   */
  public joinOrganization(organizationId: number, joinCode: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      joinCode
    };
    return makeCall("post", `organizations/${organizationId}/join`, data);
  }

  /**
   * Invite an email address to an organization
   * @param organizationId 
   * @param email 
   * @param otherData 
   */
  public inviteEmailToOrganization(organizationId: number, email: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      email
    }
    return makeCall("post", `organizations/${organizationId}/invite`, data);
  }

  /**
   * Respond to an invitation
   * @param organizationId 
   * @param requestCode 
   * @param status 
   * @param otherData 
   */
  public respondToInvitation(organizationId: number, requestCode: string, status: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      requestCode,
      status
    }
    return makeCall("post", `organizations/${organizationId}/invite/respond`, data);
  }

  /**
   * Get the pending invitations for an organization
   * @param organizationId 
   * @param data 
   */
  public getInvitations(organizationId: number, data: any = {}): Promise<any> {
    return makeCall("get", `organizations/${organizationId}/invite`, data);
  }

  /**
   * Deletes a pending invitation, if it exists
   * @param organizationId 
   * @param email 
   * @param otherData 
   */
  public deleteInvitation(organizationId: number, email: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      email,
    };
    return makeCall("post", `organizations/${organizationId}/invite/remove`, data);
  }

  /**
   * Update the status or role of the user
   * @param organizationId 
   * @param userId 
   * @param otherData 
   */
  public updateUserOrganizationLink(organizationId: number, userId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    }
    return makeCall("patch", `organizations/${organizationId}/users/${userId}`, data);
  }

  /**
   * Gets a user for an organization
   * @param organizationId 
   * @param userId 
   */
  public getUserProfileForOrganization(organizationId: number, userId: number): Promise<any> {
    return makeCall("get", `/organizations/${organizationId}/users/${userId}`);
  }

  /**
   * Completely remove a user from an organization
   * @param organizationId 
   * @param userId 
   * @param otherData 
   */
  public removeUserFromOrganization(organizationId: number, userId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    }
    return makeCall("delete", `organizations/${organizationId}/users/${userId}`, data);
  }

}