import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import MarkdownEditor from "../../structure/MarkdownEditor";
import * as AppActions from "../../../reducers/appReducer";
import { OrganizationsAPI } from "src/api";
import { error } from "src/components/structure/Alert";

import { timezones } from "src/utils/datetime";
import { Translator } from "src/utils/translator";

interface IStarterProps {
  appActions: any;
  history: any;
}

interface IStarterState {
  loading: boolean;
  name: string;
  description: string;
  joinMessage: string;
  joinCode: string;
  joinAction: string;
  timezone: string;
}

const createHelpText = Translator.getHelpText("en", "organization-create-screen");
class Starter extends React.Component<IStarterProps, IStarterState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      name: "",
      description: "",
      joinMessage: "",
      joinCode: "",
      joinAction: "request",
      timezone: "America/New_York",
    };

    this.updateField = this.updateField.bind(this);
    this.updateDescription = this.updateDescription.bind(this);
    this.updateJoinMessage = this.updateJoinMessage.bind(this);
    this.createOrganization = this.createOrganization.bind(this);
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-6 offset-md-3 col-sm-12">
          <Card title="Create a New Group" loading={this.state.loading} help={createHelpText}>
            <p>Groups are teams, schools, studios, and similiar organizations who want to hold events and allow others to sign up for those events.</p>
            <div className="form-group">
              <label>Name</label>
              <input type="text" id="name" className="form-control" value={this.state.name} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Timezone</label>
              <select  id="timezone" className="form-control" value={this.state.timezone} onChange={this.updateField}>
                {timezones.map((tz) => {
                  return (<option key={tz} value={tz}>{tz}</option>);
                })}
              </select>
            </div>
            <div className="form-group">
              <label>Description</label>
              <MarkdownEditor
                content={this.state.description}
                showToggle={true}
                showHelp={true}
                onChange={this.updateDescription}
                mode="edit"
                rows={5}
              />
            </div>
            <div className="form-group">
              <label>Join Message</label>
              <MarkdownEditor
                content={this.state.joinMessage}
                showToggle={true}
                showHelp={true}
                onChange={this.updateJoinMessage}
                mode="edit"
                rows={5}
              />
            </div>
            <div className="form-group">
              <label>Join Action</label>
              <select id="joinAction" className="form-control" value={this.state.joinAction} onChange={this.updateField}>
                <option value="accept">Accept anyone with the correct join code</option>
                <option value="request">Users with the correct join code must be approved before joining</option>
                <option value="block">No one can request to join this group</option>
              </select>
            </div>
            <div className="form-group">
              <label>Join Code</label>
              <br /><span className="small">Used for outside users to join the organization. If blank, a random one will be created for you.</span>
              <input type="text" className="form-control" id="joinCode" value={this.state.joinCode} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-primary" onClick={this.createOrganization}>Create</button>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateDescription(content: any){
    this.setState({ description: content} );
  }

  private updateJoinMessage(content: any){
    this.setState({ joinMessage: content} );
  }

  private createOrganization(){
    this.setState({ loading: true }, async () => {
      try{
        const data = {
          name: this.state.name,
          description: this.state.description,
          joinMessage: this.state.joinMessage,
          joinAction: this.state.joinAction,
          joinCode: this.state.joinCode,
          timezone: this.state.timezone,
        };
        if(this.state.name === ""){
          return this.setState({ loading: false }, () => {
            error("Name is required");
          });
        }
        const result = await OrganizationsAPI.createOrganization(data.name, data);
        this.props.history.push(`/organizations/${result.body.data.id}/admin`);
        // redirect
      }catch(err){
        error("Organization could not be created!");
        this.setState({loading: false});
      }
    })
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Starter) as any);