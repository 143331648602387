import moment from "moment";
import { makeCall } from "./client";

export interface IScheduleTemplate {
  id: number;
  organizationId: number;
  name: string;
  status: "active" | "inactive";
  scheduleType: "week";
  items: IScheduleTemplateItem[];
}

export const ScheduleTemplateBlank: IScheduleTemplate = {
  id: 0,
  organizationId: 0,
  name: "",
  status: "active",
  scheduleType: "week",
  items: []
}

export interface IScheduleTemplateItem {
  id: number;
  organizationId: number;
  eventTemplateId: number;
  scheduleTemplateId: number;
  startDay: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
  startTime: string;
  numberOfMinutes: number;

  date: moment.Moment;
  startTimeDate: Date;
  endTimeDate: Date;
  title: string;
}

export const ScheduleTemplateItemBlank: IScheduleTemplateItem = {
  id: 0,
  organizationId: 0,
  eventTemplateId: 0,
  scheduleTemplateId: 0,
  startDay: "monday",
  startTime: "15:00",
  numberOfMinutes: 30,

  date: moment(),
  startTimeDate: moment().toDate(),
  endTimeDate: moment().toDate(),
  title: "",
}

export interface IScheduleTemplateReleaseInput {
  start: moment.Moment;
  end: moment.Moment;
  releaseOn: moment.Moment;
  releaseOnType: "active" | "pending"
}

export class ScheduleTemplatesAPI {

  /**
   * Get the templates
   * @param organizationId 
   * @param otherData 
   * @returns 
   */
  public getScheduleTemplates(organizationId: number,otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("get", `organizations/${organizationId}/schedules/templates`, data);
  }

  /**
   * Create a new template
   * @param organizationId 
   * @param name 
   * @param otherData 
   * @returns 
   */
  public createScheduleTemplate(organizationId: number, name: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      name
    };
    return makeCall("post", `organizations/${organizationId}/schedules/templates`, data);
  }

  /**
   * Update a template
   * @param organizationId 
   * @param templateId 
   * @param updateData 
   * @param otherData 
   * @returns 
   */
  public updateScheduleTemplate(organizationId: number, templateId: number, updateData: any = {}, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      ...updateData
    };
    return makeCall("patch", `organizations/${organizationId}/schedules/templates/${templateId}`, data);
  }

  /**
   * Deletes a template
   * @param organizationId 
   * @param templateId 
   * @param otherData 
   * @returns 
   */
  public deleteScheduleTemplate(organizationId: number, templateId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("delete", `organizations/${organizationId}/schedules/templates/${templateId}`, data);
  }

  /**
   * Releases a template
   * @param organizationId 
   * @param templateId 
   * @param releaseData 
   * @param otherData 
   * @returns 
   */
  public releaseScheduleTemplate(organizationId: number, templateId: number, releaseData: any, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      ...releaseData
    };
    return makeCall("post", `organizations/${organizationId}/schedules/templates/${templateId}/release`, data);
  }

  /**
   * Creates 1n items
   * @param organizationId 
   * @param templateId 
   * @param createData 
   * @param otherData 
   * @returns 
   */
  public createScheduleTemplateItems(organizationId: number, templateId: number, createData: any[] = [], otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      items: createData,
    };
    return makeCall("post", `organizations/${organizationId}/schedules/templates/${templateId}/items`, data);
  }

  /**
   * Updates a single item
   * @param organizationId 
   * @param templateId 
   * @param itemId 
   * @param updateData 
   * @param otherData 
   * @returns 
   */
  public updateScheduleTemplateItem(organizationId: number, templateId: number, itemId: number, updateData: any = {}, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      ...updateData,
    };
    return makeCall("patch", `organizations/${organizationId}/schedules/templates/${templateId}/items/${itemId}`, data);
  }

  /**
   * Deletes an item
   * @param organizationId 
   * @param templateId 
   * @param itemId 
   * @param otherData 
   * @returns 
   */
  public deleteScheduleTemplateItem(organizationId: number, templateId: number, itemId: number, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
    };
    return makeCall("delete", `organizations/${organizationId}/schedules/templates/${templateId}/items/${itemId}`, data);
  }
}