import * as React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Modal } from "react-bootstrap";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import MarkdownEditor from "src/components/structure/MarkdownEditor";
import DatePicker from "src/components/structure/DatePicker";
import EventInstanceMemberListItem from "src/components/screens/Events/EventInstanceMemberListItem";
import MemberReservationScreen from "src/components/screens/Events/MemberReservationScreen";
import { error, success } from "src/components/structure/Alert";
import * as AppActions from "src/reducers/appReducer";
import * as EventActions from "src/reducers/eventReducers";
import { IOrganization, OrganizationBlank } from "src/api/organizations";
import { OrganizationsAPI, EventsAPI, UserAPI, CancellationsAPI } from "src/api";
import { IEventInstance, EventInstanceBlank } from "src/api/events";
import { Translator } from "src/utils/translator";


interface IOrganizationViewScreenProps {
  match: any;
  appActions: any;
  history: any;
  userState: any;
  eventState: any;
  eventActions: any;
}

interface IOrganizationViewScreenState {
  loading: boolean;
  loaded: boolean; // needed to prevent flicker of no instances message
  organization: IOrganization;
  accounts: any;
  allInstancesInRange: IEventInstance[];
  filteredInstances: IEventInstance[];
  instanceStart: moment.Moment;
  instanceEnd: moment.Moment;
  showFull: "yes" | "no";
  showMode: "list" | "calendar";
  minHourForCalendar: Date;
  maxHourForCalendar: Date;

  userTotalReservationsInPeriod: number;

  showReservationModal: boolean;
  selectedInstance: IEventInstance;

  isNotified: "yes" | "no";

  windowWidth: number;
  windowHeight: number;
}

const upcomingHelp = Translator.getHelpText("en", "member_org_upcoming");
const subaccountsHelp = Translator.getHelpText("en", "member_org_accounts");
const localizer = momentLocalizer(moment);

class OrganizationViewScreen extends React.Component<IOrganizationViewScreenProps, IOrganizationViewScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      accounts: [],
      organization: OrganizationBlank,
      allInstancesInRange: [],
      filteredInstances: [],
      userTotalReservationsInPeriod: 0,
      instanceStart: moment(),
      instanceEnd: moment().add(7, "days").hour(23).minute(59),
      showFull: "no",
      showMode: "calendar",
      showReservationModal: false,
      selectedInstance: EventInstanceBlank,
      minHourForCalendar: new Date(2020, 1, 1, 7),
      maxHourForCalendar: new Date(2020, 1, 1, 23),
      isNotified: "no",

      // the below is for the calendar to decide what view to start on
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };

    this.fetchOrganization = this.fetchOrganization.bind(this);
    this.fetchInstances = this.fetchInstances.bind(this);
    this.updateInstanceEnd = this.updateInstanceEnd.bind(this);
    this.updateInstanceStart = this.updateInstanceStart.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.calendarDateChanges = this.calendarDateChanges.bind(this);
    this.eventPropGetter = this.eventPropGetter.bind(this);
    this.handleInstanceSelected = this.handleInstanceSelected.bind(this);
    this.updateNotification = this.updateNotification.bind(this);
    this.toggleReservationModal = this.toggleReservationModal.bind(this);
    this.handleInstanceChanged = this.handleInstanceChanged.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // we want to look at the state here
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const eventState = this.props.eventState;
    const instanceStart = eventState && eventState.upcomingEventsMemberScreenStart ? moment(eventState.upcomingEventsMemberScreenStart) : moment();
    const instanceEnd = eventState && eventState.upcomingEventsMemberScreenEnd ? moment(eventState.upcomingEventsMemberScreenEnd) : moment().add(7, "days").hour(23).minute(59);
    this.setState({ instanceStart, instanceEnd }, () => this.fetchOrganization());
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  public render() {
    return (
      <Screen fileName="Events/Organizations/OrganizationViewScreen.tsx" information="Holds the calendar view for USERS">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="row">
              <div className="col-12">
                <Card title="About" loading={this.state.loading} help="">
                  <h2>{this.state.organization.name}</h2>
                  <MarkdownEditor mode="view" content={this.state.organization.description} />
                  <div className="row">
                    <div className="col-12">
                      {this.state.organization.maximumReservationsPerUserPerWeek > 0 && (
                        <div>
                          <p style={{fontWeight: "bold"}}>
                            This organization allows a maximum of <strong>{this.state.organization.maximumReservationsPerUserPerWeek}</strong> total reservations per user per week.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="row" style={{ marginTop: 10 }}>
              <div className="col-12">
                <Card title="Accounts You Manage" loading={this.state.loading} help={subaccountsHelp}>
                  {this.state.accounts.length === 0 && (<strong>You somehow cannot manage any accounts.</strong>)}
                  {this.state.accounts.map((a: any) => {
                    return (
                      <div className="row" key={a.id}>
                        <div className="col-12">
                          {a.firstName} {a.lastName}
                        </div>
                      </div>
                    );
                  })}
                  <div className="row">
                    <div className="col-4 offset-8">
                      <Link to="/subaccounts">Add or Change</Link>
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div className="row" style={{ marginTop: 10 }}>
              <div className="col-12">
                <Card title="Notify Me" loading={this.state.loading} help={''}>
                  <div className="row">
                    <div className="col-12">
                      <p>You can choose to be notified whenever there is a cancellation. <strong>Notice:</strong> By signing up for notifications, you will be notified
                  any time there is a cancellation made. This may result in a lot of notifications.</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Should we notify you of cancellations?</label>
                    <select id="isNotified" value={this.state.isNotified} className="form-control" onChange={this.updateNotification}>
                      <option value="yes">Yes, Notify me on a Cancellation</option>
                      <option value="no">No, Do Not Notify me on a Cancellation</option>
                    </select>
                  </div>

                </Card>
              </div>
            </div>

          </div>
          <div className="col-lg-8 col-md-12">
            <Card title="Upcoming Events" loading={this.state.loading} help={upcomingHelp}>
              <div className="row" style={{ marginBottom: 15 }}>
                <div className="col-md-3">
                  <label>Showing</label>
                  <select id="showFull" className="form-control" value={this.state.showFull} onChange={this.updateFilter}>
                    <option value="yes">Show All Events</option>
                    <option value="no">Only Show Available</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <label>Show As</label>
                  <select id="showMode" className="form-control" value={this.state.showMode} onChange={this.updateFilter}>
                    <option value="list">List</option>
                    <option value="calendar">Calendar</option>
                  </select>
                </div>
                {this.state.showMode === "list" && (
                  <div className="col-md-3">
                    <label>Show from</label>
                    <DatePicker date={this.state.instanceStart} onDateSaved={this.updateInstanceStart} />
                  </div>
                )}
                {this.state.showMode === "list" && (
                  <div className="col-md-3">
                    <label>To</label>
                    <DatePicker date={this.state.instanceEnd} onDateSaved={this.updateInstanceEnd} />
                  </div>
                )}
              </div>
              {this.state.loaded && this.state.filteredInstances.length === 0 && (
                <div className="bg-danger text-white" style={{textAlign: "center", margin: 15, padding: 5}}><strong>No upcoming events meet those criteria</strong></div>
              )}

              {this.state.showMode === "list" && this.state.filteredInstances.map((instance) => {
                return (
                  <EventInstanceMemberListItem
                    key={instance.id}
                    organization={this.state.organization}
                    instance={instance}
                    isRegistered={instance.isRegistered ? instance.isRegistered : false}
                    canRegister={instance.canRegister ? instance.canRegister : false}
                    instanceSelected={this.handleInstanceSelected}
                  />
                )
              })}
              {this.state.showMode === "calendar" && (
                <div>
                  <div className="row" style={{ marginBottom: 15 }}>
                    <div className="col-lg-3 col-sm-12">
                      <div className="schedule-instance-is-available calendar-legend">
                        Available
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <div className="schedule-instance-is-full calendar-legend">
                        Full / Complete
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <div className="schedule-instance-is-registered calendar-legend">
                        Active Reservation
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <div className="schedule-instance-is-pending calendar-legend">
                        Pending Availability
                    </div>
                    </div>
                  </div>
                  <div style={{ height: 600 }}>
                    <Calendar
                      localizer={localizer}
                      events={this.state.filteredInstances}
                      startAccessor="startTimeDate"
                      endAccessor="endTimeDate"
                      defaultView={this.state.windowWidth < 600 ? "day" : "week"}
                      views={["week", "day"]}
                      step={10}
                      min={this.state.minHourForCalendar}
                      max={this.state.maxHourForCalendar}
                      onNavigate={this.calendarDateChanges}
                      eventPropGetter={this.eventPropGetter}
                      onSelectEvent={this.handleInstanceSelected}
                      date={this.state.instanceStart.toDate()}
                    />
                  </div>
                </div>

              )}
            </Card>
          </div>
        </div>

        <Modal show={this.state.showReservationModal} onHide={this.toggleReservationModal} dialogClassName="modal-94">
          <Modal.Header closeButton={true}>
            <Modal.Title>Manage Reservations</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MemberReservationScreen
              onChange={this.handleInstanceChanged}
              organizationId={this.state.selectedInstance.organizationId}
              templateId={this.state.selectedInstance.eventTemplateId}
              instanceId={this.state.selectedInstance.id} />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.toggleReservationModal}>Done</button>
          </Modal.Footer>
        </Modal>

      </Screen>
    );
  }

  private updateInstanceStart(newDate: moment.Moment) {
    this.props.eventActions.setUpcomingEventsMemberScreenDates({ which: "start", newDate });
    this.setState({ instanceStart: newDate }, () => this.fetchInstances());
  }

  private updateInstanceEnd(newDate: moment.Moment) {
    this.props.eventActions.setUpcomingEventsMemberScreenDates({ which: "end", newDate });
    this.setState({ instanceEnd: newDate }, () => this.fetchInstances());
  }

  private updateFilter(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    ns.filteredInstances = this.filterInstances(this.state.allInstancesInRange);
    this.setState(ns);
  }

  private async updateNotification(e: any) {
    const value = e.target.value;
    try {
      // the call depends on the value
      if (value === "yes") {
        await CancellationsAPI.saveCancellationNotificationPreference(this.props.match.params.id);
      } else {
        await CancellationsAPI.deleteCancellationNotificationPreference(this.props.match.params.id);
      }
      this.setState({ loading: false, isNotified: value }, () => success("Notification preferences saved"));
    } catch (err) {
      this.setState({ loading: false }, () => error("Notification preferences could not be saved"));
    }
  }

  private fetchOrganization() {
    this.setState({ loading: false }, async () => {
      try {
        const orgResult = await OrganizationsAPI.getOrganization(this.props.match.params.id);
        const organization: IOrganization = orgResult.body.data;
        // check the permissions here; if accepted and an admin, they go to the admin page
        // if not accepted, redirect to the dashboard and tell them to hold their horses
        if (organization.userStatus === "accepted" && (organization.userRole === "admin" || organization.userRole === "billing")) {
          return this.props.history.push(`/organizations/${this.props.match.params.id}/admin`);
        }
        // if invited, we could redirect, but they don't have the invite code in the URL so it'd be dicey
        // so for now, we tell them to wait
        if (organization.userStatus !== "accepted") {
          error("You do not have permission to view that organization yet");
        }
        // get the sub accounts for display
        const accounts: any[] = [{
          ...this.props.userState.user,
        }];
        const subRes = await UserAPI.getSubaccounts();
        for (const sa of subRes.body.data) {
          accounts.push(sa);
        }

        // now the notification options
        const notifyResult = await CancellationsAPI.getCancellationNotificationPreferences(this.props.match.params.id);
        const isNotified = notifyResult.body.data.length > 0 ? "yes" : "no";


        this.setState({ loading: false, organization, accounts, isNotified }, () => this.fetchInstances());
      } catch (err) { }
    });
  }

  private fetchInstances() {
    this.setState({ loading: false }, async () => {
      let min = 23;
      let max = 0;
      try {
        const start = moment(this.state.instanceStart).format("YYYY-MM-DD") + "T00:00:00Z";
        const end = moment(this.state.instanceEnd).format("YYYY-MM-DD") + "T23:59:59Z";
        const result = await EventsAPI.getInstancesForOrganization(this.props.match.params.id, start, end);
        const allInstances: IEventInstance[] = [];
        for (const i of result.body.data) {
          i.startTime = moment(i.startTime);
          i.startTimeDate = i.startTime.toDate();
          const et = moment(i.startTime).add(i.numberOfMinutes, "minutes")
          i.endTimeDate = et.toDate();
          if (i.startTime.hour() < min) {
            min = i.startTime.hour();
          }
          if (et.hour() > max) {
            max = et.clone().add(1, "hours").hour();
          }
          i.title = i.eventName;
          if (i.status === "pending") {
            i.title = "Pending Release";
          }
          
          allInstances.push(i);
        }
        const minHourForCalendar = new Date(2020, 1, 1, min)
        const maxHourForCalendar = new Date(2020, 1, 1, max)
        const instances = this.filterInstances(allInstances);
        this.setState({
          loading: false,
          allInstancesInRange: allInstances,
          filteredInstances: instances,
          minHourForCalendar,
          maxHourForCalendar,
          loaded: true,
        }, () => {
          this.props.eventActions.setUpcomingEventsMemberScreenDates({ which: "start", newDate: this.state.instanceStart });
          this.props.eventActions.setUpcomingEventsMemberScreenDates({ which: "end", newDate: this.state.instanceEnd });
        });
      } catch (err) { }
    });
  }

  private filterInstances(input: IEventInstance[]): IEventInstance[] {
    const filtered: IEventInstance[] = [];
    for (const i of input) {
      // TODO: since we moved the register status onto the object, do we need the filter here? Couldn't we filter in the render?
      // setup some calcs; the server should block it, but let's not display what they can't touch

      // the max reservations per instance allowed; for allow_subaccount_over, we care about unique users
      const max = i.maximumReservationsPerInstance ? i.maximumReservationsPerInstance : 0;
      const uniqueUsers = i.uniqueUserReservations ? i.uniqueUserReservations : 0;
      // the current user's reservation
      const ur = i.userReservations ? i.userReservations : 0;
      // the current number of reservations
      const cr = i.currentReservations ? i.currentReservations : 0;
      i.isRegistered = ur > 0;
      i.canRegister = !i.isRegistered && (cr < max || (i.maximumReservationsPerInstanceRule === "allow_subaccounts_over" && uniqueUsers < max));

      // so, we want to show it if:
      // 1 - They can register (always)
      // 2 - They've already registered (always)
      // 3 - They chose show all (always)
      // 4 - IF NOT SHOWFULL, include in the past
      if(this.state.showFull === "yes" || ur > 0){
        // show everything
        filtered.push(i);
      } else {
        // if it's in the past, don't show it
        const start = moment(i.startTime);
        if(start.isAfter(moment()) && i.canRegister){
          filtered.push(i);
        }
      }
    }
    return filtered;
  }

  private calendarDateChanges(info: any) {
    const selectedDay = moment(info);
    const currentSetDay = this.props.eventState.upcomingEventsMemberScreenStart ? moment(this.props.eventState.upcomingEventsMemberScreenStart) : moment();
    const diff = Math.abs(currentSetDay.diff(selectedDay, "days"));
    let instanceStart = moment();
    let instanceEnd = moment();
    if(diff === 1){
      // day view
      instanceStart = moment(info).hour(0).utc();
      instanceEnd = moment(info).hour(23).minute(59).utc();
    } else {
      // week view
      instanceStart = moment(info).day("Sunday").utc();
      instanceEnd = moment(info).day("Saturday").hour(23).minute(59).utc();
    }

    // we need to get the reservations for the week
    // out of time, find a way to do this in a performant way
    // console.log(info);
    // const beginningOfWeek = moment(info).startOf("week").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
    // const endOfWeek = moment(info).endOf("week").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
    // console.log(beginningOfWeek);
    // console.log(endOfWeek);
    // const resRes = await EventsAPI.getUserReservations({start: beginningOfWeek, end: endOfWeek})
    // console.log(resRes);


    this.props.eventActions.setUpcomingEventsMemberScreenDates({ which: "start", instanceStart });
    this.props.eventActions.setUpcomingEventsMemberScreenDates({ which: "end", instanceEnd });
    this.setState({
      instanceStart,
      instanceEnd
    }, () => this.fetchInstances());
  }

  private eventPropGetter(event: IEventInstance) {
    let className = "";
    if (event.isRegistered) {
      className = "schedule-instance-is-registered"
    } else if (event.canRegister) {
      className = "schedule-instance-is-available"
    } else {
      className = "schedule-instance-cannot-register"
    }
    // if it's in the past, set it to cannot register
    const startTime = moment(event.startTime);
    if (startTime.isBefore(moment())) {
      className = "schedule-instance-cannot-register";
    } else {
      // it's in the future; if it is pending, we mark it as such
      if (event.status === "pending") {
        className = "schedule-instance-is-pending";
      }
    }
    return {
      className
    };
  }

  private handleInstanceSelected(selectedInstance: IEventInstance) {
    if (selectedInstance.status === "pending") {
      return;
    }
    const startTime = moment(selectedInstance.startTime);
    if (startTime.isBefore(moment())) {
      return;
    }
    if (selectedInstance.canRegister || selectedInstance.isRegistered) {
      this.setState({ showReservationModal: true, selectedInstance: selectedInstance });
    }
  }

  private handleInstanceChanged(changedInstance: IEventInstance) {
    this.fetchInstances();
  }

  private toggleReservationModal() {
    this.setState({ showReservationModal: !this.state.showReservationModal })
  }

  private updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
    eventState: s.eventState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    eventActions: bindActionCreators(EventActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationViewScreen) as any);